import styles from './index.module.scss';
import clsx from 'clsx';
import { Modal } from 'antd';
import { TableDialogHistoryDetail } from './table-dialog-history-detail';
import moment from 'moment';

interface IProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  data: any[];
  inforHeader: {
    id: string;
    scenarioName: string;
    startDate: string;
    endDate: string;
  };
}

export const DialogHistoryModal: React.FC<IProps> = ({
  title,
  visible,
  onCancel,
  onOk,
  inforHeader,
  data,
}) => {
  const format1 = 'HH:mm:ss';
  const format2 = 'YYYY/MM/DD';

  const columns = [
    {
      title: '時間',
      dataIndex: 'createAt',
      key: 'createAt',
      render: (text: any, record: any) => {
        return (
          <>
            <p className={clsx(styles.dateTime)}>
              {moment(record.createAt).format(format2)}
            </p>
            <p className={clsx(styles.dateTime)}>
              {moment(record.createAt).format(format1)}
            </p>
          </>
        );
      },
    },
    {
      title: '会話',
      dataIndex: 'BOT',
      key: 'BOT',
      render: (text: any) => (
        <div
          style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      ),
    },
    {
      title: '',
      dataIndex: 'USER',
      key: 'USER',
      render: (text: any) => (
        <div
          style={{ whiteSpace: 'pre-line' }}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title={title}
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        closable={true}
        maskClosable={false}
        footer={null}
        className={clsx(styles.modal__container, 'scenario__setting')}
        width='1000px'
      >
        <div>
          <div className={clsx(styles.modal__header)}>
            <div className={clsx(styles.header__title)}>
              <span>対話ID</span>
              <span>シナリオ名</span>
              <span>開始日</span>
              <span>終了日</span>
            </div>
            <div className={clsx(styles.header__content)}>
              <span>{inforHeader.id}</span>
              <span>{inforHeader.scenarioName}</span>
              <span>{inforHeader.startDate}</span>
              <span>{inforHeader.endDate}</span>
            </div>
          </div>
          <div>
            <TableDialogHistoryDetail data={data} columns={columns} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
