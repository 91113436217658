//images
import {
  Checkbox,
  Dropdown,
  Menu,
  Pagination,
  DatePicker,
  Table,
  Space,
} from 'antd';
import clsx from 'clsx';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import styles from './index.module.scss';
import './aggregate-attachment.scss';
import { DocumentIcon } from '../../assets/icons/DocumentIcon';
import { ImageIcon } from '../../assets/icons/ImageIcon';
import { VideoIcon } from '../../assets/icons/VideoIcon';
import { ZipIcon } from '../../assets/icons/ZipIcon';
import { AggregateAttachment } from '../../model';
import { useEffect, useState } from 'react';
import { EmptyData } from '../analytics/graph/scenario/table-scenario/empty-data';
const { RangePicker } = DatePicker;

export const AnalistAttachmentPage = () => {
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 50,
    },
    {
      title: 'URL File',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (text: any, record: AggregateAttachment) => {
        return (
          <Space
            className='attachment__col__filename'
            size='small'
            align='center'
          >
            {(() => {
              switch (record.type) {
                case 'docx':
                  return <DocumentIcon />;
                case 'jpg':
                case 'png':
                  return <ImageIcon />;
                case 'mp4':
                  return <VideoIcon />;
                case 'zip':
                  return <ZipIcon />;
                default:
                  return <DocumentIcon />;
              }
            })()}
            {record.fileName}
          </Space>
        );
      },
    },
    {
      title: 'Clicked number',
      dataIndex: 'number',
      key: 'number',
      width: 200,
    },
    {
      title: 'Post',
      dataIndex: 'post',
      key: 'post',
    },
    {
      title: 'Scenario',
      dataIndex: 'scenario',
      key: 'scenario',
    },
  ];

  const [attachments, setAttachments] = useState<AggregateAttachment[]>([]);
  let data: AggregateAttachment[] = [];

  useEffect(() => {
    for (let index = 1; index < 10; index++) {
      const res: AggregateAttachment = {
        id: index,
        key: index,
        fileName: `会対抗な-${index}.jpg`,
        number: 1000,
        post: 'xssxs',
        scenario: 'xxx',
        createdAt: '1 week ago',
        size: '35.5 MB',
        type: 'jpg',
        updatedAt: '2022/01/25',
      };
      data.push(res);
    }
    setAttachments(data);
  }, []);

  return (
    <div className='aggregate__attachment'>
      <div className={clsx(styles.select__month)}>
        <label>期間</label> <RangePicker picker='month' />
      </div>
      <Table locale={{ emptyText: <EmptyData /> }} dataSource={attachments} columns={columns} pagination={false} />
      <Pagination
        showSizeChanger
        defaultCurrent={1}
        total={3}
        size='small'
        showLessItems={false}
        pageSize={1}
      />
    </div>
  );
};
