import axiosClient from '../http-common/axiosClient';

class TenantService {
  getAll = () => {
    const url = '/me/tenants';
    return axiosClient.get(url);
  };
}

export default new TenantService();
