import { message } from 'antd';
import axios from 'axios';
import { getTenants } from '../redux/tenant-slice';
const envApiUrl = process.env.REACT_APP_API_URL
const defaultApiUrl = `${window.location.protocol}//${window.location.hostname}`;
const apiUrl = (envApiUrl || defaultApiUrl) + '/api/v1/bot-manager'
// const baseUrlClient = `http://18.183.234.145/api/v1/bot-manager`;

const axiosClient = axios.create({
  baseURL: `${apiUrl}`,

  headers: {
    'content-type': 'application/json',
  },
});

axiosClient.interceptors.request.use(async (config: any) => {
  const customHeaders = {
    Authorization: '',
  };

  const token = localStorage.getItem('accessTokenBot');
  if (token) {
    customHeaders.Authorization = `Bearer ${token}`;
  }

  return {
    ...config,
    baseURL: `${apiUrl}/${localStorage.getItem('tenantId')}`,
    headers: {
      ...customHeaders, // auto attach token
      ...config.headers, // but you can override for some requests
    },
  };
});

export const catchResponse = (store: any) => {
  axiosClient.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      if (error.response.status === 401) {
        if (error.response.data.message) {
          window.location.href = "/bot/chatbotUI-setting"
          store.dispatch(getTenants());
        } else {
          localStorage.removeItem('accessTokenBot');
          localStorage.removeItem('tenantId');
        }
      } else {
        throw error;
      }
    }
  );

}
export default axiosClient;
