import axiosAdmin from "../http-common/axiosAdmin";

class GraphAccessURLService {
  getAll = (params: any) => {
    const url = `/analysis/accessurlscenario`;
    return axiosAdmin.get(url, { params });
  };

  getScenarioDetail = (params: any) => {
    const url = `/analysis/detailscenario`;
    return axiosAdmin.get(url, { params });
  };
}

export default new GraphAccessURLService();
