import clsx from 'clsx';
import styles from './index.module.scss';
import { Outlet } from 'react-router-dom';
import LogoFull from '../../assets/images/logo-full.png';

export const AuthLayout: React.FC = () => {
  return (
    <div className={clsx(styles.auth__layout)}>
      <div><img src={LogoFull} /></div>
      <Outlet />
    </div>
  );
};
