import axiosAdmin from "../http-common/axiosAdmin";

class GraphStartChatService {
  getAll = (params: any) => {
    const url = `/analysis/startchatscenario`;
    return axiosAdmin.get(url, { params });
  };

  getStartScenario = (params: any) => {
    const url = `/analysis/startscenario`;
    return axiosAdmin.get(url, { params });
  };

  getEndScenario = (params: any) => {
    const url = `/analysis/notfinishscenario`;
    return axiosAdmin.get(url, { params });
  };
}

export default new GraphStartChatService();
