import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { PostModel } from "../model";
import PostService from "../services/post.services";
import { PageResultModel } from "../model/page-result.model";
import { PostDTO } from "../dto";

interface PostState {
  loading: boolean;
  loadingAction: boolean;
  posts: PostModel[];
  currentPost: PostModel;
  pageResult: PageResultModel;
  isPostNow: boolean;
}

const initialState: PostState = {
  loading: false,
  loadingAction: false,
  posts: [],
  currentPost: {} as PostModel,
  pageResult: { currentPage: 0 } as PageResultModel,
  isPostNow: true,
};

export const getPosts = createAsyncThunk(
  "post/getPosts",
  async (page?: number) => {
    const response = await PostService.getAll(page);
    return response.data;
  }
);

export const loadMorePosts = createAsyncThunk(
  "post/loadMorePosts",
  async (page?: number) => {
    const response = await PostService.getAll(page);
    return response.data;
  }
);

export const createPost = createAsyncThunk(
  "post/createPost",
  async (body: PostDTO, { rejectWithValue }) => {
    try {
      const response = await PostService.create(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const detailPost = createAsyncThunk(
  "post/detailPost",
  async (id: string | undefined, { rejectWithValue }) => {
    try {
      const response = await PostService.detail(id);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const removePost = createAsyncThunk(
  "post/removePost",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await PostService.remove(id);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePost = createAsyncThunk(
  "post/updatePost",
  async (body: PostDTO, { rejectWithValue }) => {
    try {
      const response = await PostService.update(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    setCurrentPost: (state, { payload }) => {
      const findPost = state.posts.find((f) => f._id === payload);
      if (findPost) {
        state.currentPost = findPost;
        if (findPost.startDate && findPost.endDate) {
          state.isPostNow = false;
        } else {
          state.isPostNow = true;
        }
      }
    },
    setIsPostNow: (state, { payload }) => {
      state.isPostNow = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPosts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPosts.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.status) {
        state.posts = payload.posts;
        state.pageResult = payload.pagedResult;
      }
    });
    builder.addCase(loadMorePosts.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload.status) {
        state.posts = [...state.posts, ...payload.posts];
        state.pageResult = payload.pagedResult;
      }
    });
    builder.addCase(createPost.pending, (state) => {
      state.loadingAction = true;
    });
    builder.addCase(createPost.rejected, (state) => {
      state.loadingAction = false;
    });
    builder.addCase(createPost.fulfilled, (state, { payload }) => {
      state.loadingAction = false;
      if (payload._id) {
        state.currentPost = payload;
      }
    });
    builder.addCase(detailPost.fulfilled, (state, { payload }) => {
      if (payload.post) {
        state.currentPost = payload.post;
      }
    });
    builder.addCase(updatePost.pending, (state) => {
      state.loadingAction = true;
    });
    builder.addCase(updatePost.rejected, (state) => {
      state.loadingAction = false;
    });
    builder.addCase(updatePost.fulfilled, (state, { payload }) => {
      state.loadingAction = false;
      if (payload.post && payload.post._id) {
        state.currentPost = payload.post;
      }
    });
  },
});

export const { setCurrentPost, setIsPostNow } = postSlice.actions;

export const postSelector = (state: RootState) => state.post;

export default postSlice.reducer;
