import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

//icons
import { ArowInput } from '../../../assets/icons/ArowInput';

//images
import Group1 from '../../../assets/images/Group1.png';
import Group2 from '../../../assets/images/Group2.png';
import Group3 from '../../../assets/images/Group3.png';
import Advertisement from '../../../assets/images/Advertisement.png';
import { Attachment } from './attachment-message';
import { ImageMessage } from './image';
import { CardTextImage } from './card-text-image';
import { SelectInputChat } from './select';
import { VideoMessage } from './video';
import { IconWrap } from '../../../components/icon-wrap';
import axiosClient from '../../../http-common/axiosAdmin';
import { useAppSelector } from '../../../redux/hooks';

interface IProps {
  headerBackground?: string;
  headerColorTop?: string;
  headerFonsizeTop?: string;
  headerColorBot?: string;
  headerFonsizeBot?: string;
  minusBackground?: string;
  headerTitleTop?: string;
  headerTitleBot?: string;
  containerBackground?: string;
  messageTitle?: string;
  messageBackground?: string;
  messageFontSize?: string;
  messageColor?: string;
  scenarioBackground?: string;
  scenarioColor?: string;
  scenarioBorderColor?: string;
  scenarioFontSize?: string;
  inputBackground?: string;
  iconColor?: string;
  inputPlaceholder?: string;
  inputFontSize?: string;
  inputContainerBackground?: string;
  iconURL?: string;
  replyBackground?: string;
  replyColor?: string;
  replyBorderColor?: string;
  replyFontSize?: string;
}

const HeaderChatBot = styled.div`
  background-color: ${(props: IProps) => props.headerBackground};
`;

const TopTitle = styled.p`
  color: ${(props: IProps) => props.headerColorTop};
  font-size: ${(props: IProps) => props.headerFonsizeTop};
  max-width: 250px !important;
  word-break: break-all;
`;

const BottomTitle = styled.p`
  color: ${(props: IProps) => props.headerColorBot};
  font-size: ${(props: IProps) => props.headerFonsizeBot};
  max-width: 250px !important;
  word-break: break-all;
`;

const MinusHide = styled.div`
  background-color: ${(props: IProps) => props.minusBackground};
`;

const ChatBotContainer = styled.div`
  background-color: ${(props: IProps) => props.containerBackground};
`;

const MessageBox = styled.div`
  background-color: ${(props: IProps) => props.messageBackground};
  font-size: ${(props: IProps) => props.messageFontSize};
  color: ${(props: IProps) => props.messageColor};
`;

const AdvertisementBox = styled.div`
  background-color: ${(props: IProps) => props.messageBackground};
`;

const Scenario = styled.div`
  background-color: ${(props: IProps) => props.scenarioBackground};
  color: ${(props: IProps) => props.scenarioColor};
  border: ${(props: IProps) => `1px solid ${props.scenarioBorderColor}`};
  font-size: ${(props: IProps) => props.scenarioFontSize};
`;
const Reply = styled.div`
  background-color: ${(props: IProps) => props.replyBackground};
  color: ${(props: IProps) => props.replyColor};
  border: ${(props: IProps) => `1px solid ${props.replyBorderColor}`};
  font-size: ${(props: IProps) => props.replyFontSize};
  font-weight: 400;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin: 0 10px 10px auto;
  text-align: right;
  width: fit-content;
  word-break: break-all;
  border-radius: 10px;
  max-width: 270px;
  padding: 10px;
  > p {
    margin: 0;
  }
`;
const Footer = styled.div`
  background-color: ${(props: IProps) => props.inputContainerBackground};
`;

const InputChatBox = styled.input`
  background-color: ${(props: IProps) => props.inputBackground};
  font-size: ${(props: IProps) => props.inputFontSize};
`;

const InputIcon = styled.div`
  color: ${(props: IProps) => props.iconColor};
`;
export const ChatBotUI: React.FC<IProps> = ({
  headerBackground,
  headerTitleTop,
  headerFonsizeTop,
  headerColorTop,
  headerTitleBot,
  headerFonsizeBot,
  headerColorBot,
  minusBackground,
  containerBackground,
  messageTitle,
  messageBackground,
  messageFontSize,
  messageColor,
  scenarioBackground,
  scenarioColor,
  scenarioBorderColor,
  scenarioFontSize,
  inputBackground,
  iconColor,
  inputPlaceholder,
  inputFontSize,
  inputContainerBackground,
  iconURL,
  replyBackground,
  replyColor,
  replyBorderColor,
  replyFontSize,
}) => {
  const [imageUrl, setImageUrl] = useState('');
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const fetchData = () => {
    const handleAsync = async () => {
      let url = '/icons/bot-header.png';
      axiosClient.get(url, { responseType: 'blob' }).then((res) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = function () {
          let imgSRC = reader.result;
          if (imgSRC && typeof imgSRC === 'string') {
            setImageUrl(imgSRC);
          }
        };
      });
    };
    handleAsync();
  };
  useEffect(() => {
    fetchData();
  }, [iconURL, localStorage.getItem('tenantId')]);
  return (
    <div className={clsx(styles.container)}>
      <ChatBotContainer
        containerBackground={containerBackground}
        className={clsx(styles.chatbot)}
      >
        <HeaderChatBot
          headerBackground={headerBackground}
          className={clsx(styles.headerChatBot)}
        >
          {(imageUrl || iconURL) && iconURL !== 'reset' && (
            <img
              src={iconURL ? iconURL : imageUrl}
              alt='chatbot-logo'
              className={clsx(styles.img)}
            />
          )}
          <div
            style={{ marginLeft: !iconURL || iconURL == 'reset' ? '10px' : 0 }}
            className={clsx(styles.title)}
          >
            <TopTitle
              headerColorTop={headerColorTop}
              headerFonsizeTop={headerFonsizeTop}
            >
              {headerTitleTop}
            </TopTitle>
            <BottomTitle
              headerColorBot={headerColorBot}
              headerFonsizeBot={headerFonsizeBot}
            >
              {headerTitleBot}
            </BottomTitle>
          </div>
          <MinusHide
            minusBackground={minusBackground}
            className={clsx(styles.minus)}
          />
        </HeaderChatBot>

        <div className={clsx(styles.body)}>
          <MessageBox
            messageBackground={messageBackground}
            messageColor={messageColor}
            messageFontSize={messageFontSize}
            className={clsx(styles.message)}
          >
            {messageTitle}
          </MessageBox>
          <div className={clsx(styles.scenario__group)}>
            <Scenario
              scenarioBackground={scenarioBackground}
              scenarioColor={scenarioColor}
              scenarioBorderColor={scenarioBorderColor}
              scenarioFontSize={scenarioFontSize}
            >
              <img src={Group1} alt='image1' />
              {'航空券予約'}
            </Scenario>
            <Scenario
              scenarioBackground={scenarioBackground}
              scenarioColor={scenarioColor}
              scenarioBorderColor={scenarioBorderColor}
              scenarioFontSize={scenarioFontSize}
            >
              <img src={Group2} alt='image2' />
              {'クーポン'}
            </Scenario>
            <Scenario
              scenarioBackground={scenarioBackground}
              scenarioColor={scenarioColor}
              scenarioBorderColor={scenarioBorderColor}
              scenarioFontSize={scenarioFontSize}
            >
              <img src={Group3} alt='image3' />
              {'インタビュースケジュール'}
            </Scenario>
          </div>
          <Reply
            replyBackground={replyBackground}
            replyColor={replyColor}
            replyBorderColor={replyBorderColor}
            replyFontSize={replyFontSize}
          >
            <p>テキスト返答</p>
          </Reply>
        </div>
        <Footer
          inputContainerBackground={inputContainerBackground}
          className={clsx(styles.footer)}
        >
          <InputChatBox
            disabled={!isEditable}
            inputBackground={inputBackground}
            inputFontSize={inputFontSize}
            type='text'
            placeholder={inputPlaceholder}
          />
          <IconWrap size={22} icon={<ArowInput />} color={iconColor} />
        </Footer>
      </ChatBotContainer>
    </div>
  );
};
