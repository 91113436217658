import './index.scss';
import clsx from 'clsx';
import { Pagination, Table } from 'antd';
import { PageResultModel } from '../../../../../model/page-result.model';
import { EmptyData } from '../../scenario/table-scenario/empty-data';

interface IProps {
  columns: any[];
  data: any[];
  pagination: boolean;
  currentPage: number;
  pageResult: PageResultModel;
  onChangePage: (page: number, pageSize: number) => void;
}

export const TableGraphAttachment: React.FC<IProps> = ({
  data,
  columns,
  pagination,
  currentPage,
  pageResult,
  onChangePage,
}) => {
  const onHandlePage = (page: number, pageSize: number) => {
    onChangePage(page, pageSize);
  };

  const locale = {
    emptyText: <EmptyData />,
  }
  return (
    <div className='table__graph__attachment table__list'>
      <Table
        rowKey='_id'
        locale={locale}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
      {pagination && (
        <Pagination
          showSizeChanger
          onChange={onHandlePage}
          defaultCurrent={1}
          current={currentPage}
          total={Number(pageResult.total)}
          size='small'
          showLessItems={false}
          pageSize={Number(pageResult.pageSize)}
        />
      )}
    </div>
  );
};
