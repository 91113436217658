import { useState } from 'react';
import ReactPlayer from 'react-player';
import styles from './index.module.scss';
import clsx from 'clsx';

export const VideoMessage = (url: any) => {
  const [playTime, setPlayTime] = useState(0);

  const handleProgress = (state: any) => {
    setPlayTime(state.playedSeconds);
  };
  const formatTime = (time: any) => {
    const date = new Date(time * 1000);
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const second = ('0' + date.getUTCSeconds()).slice(-2);
    if (hour) {
      return `${hour}:${('0' + minute).slice(-2)}:${second}`;
    }
    return `${minute}:${second}`;
  };
  return (
    <div className={clsx(styles.container)}>
      <ReactPlayer
        width='249px'
        height='auto'
        pip
        controls
        playing
        // config={{ file: { forceHLS: true } }}
        url={url.url}
      />
    </div>
  );
};
