import styles from "./index.module.scss";
import clsx from "clsx";

interface IProps {
  title: string;
  content: string;
  className?: string;
  textEmpty?: string;
}

export const TitleProfile: React.FC<IProps> = ({
  title,
  content,
  className,
  textEmpty
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <p className={clsx(styles.title)}>{title}</p>
      <p className={clsx(styles.content, "title__content")}>
        <span className="text__field">{content}</span>
        <span className="text__required">{textEmpty}</span>
      </p>
    </div>
  );
};
