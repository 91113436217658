import './index.scss';
import clsx from 'clsx';
import { Table } from 'antd';
import { EmptyData } from '../table-scenario/empty-data';
import { ButtonCustom } from '../../../../../components/button';

interface IProps {
  columns: any[];
  data: any[];
  exportExcel: (params?: any) => void;
  canNotExport?: boolean;
  // pagination: boolean;
  // currentPage: number;
  // pageResult: PagedResult;
  // onChangePage: (page: number, pageSize: number) => void;
}

export const TableTopLanddingPage: React.FC<IProps> = ({
  data,
  columns,
  exportExcel,
  canNotExport,
  // pagination,
  // currentPage,
  // pageResult,
  // onChangePage,
}) => {
  return (
    <div className={clsx('table__list__landding')}>
      <div className={clsx('group__header__landding')}>
        <p>シナリオがよく使われたページ</p>
        <div className={'export'}>
          <ButtonCustom
            type='primary'
            text='エクスポート'
            onClick={exportExcel}
            disabled={canNotExport}
          />
        </div>
      </div>

      <Table
        rowKey='key'
        locale={{ emptyText: <EmptyData /> }}
        dataSource={data}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
