import { unwrapResult } from '@reduxjs/toolkit';
import { Table } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { PostModel } from '../../model';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getPosts,
  loadMorePosts,
  postSelector,
  setCurrentPost,
} from '../../redux/post-slice';
import PostAd from '../../assets/images/post-advertisement.png';

import styles from './index.module.scss';
interface IProps {
  posts?: PostModel[];
  onDetail: (e: PostModel) => void;
}
export const ListPost: React.FC<IProps> = () => {
  const navigate = useNavigate();
  const fetchPost = useAppSelector(postSelector);
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const dispatch = useAppDispatch();
  const { postId } = useParams();

  const onloadMore = () => {
    const page: number = Number(fetchPost.pageResult.currentPage) + 1;
    dispatch(loadMorePosts(page));
  };

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getPosts());
      const response = unwrapResult(actionResult);
      if (postId) {
        dispatch(setCurrentPost(postId));
      }
    } catch (error) {
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentPost(postId));
  }, [postId]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);
  return (
    <>
      {(fetchPost.posts.length > 0 && (
        <div className={clsx(styles.list__post)}>
          <InfiniteScroll
            dataLength={Number(fetchPost.pageResult.total)}
            next={onloadMore}
            style={{ width: '100%' }}
            height='calc(100vh - 230px)'
            hasMore={
              Number(fetchPost.pageResult.total) > fetchPost.posts.length
                ? true
                : false
            }
            loader=''
          >
            <div className='row__post header__fixed'>
              <div className='column__title'>投稿一覧</div>
              <div className='column__status'>状態</div>
              <div className='column__date'>作成日</div>
            </div>
            {fetchPost.posts.map((i, index) => (
              <div
                onClick={() => {
                  dispatch(setCurrentPost(i._id));
                  if (isEditable) {
                    navigate(`/ad-setting/edit/${i._id}`);
                  } else {
                    navigate(`/ad-setting/detail/${i._id}`);
                  }
                }}
                className={clsx('row__post', {
                  [styles.row__active]: postId === i._id,
                })}
                key={index}
              >
                <div className='column__title'>{i.title}</div>
                <div className='column__status'>
                  {i.status === 'active' ? (
                    <span
                      className={clsx(styles.status, styles.status__active)}
                    >
                      有効
                    </span>
                  ) : (
                    <span className={clsx(styles.status)}>無効</span>
                  )}
                </div>
                <div className='column__date'>
                  {moment(i.createdAt).format('YYYY/MM/DD')}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )) || (
          <>
            <img src={PostAd} alt='example' />
            <div className={clsx(styles.text)}>
              <p>まだ投稿はありません。</p>
              <p>今すぐ投稿を作成しましょう！</p>
            </div>
          </>
        )}
    </>
  );
};
