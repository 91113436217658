import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Error from '../pages/error';

interface IProps {
  isEditable: boolean;
}

export const ProtectedRouteEdit: React.FC<IProps> = ({ isEditable }) => {
  let location = useLocation();
  return isEditable ? (
    <Outlet />
  ) : (
    // <Navigate to='/ad-setting' state={{ from: location }} replace />
    <Error />
  );
};
