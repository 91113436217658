import axiosAdmin from '../http-common/axiosAdmin';
import { ChatbotDTO } from '../dto/index';

class ChatbotService {
  setUI = (body: ChatbotDTO) => {
    const url = `/tenants/design`;
    return axiosAdmin.post(url, body);
  };
  getUI = () => {
    const url = `/tenants/design`;
    return axiosAdmin.get(url);
  };
  getIcon = () => {
    const url = `/icons/bot-header.png`;
    return axiosAdmin.get(url, { responseType: 'blob' });
  };
}

export default new ChatbotService();
