import axiosAdmin from '../http-common/axiosAdmin';

class AnalyticsScenarioService {
  getAllScenarioIntenant = () => {
    const url = `/scenarios/suggestions?`;
    return axiosAdmin.get(url);
  };
  getAggregationScenario = (params: any) => {
    const url = `/analysis/aggregationscenario`;
    return axiosAdmin.get(url, {
      params: {
        scenarioId: params.scenarioId,
        startDay: params.startDay,
        endDay: params.endDay,
        page: params.page,
        pageSize: params.pageSize,
        filterBy: params.filterBy,
        selectBy: params.selectBy,
      },
    });
  };
  getDetailTalklog = (params: any) => {
    const url = `/analysis/detailtaklog`;
    return axiosAdmin.get(url, {
      params: {
        chatId: params.chatId,
      },
    });
  };
  changeStateConfirm = (params: any) => {
    const url = `/analysis/stateconfirm?stateConfirm=${params.stateConfirm}&chatId=${params.chatId}`;
    return axiosAdmin.post(url);
  };
  exportCSV = (params: any) => {
    const url = `analysis/exportCSV`;
    return axiosAdmin.get(url, {
      params: {
        scenarioId: params.scenarioId,
        startMonth: params.startMonth,
        endMonth: params.endMonth,
        listItems: params.listItems.map((v: any) => {
          return `${v}`;
        }),
        scenarioName: params.scenarioName,
        filterBy: params.filterBy,
      },
    });
  };
}

export default new AnalyticsScenarioService();
