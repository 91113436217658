import { unwrapResult } from "@reduxjs/toolkit";
import { Alert, Form, Select, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ButtonCustom } from "../../../components/button";
import { LANG } from "../../../lang";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  chatsettingSelector,
  EditChatSetting
} from '../../../redux/chat-setting-slice';

export const ChangeVisitSetting = () => {
  const fetchData = useAppSelector(chatsettingSelector);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    const body: any = {
      setting: {
        visitSettingCnt: data.visitSettingCnt,
        timerSettingCnt: fetchData.setting.timerSettingCnt,
        displayPosition: fetchData.setting.displayPosition
      },
    };
    try {

      const actionResult = await dispatch(EditChatSetting(body));
      const response = unwrapResult(actionResult);
      if (!response.status) {
        message.warning({
          content: response.message,
          duration: 1.5,
        });

        reset();
      } else {
        message.success({
          content: LANG.EDIT_SUCCESS,
          duration: 1.5,
        });
      }
    } catch (error) {
    }
  };

  return (
    <form className="form__information" onSubmit={(e) => e.preventDefault()}>
      <Form.Item
      >
        <Controller
          name='visitSettingCnt'
          defaultValue={fetchData.setting.visitSettingCnt}
          control={control}
          render={({ field }) => (
            <div style={{ position: 'relative' }} id='selectStatus'>
              <Select
                size='large'
                placeholder='常時表示'
                {...field}
                getPopupContainer={() => document.getElementById('selectStatus')!}
              >
                <Select.Option value="">常時表示</Select.Option>
                {Array.from({length:9}).map((v,k) => <Select.Option value={k+2} key={k+1}>{k+2}回</Select.Option>) }
              </Select>
            </div>
          )}
        />
      </Form.Item>

      <div className="form__information__action">
        <ButtonCustom
          htmlType="submit"
          onClick={handleSubmit(onSubmit)}
          type="primary"
          text="保存"
          spacing={true}
          autoFocus={true}    
          index={0}
        />
        <ButtonCustom
          onClick={() => reset()}
          htmlType="button"
          type="link"
          text="キャンセル"
        />
      </div>
    </form>
  );
};
