import { ImageMessage } from "../../chatbot-ui-setting/chatbot/image";
import { VideoMessage } from "../../chatbot-ui-setting/chatbot/video";
interface IProps {
  type: string;
  data: string;
}
export const Preview: React.FC<IProps> = ({ type, data }) => {
  return (
    <div>
      {
        ['jpg', 'png', 'gif'].includes(type) ? <ImageMessage url={data} /> : <VideoMessage url={data} />
      }
    </div>
  );
};
