import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { ChatbotModel } from '../model';
import ScenarioSettingService from '../services/scenario-setting.services';
import { ScenarioSettingDTO } from '../dto/scenario-setting.dto';

interface ScenarioSettingState {
  loading: boolean;
  allScenario: any;
  currentScenario: any;
  currentImageScenario: string;
}

const initialState: ScenarioSettingState = {
  loading: false,
  allScenario: [],
  currentScenario: null,
  currentImageScenario: '',
};

export const CreateScenario = createAsyncThunk(
  'scenario/createSetting',
  async (body: ScenarioSettingDTO, { rejectWithValue }) => {
    try {
      const response = await ScenarioSettingService.postScenario(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllScenario = createAsyncThunk(
  'scenario/getAll',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await ScenarioSettingService.getScenario(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeScenario = createAsyncThunk(
  'scenario/remove',
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await ScenarioSettingService.removeScenario(id);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getOneScenario = createAsyncThunk(
  'scenario/getOne',
  async (id: any, { rejectWithValue }) => {
    try {
      const response = await ScenarioSettingService.getByID(id);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateScenario = createAsyncThunk(
  'scenario/update',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await ScenarioSettingService.updateScenario(
        body.id,
        body.body
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAvatar = createAsyncThunk(
  'scenario/createAvatar',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await ScenarioSettingService.uploadAvatar(data);
      return response.data;
    } catch (error: any) {

      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    resetCurrentIcon: (state, { payload }) => {
      state.currentImageScenario = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(CreateScenario.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreateScenario.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getAllScenario.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllScenario.fulfilled, (state, action) => {
        state.loading = false;
        state.allScenario = action.payload;
      })
      .addCase(removeScenario.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeScenario.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getOneScenario.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneScenario.fulfilled, (state, action) => {
        state.loading = false;
        state.currentScenario = action.payload;
      })
      .addCase(updateScenario.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateScenario.fulfilled, (state, action) => {
        state.loading = false;
        state.currentScenario = action.payload;
      })
      .addCase(createAvatar.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAvatar.fulfilled, (state, action) => {
        state.loading = false;
        state.currentImageScenario = action.payload.url;
      });
  },
});
export const { resetCurrentIcon } = scenarioSlice.actions;
export const scenarioSelector = (state: RootState) =>
  state.scenario.allScenario;

export default scenarioSlice.reducer;
