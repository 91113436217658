import axiosAdmin from '../http-common/axiosAdmin';
import { ScenarioSettingDTO } from '../dto/index';

class ScenarioSettingService {
  postScenario = (body: ScenarioSettingDTO) => {
    const url = `/scenarios`;
    return axiosAdmin.post(url, body);
  };
  getScenario = (params: any) => {
    const url = `/scenarios`;
    return axiosAdmin.get(url, { params: params });
  };
  removeScenario = (id: any) => {
    const url = `/scenarios/${id}`;
    return axiosAdmin.delete(url);
  };
  getByID = (id: any) => {
    const url = `/scenarios/${id}`;
    return axiosAdmin.get(url);
  };
  updateScenario = (id: any, body: ScenarioSettingDTO) => {
    const url = `/scenarios/${id}`;
    return axiosAdmin.put(url, body);
  };
  uploadAvatar = (data: any) => {
    const url = `/avatar`;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axiosAdmin.post(url, data, config);
  };
}

export default new ScenarioSettingService();
