import { Upload, message, Modal } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';
import { useEffect, useRef, useState } from 'react';
import { LoadingOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { IconWrap } from '../../../../components/icon-wrap';
import { ExitIcon, PlusImageIcon } from '../../../../assets/icons';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { createAvatar } from '../../../../redux/scenario-setting';
import { unwrapResult } from '@reduxjs/toolkit';
import { IconCrop } from '../../../../components/crop-upload';
interface IProps {
  isEdit: boolean;
  removedIcon: Function
}
const envApiUrl = process.env.REACT_APP_API_URL
export const UploadIconScenario: React.FC<IProps> = ({ isEdit, removedIcon }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [openCrop, setOpenCrop] = useState('')
  const dispatch = useAppDispatch();
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const img = useAppSelector(
    (state) => state.scenario?.currentScenario?.scenario?.icon
  );

  useEffect(() => {
    if (img && isEdit) {
      setImageUrl(img);
    }
  }, [img]);

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const uploadImage = async (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const actionResult = await dispatch(createAvatar(formData));
      const response = unwrapResult(actionResult);
      if (response) {
        setImageUrl(
          `${envApiUrl || (window.location.protocol + '//' + window.location.hostname)}/${response.url}`
        );
        if (removedIcon) {
          removedIcon(false)
        }
      } else {
      }
    } catch (error) {
      // onError({ error });
    }
  };
  const handleChange = (info: any) => {
    // Get this url from response in real world.
    getBase64(info, (result: any) => {
      setOpenCrop(result)
    });
  };
  const cropied = (url: string, data: any) => {
    setImageUrl(url);
    setLoading(false);
    setOpenCrop('')
    uploadImage(data)
  }
  const uploadButton = (
    <div>
      <div className={clsx(styles.upload)}>
        <p>アイコン</p>
        <div className={clsx(styles.upload__icon)}>
          <IconWrap icon={<PlusImageIcon />} className={clsx(styles.icon)} />
        </div>
      </div>
    </div>
  );
  const openUpload = () => {
    if (isEditable) {
      let dom = document.getElementById("upload")
      dom?.click()
    }
  }
  return (
    <>
      {openCrop && <IconCrop setOpenCrop={(data) => setOpenCrop(data)} open={openCrop} cropied={(url: string, data: any) => cropied(url, data)} />}
      <input accept="image/png, image/jpeg" id="upload" style={{ display: "none" }} type="file" onChange={(e) => {
        let files = e.target.files
        if (files && files.length) {
          if (["image/png", "image/jpeg"].includes(files[0].type)) {
            handleChange(files[0])
          } else {
            message.error('JPG形式又はPNG形式のみアップロードできます。')
          }
        }
        e.target.value = ''
      }} />
      <div style={{ cursor: isEditable ? 'pointer' : 'not-allowed', height: '158px' }} onClick={openUpload}>
        {imageUrl &&
          imageUrl !==
          `${envApiUrl || (window.location.protocol + '//' + window.location.hostname)}/` ? (
          <div className={clsx(styles.upload)}>
            <p>アイコン</p>
            <IconWrap
              icon={<ExitIcon />}
              className={clsx(styles.exitIcon)}
              onClick={(e: any) => {
                e.stopPropagation();
                setImageUrl('');
                if (removedIcon) {
                  removedIcon(true)
                }
              }}
            />
            <div className={clsx(styles.upload__image)}>
              <img
                src={imageUrl}
                alt='avatar'
                className={clsx(styles.scenario__image)}
              />
            </div>
          </div>
        ) : (
          uploadButton
        )}
      </div>
    </>
  );
};
