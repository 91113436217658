//component
import { TitleProfile } from "../../components/title-profile";
import { Collapse } from "antd";

//styles
import styles from "./index.module.scss";
import "./profile.scss";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { EditIcon } from "../../assets/icons";
import { IconWrap } from "../../components/icon-wrap";
import { ChangePassword } from "./change-password";
import { ChangeName } from "./change-name";
import { ChangeBirthDay } from "./change-birthday";
import { ChangeEmail } from "./change-email";
import { authSelector, getMe } from "../../redux/auth-slice";
import { isDate } from "moment";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

const { Panel } = Collapse;

export const Profile = () => {
  const [collapse, setCollapse] = useState<string[]>([]);
  const [collapse2, setCollapse2] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const fetchData = useAppSelector(authSelector);
  const genExtra = () => (
    <div
      className="profile__gentExtra"
      onClick={(event: any) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        // event.stopPropagation();
      }}
    >
      <IconWrap size={15} icon={<EditIcon />} className={styles.icon__wrap} />
      <p>編集</p>
    </div>
  );

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  return (
    <div className="page__profile">
      <div className="profile__header">
        <h1>プロファイル</h1>
      </div>
      <div className="profile__container">
        <Collapse
          // defaultActiveKey={['1']}
          accordion
          expandIconPosition="right"
          className="profile__collapse"
        >
          <Panel
            showArrow={false}
            header={
              <TitleProfile
                className={clsx({
                  [styles.profile__title]: collapse.includes("1"),
                })}
                title="名前"
                textEmpty="名前を入力してください。"
                content={fetchData.profile?.name || ''}
              />
            }
            key="1"
          >
            <ChangeName />
          </Panel>
          <Panel
            showArrow={false}
            header={
              <TitleProfile
                className={clsx({
                  [styles.profile__title]: collapse.includes("2"),
                })}
                title="生年月日"
                textEmpty="生年月日を選択してください。"
                content={fetchData.profile.dayofbirth ? String(moment(
                  fetchData.profile.dayofbirth,
                  "YYYY/MM/DD"
                ).format('YYYY/MM/DD')) : ''}
              />
            }
            key="2"
          >
            <ChangeBirthDay />
          </Panel>
          <Panel
            showArrow={false}
            header={
              <TitleProfile
                className={clsx({
                  [styles.profile__title]: collapse.includes("3"),
                })}
                title="電子メールアドレス"
                textEmpty="電子メールアドレスを入力してください。"
                content={fetchData?.profile?.emailAddress || ''}
              />
            }
            key="3"
          >
            <ChangeEmail />
          </Panel>
        </Collapse>
        <Collapse
          accordion
          expandIconPosition="right"
          className="profile__collapse"
        >
          <Panel
            showArrow={false}
            header={
              <TitleProfile
                className={clsx({
                  [styles.profile__title]: collapse2.includes("4"),
                })}
                title="パスワード"
                textEmpty='※利用できる文字は8文字以上の半角英数字及び記号 ( @!#"$%&-^[;:],./<>?_+*}`(){=~|¥ )。'
                content="••••••••••"
              />
            }
            key="4"
          >
            <ChangePassword />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};
