import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import ja_JP from 'antd/lib/locale/ja_JP';
import 'moment/locale/ja'
ReactDOM.render(
  <ConfigProvider locale={ja_JP}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
  ,
  document.getElementById('root')
);
