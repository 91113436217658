import { unwrapResult } from '@reduxjs/toolkit';
import { Alert, Form, Input, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonCustom } from '../../../components/button';
import { LANG } from '../../../lang';
import { authSelector, updateProfile } from '../../../redux/auth-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

export const ChangePassword = () => {
  const fetchData = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    register,
    clearErrors,
    watch,
  } = useForm();
  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = async (data: any) => {
    const body: any = {
      username: fetchData.profile.username,
      old_password: data.old_password,
      password: data.password,
    };
    try {
      const actionResult = await dispatch(updateProfile(body));
      const response = unwrapResult(actionResult);
      if (!response.status) {
        message.warning({
          content: response.message,
          duration: 1.5,
        });
      } else {
        message.success({
          content: LANG.EDIT_SUCCESS,
          duration: 1.5,
        });
      }
      reset();
    } catch (error) {
    }
  };

  return (
    <form className='form__password' onSubmit={(e) => e.preventDefault()}>
      <Form.Item
        label='現在のパスワード'
        validateStatus={errors?.old_password ? 'error' : ''}
        help={
          errors?.old_password?.type === 'required' &&
          LANG.OLD_PASSWORD_REQUIRED
        }
      >
        <Controller
          name='old_password'
          rules={{
            required: true,
            min: 8,
          }}
          control={control}
          render={({ field }) => (
            <Input placeholder='現在のパスワード' type='password' {...field} />
          )}
        />
      </Form.Item>
      <Form.Item
        label='新しいパスワード'
        validateStatus={errors?.password ? 'error' : ''}
        help={
          (errors?.password?.type === 'required' && LANG.PASSWORD_REQUIRED) ||
          (errors?.password?.type === 'pattern' && LANG.PASSWORD_INVALID) ||
          (errors?.password?.type === 'minLength' &&
            'パスワードの長さは最低８文字です。')
        }
      >
        <Controller
          name='password'
          rules={{
            required: true,
            minLength: 8,
            pattern: /[\\@!#"$%&\-^[;:\],./<>?_+*}`(){=~|¥]/g,
          }}
          control={control}
          render={({ field }) => (
            <Input placeholder='新しいパスワード' type='password' {...field} />
          )}
        />
      </Form.Item>
      <Form.Item
        label='新しいパスワードを確認'
        validateStatus={
          errors?.password_repeat ||
            watch('password') !== watch('password_repeat')
            ? 'error'
            : ''
        }
        help={
          (errors?.password_repeat?.type === 'required' &&
            '新しいパスワード（確認用）を入力してください。') ||
          (errors?.password_repeat?.type === 'validate' &&
            LANG.PASSWORD_NOT_MATCH) ||
          (watch('password') !== watch('password_repeat') &&
            LANG.PASSWORD_NOT_MATCH)
        }
      >
        <Controller
          name='password_repeat'
          rules={{
            required: true,
            validate: (value) => value === password.current,
          }}
          control={control}
          render={({ field }) => (
            <Input
              placeholder='新しいパスワードを確認'
              type='password'
              {...field}
            />
          )}
        />
      </Form.Item>

      <div className='form__password__action'>
        <ButtonCustom
          htmlType='submit'
          onClick={handleSubmit(onSubmit)}
          type='primary'
          text='保存'
        />
        <ButtonCustom
          onClick={() => reset()}
          htmlType='button'
          type='link'
          text='キャンセル'
        />
      </div>
    </form>
  );
};
