import { PostDTO } from "../dto";
import axiosAdmin from "../http-common/axiosAdmin";

class PostService {
  getAll = (page: number = 1) => {
    const url = `/posts?page=${page}&pageSize=20`;
    return axiosAdmin.get(url);
  };

  detail = (id?: string) => {
    const url = `/posts/${id}`;
    return axiosAdmin.get(url);
  };

  create = (body: PostDTO) => {
    const url = `posts`;
    return axiosAdmin.post(url, body);
  };

  remove = (id: string) => {
    const url = `posts/${id}`;
    return axiosAdmin.delete(url);
  };

  update = (body: PostDTO) => {
    const url = `posts/${body._id}`;
    return axiosAdmin.put(url, body);
  };
}

export default new PostService();
