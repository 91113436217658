import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { LoginDTO } from '../dto/index';
import AuthService from '../services/auth.services';

interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  profile: any;
  isExistEmail: any;
}

const initialState: AuthState = {
  loading: false,
  isAuthenticated: false,
  profile: {},
  isExistEmail: null
};

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (body: LoginDTO, { rejectWithValue }) => {
    try {
      const response = await AuthService.login(body);
      localStorage.setItem('accessTokenBot', response.data.accessToken);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const checkMail = createAsyncThunk(
  "user/checkMail",
  async (email: string) => {
    const response = await AuthService.checkMail(email);
    return response.data;
  }
);

export const getMe = createAsyncThunk(
  'auth/getMe',
  async (body, { rejectWithValue }) => {
    try {
      const response = await AuthService.getMe();
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'auth/fetchUpdateProfile',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await AuthService.update(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    // logout: (state) => {
    //   state.isAuthenticated = false;
    // },
    // login: (state) => {
    //   state.isAuthenticated = true;
    // },
    setExistMail: (state, { payload }) => {
      state.isExistEmail = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.accessToken) {
          state.isAuthenticated = true;
        }
      })

      /**Check email */
      .addCase(checkMail.fulfilled, (state, { payload }) => {
        if (payload.status) {
          state.isExistEmail = payload.isExistEmail;
        }
      })

      .addCase(getMe.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMe.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload._id) {
          state.isAuthenticated = true;
          state.profile = payload;
        }
      })
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        if (payload.status) {
          state.profile = payload;
        }
      });
  },
});

export const { setExistMail } = authSlice.actions;

export const authSelector = (state: RootState) => state.auth;

// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default authSlice.reducer;
