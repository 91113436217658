import clsx from 'clsx';
import { MenuLayout } from '../menu-layout';
import styles from './index.module.scss';
import './aggregate-layout.scss';
//images
import { Menu, message } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/hooks';
import { getListScenario } from '../../redux/global-slice';
import { unwrapResult } from '@reduxjs/toolkit';

export const AnalyticsLayout = () => {
  const scroll = useRef<HTMLDivElement>(null);
  let pathname = window.location.pathname.split('/');
  let currentPage =
    pathname.length === 5 ? `${pathname[3]}/${pathname[4]}` : `${pathname[3]}`;

  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState<string>(currentPage);
  const [openKeys, setOpenKeys] = useState<any>([pathname[3]]);


  const handleClick = (e: any) => {
    navigate(`${e.key}`);
    if (e.key === 'scenario') {
      setOpenKeys([]);
    }
    setSelectedKey(e.key);
  };

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getListScenario(null));
      const response = unwrapResult(actionResult);
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }
    }
  }, []);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  return (
    <div className={clsx(styles.analytics)}>
      <h2>集計</h2>
      <div className={clsx(styles.container)}>
        <div className={clsx(styles.container__nav)}>
          <Menu
            onClick={handleClick}
            className='aggregate__nav'
            selectedKeys={[selectedKey]}
            openKeys={openKeys}
            onOpenChange={(openKeys) => {
              setOpenKeys(openKeys);
            }}
            mode='inline'
          >
            <Menu.Item key='scenario'>シナリオ</Menu.Item>
            {/* <Menu.Item key='dialog-history'>対話履歴</Menu.Item> */}

            <SubMenu title='グラフ' key='graph'>
              <Menu.Item key='graph/attachment'>添付</Menu.Item>
              <Menu.Item key='graph/scenario'>シナリオ</Menu.Item>
              <Menu.Item key='graph/access-number'>アクセス数</Menu.Item>
              <Menu.Item key='graph/open-exit'>開始数・離脱数</Menu.Item>
            </SubMenu>
          </Menu>
        </div>
        <div
          ref={scroll}
          className={clsx(styles.container__content)}
        >
          {<Outlet />}
        </div>
      </div>
    </div>
  );
};
