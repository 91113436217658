import styles from './index.module.scss';
import clsx from 'clsx';
import { CollumnChart } from '../../../../components/chart';
import { StackChart } from '../../../aggregate-scenario/stack-chart';
import { GroupChart } from '../../../aggregate-scenario/group-chart';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getEndScenario,
  getStartChat,
  GraphStartChatSelector,
} from '../../../../redux/graph-start-chat-slice';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { message } from 'antd';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  changeScenarioExit,
  changeScenarioOpen,
  GlobalSelector,
} from '../../../../redux/global-slice';
import { getListScenarioAnalytics } from '../../../../redux/analytics-scenario-slice';

export const GraphOpenExit = () => {
  const envApiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const fetchData = useAppSelector(GraphStartChatSelector);
  const globalData = useAppSelector(GlobalSelector);

  const [currentStartYear, setCurrentStartYear] = useState(
    moment().format('YYYY')
  );
  const [currentEndYear, setCurrentEndYear] = useState(moment().format('YYYY'));
  const [currentScenarioId, setCurrentScenarioId] = useState<any>();
  const firstFetch = useRef(false);

  const initFetchStartChat = useCallback(async () => {
    try {
      const actionResult = await dispatch(getStartChat({}));
      const response = unwrapResult(actionResult);
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }

    }
  }, [dispatch]);

  useEffect(() => {
    initFetchStartChat();
    setCurrentScenarioId(globalData.currentScenarioExit);
  }, [initFetchStartChat]);

  const initFetchEndScenario = useCallback(async () => {
    try {
      const actionResult = await dispatch(
        getEndScenario({ scenarioId: globalData.currentScenarioExit })
      );
      const response = unwrapResult(actionResult);
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }

    }
  }, [dispatch, globalData.currentScenarioExit]);

  useEffect(() => {
    if (firstFetch.current) {
      initFetchEndScenario();
      setCurrentScenarioId(globalData.currentScenarioExit);
    }
  }, [initFetchEndScenario]);

  const firstFetchEndScenario = async () => {
    try {
      const actionResult = await dispatch(getListScenarioAnalytics());
      const response = unwrapResult(actionResult);
      if (response) {
        dispatch(changeScenarioExit(response[0]._id));
        setCurrentScenarioId(response[0]._id);
      }
    } catch (error: any) { }
  };

  useEffect(() => {
    if (!firstFetch.current) {
      firstFetchEndScenario();
    }
    firstFetch.current = true;
    return () => {
      firstFetch.current = false;
    };
  }, []);

  const handleExportOpenExcel = () => {
    window.open(
      `${envApiUrl || window.location.protocol + '//' + window.location.hostname
      }/api/v1/bot-manager/${localStorage.getItem(
        'tenantId'
      )}/analysis/exportOpenChat`,
      `_blank`
    );
  };

  const handleExportExitExcel = () => {
    window.open(
      `${envApiUrl || window.location.protocol + '//' + window.location.hostname
      }/api/v1/bot-manager/${localStorage.getItem(
        'tenantId'
      )}/analysis/exportExitChat?scenarioId=${currentScenarioId}`,
      `_blank`
    );
  };

  return (
    <div>
      <CollumnChart
        onChangeScenario={(e: any) => {
          dispatch(changeScenarioOpen(e));
        }}
        type={'open'}
        onChangeDate={(e: any) => setCurrentStartYear(e)}
        chartData={fetchData.startChat}
        currentYear={currentStartYear}
        title='チャットボットの開始数'
        hasExpoxtExcel={true}
        exportExcel={handleExportOpenExcel}
      />
      <CollumnChart
        onChangeScenario={(e: any) => {
          setCurrentScenarioId(e);
          dispatch(changeScenarioExit(e));
        }}
        type={'exit'}
        onChangeDate={(e: any) => setCurrentEndYear(e)}
        bgColor='#FF4133'
        chartData={fetchData.endScenario}
        currentYear={currentEndYear}
        title='チャットボットの途中で離脱数'
        currentScenarioId={currentScenarioId}
        hasExpoxtExcel={true}
        exportExcel={handleExportExitExcel}
      />
    </div>
  );
};
