import clsx from 'clsx';
import styles from './index.module.scss';

interface IProps {
  icon: React.ReactNode;
  size?: number;
}

export const CoreIcon: React.FC<IProps> = ({
  icon,
  size
}) => {
  return (
    <div 
      className={clsx(styles.core__icon)}
      style={{fontSize: size }}
    >
      {icon}
    </div>
  );
};
