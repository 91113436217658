import styles from './index.module.scss';
import clsx from 'clsx';

export const ImageMessage = (url: any) => {
  return (
    <div className={clsx(styles.image__container)}>
      <img src={url.url} alt='imagePreview' />
    </div>
  );
};
