import React, { useEffect, useRef, useState } from 'react';
import Croppie from 'croppie'
import styled from 'styled-components';
import { Button } from 'antd';
interface IProps {
  cropied: (url: any, data: any) => void,
  open: string,
  setOpenCrop: (data: string) => void
}
const Container = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.2);
  .modal {
    background-color: #fff;
    width: 348px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    position: relative;
    top: 100px;
    max-width: calc(100vw - 32px);
    margin: 0 auto;
    padding: 24px;
    border-radius: 5px;
  }
  .btn-group {
    margin-top: 50px;
    display: flex;
    justify-content: flex-end;
  }
`

export const IconCrop: React.FC<IProps> = ({ cropied, open, setOpenCrop }) => {
  const [loaded, setLoaded] = useState(false);
  const [crop, setCrop] = useState<Croppie>();

  useEffect(
    () => {
      if (loaded) return;
      setLoaded(true);
      let container = document.getElementById('container')
      if (container && open) {
        var c = new Croppie(container, {
          viewport: {
            width: 90,
            height: 90
          },
          enableExif: true
        });
        c.bind({
          url: open
        })
          .catch(() => {
          })
          .then(() => {
          });
        setCrop(c)
      }
    },
    [loaded, open]
  );
  const dataURLtoFile = (dataurl: any, filename: any) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const onResult = () => {
    if (crop) {
      crop.result().then((base64) => {
        cropied(base64, dataURLtoFile(base64, 'cropped.png'))
      })
    }
  }
  return (
    <Container>
      <div className='modal'>
        <div
          style={{ width: "300px", height: "300px", background: '#fff', margin: '10px 0px' }}
          id="container"
        />
        <div className='btn-group'>
          <Button type='primary' onClick={onResult} style={{ letterSpacing: -2 }} >確定</Button>
          <Button onClick={() => setOpenCrop("")} style={{ marginLeft: 10 }}>キャンセル</Button>
        </div>
      </div>
    </Container>
  );
};
