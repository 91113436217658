import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GlobalService from '../services/global.services';
import { RootState } from './store';

interface GlobalState {
  loading: boolean;
  listScenario: any[];
  currentScenarioAccess: string;
  currentScenario: string;
  currentScenarioOpen: string;
  currentScenarioExit: string;
}

const initialState: GlobalState = {
  loading: false,
  listScenario: [],
  currentScenarioAccess: '',
  currentScenario: '',
  currentScenarioOpen: '',
  currentScenarioExit: '',
};

export const getListScenario = createAsyncThunk(
  'global/getListScenario',
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await GlobalService.getAll();
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeScenarioAccess: (state, { payload }) => {
      state.currentScenarioAccess = payload;
    },
    changeScenario: (state, { payload }) => {
      state.currentScenario = payload;
    },
    changeScenarioOpen: (state, { payload }) => {
      state.currentScenarioOpen = payload;
    },
    changeScenarioExit: (state, { payload }) => {
      state.currentScenarioExit = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListScenario.pending, (state) => {
      state.loading = true;
      state.listScenario = [];
      state.currentScenarioAccess = '';
      state.currentScenarioOpen = '';
      state.currentScenarioExit = '';
      state.currentScenario = '';
    });
    builder.addCase(getListScenario.fulfilled, (state, { payload }) => {
      state.loading = false;

      state.listScenario = payload;
      if (payload.length > 0) {
        state.currentScenarioAccess = payload[0]._id;
        state.currentScenarioOpen = payload[0]._id;
        state.currentScenarioExit = payload[0]._id;
        state.currentScenario = payload[0]._id;
      } else {
        state.currentScenarioAccess = '';
        state.currentScenarioOpen = '';
        state.currentScenarioExit = '';
        state.currentScenario = '';
      }
    });
  },
});
export const {
  changeScenarioAccess,
  changeScenario,
  changeScenarioOpen,
  changeScenarioExit,
} = globalSlice.actions;

export const GlobalSelector = (state: RootState) => state.global;

export default globalSlice.reducer;
