import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

interface IProps {
  label?: string;
  className?: string;
  children: React.ReactNode;
  required?: boolean;
}

export const FormItem: React.FC<IProps> = ({
  className,
  label,
  children,
  required,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <label className={clsx({ label__required: required })}>{label}</label>
      {children}
    </div>
  );
};
