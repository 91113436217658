import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';
import { Button, Modal, Pagination, Table, Tag } from 'antd';
//icon
import { PlusIcon } from '../../assets/icons/PlusIcon';
//components
import { IconWrap } from '../../components/icon-wrap';
import { ButtonCustom } from '../../components/button';
import { PanelContent } from './panel-content';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getAllScenario,
  removeScenario,
  scenarioSelector,
} from '../../redux/scenario-setting';
import { unwrapResult } from '@reduxjs/toolkit';
import { ScenarioModal } from './modal';
import { CopyOutlined } from '@ant-design/icons'
import { message, Select } from 'antd';
import moment from 'moment';
import { DeleteIcon, EditIcon } from '../../assets/icons';
import axiosClient from '../../http-common/axiosAdmin';
import { EmptyData } from '../analytics/graph/scenario/table-scenario/empty-data';
import InternalPreviewGroup from 'antd/lib/image/PreviewGroup';
const envApiUrl = process.env.REACT_APP_API_URL;
export const ScenarioSettingPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [idEditModal, setIdEditModal] = useState();
  const isEditTenantable = useAppSelector((state) => state.tenant.editable);
  const allScenario = useAppSelector(scenarioSelector);

  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllScenario({ page: page, pageSize: 15 }));
  }, [dispatch, page]);
  const handleOpenEditModal = (id: any) => {
    setIdEditModal(id);
    setIsModalVisible(true);
  };

  const handleRemoveScenario = async (id: any) => {
    try {
      const actionResult = await dispatch(removeScenario(id));
      const response = unwrapResult(actionResult);
      if (response.status === true) {
        message.success('削除が完了しました。');
        dispatch(getAllScenario({ page: 1, pageSize: 15 }));
      }
      setConfirmDelete('');
    } catch (error: any) {
      message.error(error.err)
    }
  };

  const handleOpenCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const columns = [
    {
      title: '#',
      key: 'no',
      width: 5,
      render: (text: any, record: any, index: number) => {
        return <span>{(page - 1) * 15 + index + 1}</span>;
      },
    },
    {
      title: 'アイコン',
      key: 'icon',
      width: 100,
      minWidth: 100,
      dataIndex: 'icon',
      render: (url: string) => (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img
            style={{ maxWidth: 45, maxHeight: 45 }}
            alt='icon'
            src={
              url ===
                `${envApiUrl ||
                window.location.protocol + '//' + window.location.hostname
                }/`
                ? 'EmptyIcon.png'
                : url
            }
          />
        </div>
      ),
    },
    {
      title: 'シナリオ名',
      key: 'name',
      width: 200,
      dataIndex: 'filename',
      render: (filename: any) => (
        <span style={{ display: 'flex', wordBreak: 'break-word' }}>
          {filename}
        </span>
      ),
    },
    {
      title: '状態',
      key: 'status',
      width: '20',
      dataIndex: 'status',
      render: (status: string) => status === 'active' ? <Tag style={{ background: " #ecffe6", color: "#45d313", border: 'none' }} >有効</Tag> : <Tag style={{ background: " #ffefcc", color: "#ffa544", border: 'none' }} >無効</Tag>
    },
    {
      title: 'タグ',
      key: 'tags',
      width: 250,
      dataIndex: 'tags',
      render: (tags: any[]) => {
        return (
          <div>
            {tags?.map((tag: string, index) => (
              <Tag className={clsx(styles.scenario__tag)} key={index}>
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: 'メール通知',
      key: 'emails',
      width: 250,
      dataIndex: 'emails',
      render: (tags: any[]) => {
        return (
          <div>
            {tags?.map((tag: string, index) => (
              <Tag className={clsx(styles.scenario__tag)} key={index}>
                {tag}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: '作成者',
      key: 'username',
      width: 150,
      dataIndex: 'createdBy',
      render: (user: any) => <span>{user?.username}</span>,
    },
    {
      title: '作成日',
      key: 'createdAt',
      width: 100,
      dataIndex: 'createdAt',
      render: (date: string) => (
        <span>{moment(date).format('YYYY/MM/DD HH:mm:ss')}</span>
      ),
    },
    {
      title: '更新者',
      key: 'updatedBy',
      width: 150,
      dataIndex: 'updatedBy',
      render: (user: any, record: any) => <span>{user?.username}</span>,
    },
    {
      title: '更新日',
      key: 'updatedAt',
      width: 100,
      dataIndex: 'updatedAt',
      render: (date: string) => (
        <span>{date && moment(date).format('YYYY/MM/DD HH:mm:ss')}</span>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 50,
      render: (record: any) => {
        return (
          <div className={clsx(styles.action)}>
            <ButtonCustom
              type='link'
              disabled={!isEditTenantable}
              onClick={(e: any) => {
                e.stopPropagation();
                handleClone(record._id);
              }}
              className={clsx(styles.action__btn)}
            >
              <div className={clsx(styles.delete__icon)}>
                <CopyOutlined />
              </div>
            </ButtonCustom>
            <ButtonCustom
              type='link'
              disabled={!isEditTenantable}
              onClick={(e: any) => {
                e.stopPropagation();
                handleOpenEditModal(record._id);
              }}
              className={clsx(styles.action__btn)}
            >
              <div className={clsx(styles.delete__icon)}>
                <EditIcon />
              </div>
            </ButtonCustom>
            <ButtonCustom
              type='link'
              disabled={!isEditTenantable}
              onClick={(e: any) => {
                e.stopPropagation();
                showDeleteConfirm(record._id);
              }}
              className={clsx(styles.action__btn)}
            >
              <div className={clsx(styles.delete__icon)}>
                <DeleteIcon fill='#FF4133' />
              </div>
            </ButtonCustom>
          </div>
        );
      },
    },
  ];
  const showDeleteConfirm = (id: string) => {
    setConfirmDelete(id);
  };
  const handleClone = (id: string) => {
    axiosClient.post(`scenarios/${id}/clone`).then(() => {
      message.success('シナリオをコピーしました。')
      setPage(1)
      dispatch(getAllScenario({ page: page, pageSize: 15 }));
    }).catch((err: any) => {
      message.error(err.err)
    })
  }
  const pagination = {
    total: allScenario?.pagedResult?.total,
    current: page,
    pageSize: 15,
    onChange: (page: number) => {
      setPage(page);
    },
  };
  return (
    <div style={{ height: '100%', width: '100%' }}>
      <div className={clsx(styles.scenario__container)}>
        <div className={clsx(styles.scenario__header)}>
          <span className={clsx(styles.scenario__header_text)}>
            シナリオの設定
          </span>
            <ButtonCustom
              disabled={!isEditTenantable}
              className={clsx(styles.scenario__add__btn)}
              type='primary'
              text='新規シナリオ作成'
              icon={<IconWrap icon={<PlusIcon />} />}
              onClick={handleOpenCreateModal}
            />
        </div>
        <div className={styles.scenario__content}>
          {allScenario?.scenarios?.length ? (
            <Table
              locale={{ emptyText: <EmptyData /> }}
              className={clsx(styles.scenario__table)}
              columns={columns}
              dataSource={allScenario?.scenarios}
              rowKey={(record: any) => record._id}
              pagination={false}
              expandable={{
                expandedRowRender: (record: any) => (
                  <PanelContent scenarioSettings={record?.scenarioSettings} />
                ),
                showExpandColumn: false,
                expandRowByClick: true,
                expandedRowClassName: () => clsx(styles.scenario__expanded),
              }}
            />
          ) : (
            <div
              className={clsx(styles.scenario__empty)}
              style={{ width: '100%', height: '100%', background: 'white' }}
            >
              <p>まだシナリオはありません。</p>
              <p>今すぐシナリオを作成しましょう！</p>
            </div>
          )}
        </div>
        {allScenario?.scenarios?.length > 0 && (
          <div className={styles.scenario__paganition}>
            <Pagination className={clsx(styles.paganition)} {...pagination} />
          </div>
        )}
      </div>
      {isModalVisible && (
        <ScenarioModal
          isEditImg={true}
          isEditable={true}
          title='シナリオ編集'
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          idDetail={idEditModal}
        />
      )}
      {isCreateModalVisible && (
        <ScenarioModal
          isEditImg={false}
          isEditable={false}
          title='新規シナリオ作成'
          visible={isCreateModalVisible}
          onCancel={() => setIsCreateModalVisible(false)}
        />
      )}
      {confirmDelete && (
        <Modal
          closable={true}
          footer={false}
          visible={confirmDelete !== ''}
          width='400px'
          onCancel={() => setConfirmDelete('')}
        >
          <div style={{ height: '50px', textAlign: 'center' }}>
            シナリオを削除してもよろしいですか。
          </div>
          <div className={clsx(styles.modal__action)}>
            <Button
              onClick={() => handleRemoveScenario(confirmDelete)}
              style={{ marginRight: 10, letterSpacing: -2 }}
              type='primary'
              autoFocus={true}
            >
              確定
            </Button>
            <Button onClick={() => setConfirmDelete('')}>キャンセル</Button>
          </div>
        </Modal>
      )}
    </div>
  );
};
