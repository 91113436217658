export const PlusImageIcon = () => {
  return (
    <svg
      width='44'
      height='40'
      viewBox='0 0 44 40'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M38 24V30H44V34H38V40H34V34H28V30H34V24H38ZM38.016 0C39.112 0 40 0.89 40 1.986V20.684C38.7153 20.2302 37.3625 19.9989 36 20V4H4L4.002 32L22.586 13.414C22.9299 13.069 23.388 12.8615 23.8742 12.8305C24.3604 12.7996 24.8411 12.9473 25.226 13.246L25.412 13.416L32.504 20.516C30.9533 20.9885 29.5143 21.7702 28.2739 22.8139C27.0335 23.8576 26.0172 25.1417 25.2865 26.5888C24.5558 28.0359 24.1257 29.616 24.0221 31.2338C23.9186 32.8516 24.1437 34.4736 24.684 36.002L1.984 36C1.45763 35.9995 0.952994 35.79 0.580979 35.4176C0.208964 35.0452 -2.6691e-07 34.5404 0 34.014V1.986C0.00366009 1.46076 0.213801 0.958035 0.585026 0.586435C0.956251 0.214836 1.45876 0.0041893 1.984 0H38.016ZM12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8V8Z' />
    </svg>
  );
};
