import styles from './index.module.scss';
import clsx from 'clsx';
import { Select } from 'antd';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  scenarioData: any;
}

export const TopScenarioChart: React.FC<IProps> = ({ scenarioData }) => {
  const labels = [
    '0h',
    '1h',
    '2h',
    '3h',
    '4h',
    '5h',
    '6h',
    '7h',
    '8h',
    '9h',
    '10h',
    '11h',
    '12h',
    '13h',
    '14h',
    '15h',
    '16h',
    '17h',
    '18h',
    '19h',
    '20h',
    '21h',
    '22h',
    '23h',
  ];

  const options = {
    // responsive: true,
    aspectRatio: 2.3,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 10,
      },
    },
  };

  const generateData = () => {
    let newObj: any = {};
    for (let index = 0; index < 24; index++) {
      newObj[index] = 0;
      if (scenarioData && scenarioData[index]) {
        newObj[index] = scenarioData[index];
      }
    }
    return newObj;
  };

  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: Object.keys(generateData()).map((item, i) => generateData()[i]),
        borderColor: '#FFA544',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return (
    <div className={clsx(styles.container)}>
      <div className={clsx(styles.wrap_row)}>
        <div style={{ width: 14, paddingBottom: 20, fontWeight: '400' }}>
          アクセス数
        </div>
        <Line
          className={clsx(styles.line__chart)}
          options={options}
          data={data}
        />
      </div>
      <div className={clsx(styles.line__chart_x)}>時間帯</div>
    </div>
  );
};
