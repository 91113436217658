import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface IProps {
  isAuthenticated: boolean;
}

export const ProtectedRoute: React.FC<IProps> = ({ isAuthenticated }) => {
  let location = useLocation();
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};
