import styles from './index.module.scss';
import clsx from 'clsx';
import { DatePicker, Select, Tag, Tooltip as TooltipAnt } from 'antd';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { useAppSelector } from '../../redux/hooks';
import { GraphStartChatSelector } from '../../redux/graph-start-chat-slice';
import { GlobalSelector } from '../../redux/global-slice';
import { ButtonCustom } from '../button';

const { Option } = Select;
interface IProps {
  title: string;
  currentYear: string;
  chartData: any;
  bgColor?: string;
  type: 'open' | 'exit';
  currentScenarioId?: string;
  onChangeDate: (e: any) => void;
  onChangeScenario: (e: any) => void;
  exportExcel?: (e: any) => void;
  hasExpoxtExcel: boolean;
}
export const CollumnChart: React.FC<IProps> = ({
  title,
  type,
  currentYear,
  chartData,
  bgColor,
  currentScenarioId,
  onChangeDate,
  onChangeScenario,
  exportExcel,
  hasExpoxtExcel = false,
}) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    // responsive: true,
    aspectRatio: 5,
    plugins: {
      legend: {
        display: false,
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 100,
          fontSize: 14,
        },
      },
    },
  };

  const labels = [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ];

  const globalData = useAppSelector(GlobalSelector);


  const generateData = () => {
    let newObj: any = {};
    for (let index = 1; index < 13; index++) {
      newObj[index] = 0;
      if (chartData && chartData[index]) {
        newObj[index] = chartData[index];
      }
    }
    return newObj;
  };

  const data = {
    labels,
    datasets: [
      {
        // label: 'Dataset 1',
        data: Object.keys(generateData()).map(
          (item, i) => generateData()[i + 1]
        ),
        backgroundColor: bgColor ? bgColor : '#ffa544',
        borderRadius: 5,
        borderSkipped: false as const,
        categoryPercentage: 0.5,
        barPercentage: 0.5,
      },
    ],
  };

  const onHandleScenario = (data: any) => {
    onChangeScenario(data);
  };

  return (
    <div className={clsx(styles.container)}>
      <div className={clsx(styles.select__container)}>
        <p>{title}</p>
        {type === 'exit' && (
          <div className={clsx(styles.select__group)}>
            {
              <div style={{ position: 'relative' }} id='selectScenario'>
                <Select
                  className={clsx(styles.select__scenario)}
                  onChange={onHandleScenario}
                  value={currentScenarioId}
                  style={{ minWidth: '180px', maxWidth: '180px' }}
                  getPopupContainer={() =>
                    document.getElementById('selectScenario')!
                  }
                >
                  {globalData.listScenario.map((item, index) => {
                    return (
                      <Option key={index} value={item._id}>
                        <TooltipAnt placement='topLeft' title={item.filename}>
                          {item.filename}
                          {item.deletedAt !== null && (
                            <Tag
                              color='#ff4133'
                              style={{
                                marginLeft: 5,
                                marginRight: 0,
                                padding: '0 5px',
                                fontSize: 10,
                              }}
                            >
                              削除
                            </Tag>
                          )}
                        </TooltipAnt>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            }
            {/* <DatePicker picker="year" defaultValue={moment(currentYear, 'YYYY')} format={'YYYY'} onChange={onDateTimePickerChange} /> */}
          </div>
        )}
        {hasExpoxtExcel && (
          <div className={styles.export}>
            <ButtonCustom
              type='primary'
              text='エクスポート'
              onClick={exportExcel}
              disabled={globalData.listScenario.length === 0}
            />
          </div>
        )}
      </div>
      <Bar className={clsx(styles.bar__chart)} options={options} data={data} />
    </div>
  );
};
