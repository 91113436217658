import styles from './index.module.scss';
import clsx from 'clsx';
import { useFieldArray, Controller } from 'react-hook-form';
import { FormItem } from '../../../../components/form-item';
import { IconWrap } from '../../../../components/icon-wrap';
import { DeleteIcon, PlusIcon } from '../../../../assets/icons';
import { Input, Form } from 'antd';

interface IProps {
  nestIndex: number;
  control: any;
  register: any;
  errors: any;
  show: boolean;
}

export const NestedReaction: React.FC<IProps> = ({
  nestIndex,
  control,
  register,
  errors,
  show,
}) => {

  return (
    <div
      className={clsx(styles.add__option, {
        [styles.show]: !show,
      })}
    >
      <div className={clsx(styles.custom__option__container)}>
      <Form.Item
          label="共通リアクション"
          validateStatus=""
          help=""
          className={clsx(styles.form__item)}
        >
          <Controller
            name={
              `scenarioSettings[${nestIndex}].commonReaction` as any
            }
            rules={{
              required: show,
            }}
            control={control}
            render={({ field }) => (
              <Input.TextArea
                style={{ width: 480, marginLeft: 10 }}
                autoSize={{ minRows: 1, maxRows: 5 }}
                placeholder='共通リアクション'
                {...field}
                className={clsx(
                  styles.filename__input,
                  'textareaResize'
                )}
              />
            )}
          />
        </Form.Item>
      </div>
    </div>
  );
};
