import './index.scss';
import clsx from 'clsx';
import { Pagination, Table } from 'antd';
import { useAppSelector } from '../../../../../redux/hooks';
import { GraphAccessURLSelector } from '../../../../../redux/graph-access-url-slice';
import { EmptyData } from './empty-data';

interface IProps {
  columns: any[];
  data: any[];
  onChangePage?: (e: any) => void;
  hasPagination?: boolean;
}

const PAGE_SIZE = 10;

export const TableScenario: React.FC<IProps> = ({
  columns,
  data,
  onChangePage,
  hasPagination,
}) => {
  const pageResult = useAppSelector(GraphAccessURLSelector).pageResult;

  return (
    <div className='table__list '>
      <Table
        rowKey='key'
        dataSource={data}
        columns={columns}
        pagination={false}
        locale={{ emptyText: <EmptyData /> }}
      />
      {hasPagination && pageResult && pageResult.total > PAGE_SIZE && (
        <Pagination
          showSizeChanger
          onChange={(current) => {
            if (onChangePage) {
              onChangePage(current);
            }
          }}
          current={pageResult.currentPage}
          total={pageResult.total}
          pageSize={pageResult.pageSize}
          showLessItems={false}
          size='small'
        />
      )}
    </div>
  );
};
