import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { GraphAttachment } from "../model";
import { PageResultModel } from "../model/page-result.model";
import GraphAttachmentService from "../services/graph-attachment.services";


interface GraphAttachmentState {
  loading: boolean;
  attachments: GraphAttachment[];
  pageResult: PageResultModel;
}

const initialState: GraphAttachmentState = {
  loading: false,
  attachments: [],
  pageResult: { currentPage: 0 } as PageResultModel,
};

export const getGraphAttachment = createAsyncThunk(
  "graphAttachment/getAttachment",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await GraphAttachmentService.getAll(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const graphAttachmentSlice = createSlice({
  name: "graphAttachment",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getGraphAttachment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGraphAttachment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.attachments = payload.attachFiles;
      state.pageResult = payload.pagedResult;
    });
  },
});

export const GraphAttachmentSelector = (state: RootState) => state.graphAttachment;

export default graphAttachmentSlice.reducer;
