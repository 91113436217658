import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { ChatSettingModel } from '../model';
import ChatSettingService from '../services/chatsetting.services';

interface ChatSettingState {
  loading: boolean;
  setting: ChatSettingModel;
}

const initialState: ChatSettingState = {
  loading: false,
  setting: {
    visitSettingCnt: '',
    timerSettingCnt: '',
    displayPosition: 'right-down'
  }
};

export const EditChatSetting = createAsyncThunk(
  'chatbot/editchatsetting',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await ChatSettingService.setSetting(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const GetChatSetting = createAsyncThunk(
  'chatbot/getchatsetting',
  async (body, { rejectWithValue }) => {
    try {
      const response = await ChatSettingService.getSetting();
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const chatSettingSlice = createSlice({
  name: 'chatsetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EditChatSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditChatSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.setting = action.payload.setting;
      })
      .addCase(GetChatSetting.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChatSetting.fulfilled, (state, action) => {
        state.loading = false;
        state.setting = action.payload.setting;
      })
  },
});

export const chatsettingSelector = (state: RootState) => state.chatsetting;

export default chatSettingSlice.reducer;
