import {
  Checkbox,
  DatePicker,
  Dropdown,
  Menu,
  message,
  Select,
  Tag,
  Tooltip,
} from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  changeScenarioAccess,
  getListScenario,
  GlobalSelector,
} from '../../../redux/global-slice';
import {
  changeStateConfirm,
  exportCSV,
  getAggregationScenario,
  getDetailTalklog,
  getListScenarioAnalytics,
} from '../../../redux/analytics-scenario-slice';
import { ScenarioTable } from '../../aggregate-scenario/scenario-table';
import { CoreIcon } from '../../../components/core-icon';
import { ArrowDownIcon } from '../../../assets/icons';
import { DialogHistoryModal } from '../dialog-history/dialog-history-modal';
import { LoadingOverlay } from '../../../components/loadingOverlay';
import { ButtonCustom } from '../../../components/button';
import { tenantSelector } from '../../../redux/tenant-slice';
import { DateRangePlans } from '../../../model/plan.model';
import axios from 'axios';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface InforHeader {
  id: string;
  scenarioName: string;
  startDate: string;
  endDate: string;
}
const envApiUrl = process.env.REACT_APP_API_URL;

export const AnalytiscScenario = () => {
  const monthFormat = 'YYYY/MM/DD';
  const formatDate = 'YYYY/MM/DD HH:mm:ss';
  const dispatch = useAppDispatch();
  const globalData = useAppSelector(GlobalSelector);
  const aggregationScenario = useAppSelector(
    (state) => state.analyticsScenario.aggregationScenario
  );
  const [stateConfirm, setStateConfirm] = useState();
  const [startDay, setStartDay] = useState(
    moment().startOf('month').format(monthFormat)
  );
  const [endDay, setEndDay] = useState(moment().format(monthFormat));
  const [itemName, setItemName] = useState([]);
  const [collumn, setCollumn] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentTalkLogDetail, setCurrentTalkLogDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentScenarioId, setCurrentScenarioId] = useState();
  const [currentFilter, setCurrentFilter] = useState('completed');
  const [currentListScenario, setCurrentListScenario] = useState<any>([]);
  const [currentFilterStateConfirm, setCurrentFilterStateConfirm] =
    useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [inforHeader, setInforHeader] = useState<InforHeader>({
    id: '',
    scenarioName: '',
    startDate: '',
    endDate: '',
  });
  const tenant = useAppSelector(tenantSelector);

  const dateRangePlan = ({
    trial: 1,
    light: 1,
    standard: 12,
    master: 36
  } as DateRangePlans)[tenant.currentPlan as keyof DateRangePlans];

  const stateComfirmCollumn = (title: string) => {
    const newCollumn = [
      {
        title: () => {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Dropdown
                overlayClassName='scenario__table__dropdown'
                overlay={menuColumn}
                trigger={['click']}
                placement='bottomRight'
              >
                <span>
                  {title || currentFilterStateConfirm}
                  <CoreIcon size={9} icon={<ArrowDownIcon />} />
                </span>
              </Dropdown>
            </div>
          );
        },
        width: 120,
        fixed: 'right',
        align: 'center',
        dataIndex: 'stateConfirm',
        key: 'stateConfirm',
        render: (text: any, record: any) => {
          return (
            <Checkbox
              onClick={(e) => {
                onChangeStateConfirm(e, record);
              }}
              checked={record.stateConfirm}
            />
          );
        },
      },
    ];
    return newCollumn;
  };

  const ticketReservationColumns = [
    {
      title: () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Dropdown
              overlayClassName='scenario__table__dropdown'
              overlay={menuColumn}
              trigger={['click']}
              placement='bottomRight'
            >
              <span>
                {handleJapanese(currentFilterStateConfirm)}
                <CoreIcon size={9} icon={<ArrowDownIcon />} />
              </span>
            </Dropdown>
          </div>
        );
      },
      width: 120,
      fixed: 'right',
      align: 'center',
      dataIndex: 'stateConfirm',
      key: 'stateConfirm',
      render: (text: any, record: any) => {
        return (
          <Checkbox
            onClick={(e) => {
              onChangeStateConfirm(e, record);
            }}
            checked={record.stateConfirm}
          />
        );
      },
    },
  ];

  const handleOpenModal = async (record: any) => {
    setIsModalVisible(true);
    try {
      const actionResult = await dispatch(
        getDetailTalklog({ chatId: record.chatId })
      );
      const response = unwrapResult(actionResult);
      setCurrentTalkLogDetail(response);
      setInforHeader({
        id: globalData.currentScenarioAccess,
        scenarioName: globalData.listScenario.find(
          (item) => item._id === globalData.currentScenarioAccess
        )?.filename,
        startDate: moment(response[0].createAt).format(formatDate),
        endDate: moment(response[response.length - 1].createAt).format(
          formatDate
        ),
      });
    } catch (error) { }
  };

  const handleJapanese = (title: string) => {
    if (title === 'all') {
      return '対応状況';
    }
    if (title === 'confirmed') {
      return '対応済';
    }
    if (title === 'notConfirm') {
      return '未対応';
    } else {
      return title;
    }
  };

  //handle change selectedBy
  const handleChangeFilter = (e: any) => {
    setCurrentFilterStateConfirm(e.key);
    setCollumn((prev: any[]) => {
      if (e.key) {
        return prev
          ?.splice(0, prev.length - 1)
          .concat(stateComfirmCollumn(handleJapanese(e.key)));
      }
    });
  };

  //useEffect change
  useEffect(() => {
    dispatch(
      getAggregationScenario({
        scenarioId: currentScenarioId,
        startDay: startDay,
        endDay: endDay,
        page: currentPage,
        pageSize: 10,
        filterBy: currentFilter,
        selectBy: currentFilterStateConfirm,
      })
    );
  }, [currentFilterStateConfirm]);

  const menuColumn = (
    <Menu onClick={handleChangeFilter}>
      <Menu.Item key='all'>対応状況</Menu.Item>
      <Menu.Item key='confirmed'>対応済</Menu.Item>
      <Menu.Item key='notConfirm'>未対応</Menu.Item>
    </Menu>
  );

  const handleChangeTenant = async () => {
    try {
      const actionResult = await dispatch(getListScenarioAnalytics());
      const response = unwrapResult(actionResult);
      setCurrentListScenario(response);
      if (response.length > 0) {
        initFetch(response[0]._id);
        setCurrentScenarioId(response[0]._id);
      }
    } catch (error) { }
  };

  //handle change tenant
  useEffect(() => {
    handleChangeTenant();
  }, [localStorage.getItem('tenantId')]);

  const onChangeDate = (date: any) => {
    const startDay = date[0].format(monthFormat);
    const endDay = date[1].format(monthFormat);
    setStartDay(startDay);
    setEndDay(endDay);
    initFetch(globalData.currentScenarioAccess, startDay, endDay);
  };

  const initFetch = async (
    currentScenario?: any,
    start?: any,
    end?: any,
    filterBy?: any,
    selectBy?: any
  ) => {
    setIsLoading(true);
    try {
      const actionResult = await dispatch(
        getAggregationScenario({
          scenarioId: currentScenario || globalData.currentScenarioAccess,
          startDay: start || startDay,
          endDay: end || endDay,
          page: 1,
          pageSize: 10,
          filterBy: filterBy || currentFilter,
          selectBy: selectBy || currentFilterStateConfirm,
        })
      );
      const response = unwrapResult(actionResult);
      if (response.aggregationFiles) {
        setItemName(
          handleMapItemName(response.listItemName)
            .filter((item: any) => item.status === 'active')
            .map((item: any, index: any) => index)
        );
        const columns = handleMapItemName(response.listItemName)
          .filter((item: any) => item.status === 'active')
          .map((item: any, index: any) => {
            return {
              title: item.itemName,
              dataIndex: item.itemName,
              key: item.itemName,
              // width: 150,
            };
          });
        if (columns.length === 0) {
          setCollumn(columns);
        } else {
          setCollumn(columns.concat(ticketReservationColumns));
        }
      }
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }
    }
    setIsLoading(false);
  };

  const handleMapItemName = (data: any) => {
    const listItemName = data?.map((item: any, index: any) => {
      return {
        _id: index,
        value: item.itemName,
        status: item.status,
        itemName: item.itemName,
      };
    });
    return listItemName;
  };

  const onHandleScenario = (data: any) => {
    dispatch(changeScenarioAccess(data));
    setCurrentScenarioId(data);
    initFetch(data);
  };

  const onHandleChangeFilter = (value: any) => {
    setCurrentFilter(value);
    initFetch(null, null, null, value);
  };

  const handleMapListScenario = (data: any) => {
    const listScenario = data.map((item: any) => {
      return {
        _id: item._id,
        value: item.filename,
        deletedAt: item.deletedAt,
      };
    });
    return listScenario;
  };

  const onHandleItemName = (value: any) => {
    setItemName(value.sort());
    const columns = value.map((item: any) => {
      return {
        title: () => {
          return (
            <div>
              <span>{aggregationScenario?.listItemName[item].itemName}</span>
              {aggregationScenario?.listItemName[item].status !== 'active' && (
                <Tag
                  color='#ff4133'
                  style={{
                    marginLeft: 5,
                    marginRight: 0,
                    padding: '0 5px',
                    fontSize: 10,
                  }}
                >
                  無効
                </Tag>
              )}
            </div>
          );
        },
        dataIndex: aggregationScenario?.listItemName[item].itemName,
        key: aggregationScenario?.listItemName[item].itemName,
        // width: 150,
      };
    });
    if (columns.length === 0) {
      setCollumn(columns);
    } else {
      setCollumn(columns.concat(ticketReservationColumns));
    }
  };

  const handleOnChangePage = (current: any) => {
    setCurrentPage(current);
    dispatch(
      getAggregationScenario({
        scenarioId: globalData.currentScenarioAccess,
        startDay: startDay,
        endDay: endDay,
        page: current,
        pageSize: 10,
        filterBy: currentFilter,
        selectBy: currentFilterStateConfirm,
      })
    );
  };

  const onChangeStateConfirm = async (e: any, record: any) => {
    e.stopPropagation();
    try {
      const actionResult = await dispatch(
        changeStateConfirm({
          chatId: record.chatId,
          stateConfirm: !record.stateConfirm,
        })
      );
      const response = unwrapResult(actionResult);
      if (response) {
        dispatch(
          getAggregationScenario({
            scenarioId: record.scenarioId,
            startDay: record.startDay,
            endDay: record.endDay,
            page: record.page,
            pageSize: 10,
            filterBy: record.filterBy,
            selectBy: record.selectBy,
          })
        );
        setStateConfirm((prev: any) => prev + 1);
      }
    } catch (error) { }
  };

  const handleFilterData = (data: any) => {
    const listItemName = itemName.map((item: any) => {
      return aggregationScenario?.listItemName[item].itemName;
    });
    return data.filter((obj: any) => {
      return Object.keys(obj).some((item) => listItemName.includes(item));
    });
  };

  const handleExportExcel = () => {
    const listItemName = itemName.map((item: any) => {
      return aggregationScenario?.listItemName[item].itemName;
    });
    const currentScenario = currentListScenario.find(
      (item: any) => item._id === globalData.currentScenarioAccess
    );
    const params = {
      scenarioId: globalData.currentScenarioAccess,
      startDay: startDay,
      endDay: endDay,
      filterBy: currentFilter,
      selectBy: currentFilterStateConfirm,
      scenarioName: currentScenario.filename,
      listItems: listItemName,
    };
    window.open(
      `${envApiUrl || window.location.protocol + '//' + window.location.hostname
      }/api/v1/bot-manager/${localStorage.getItem(
        'tenantId'
      )}/analysis/exportCSV?scenarioId=${params.scenarioId}&startDay=${params.startDay
      }&endDay=${params.endDay}&listItems=${params.listItems.join(
        ', '
      )}&filterBy=${params.filterBy}&scenarioName=${params.scenarioName
      }&selectBy=${params.selectBy}`,
      `_blank`
    );
  };

  return (
    <div>
      <LoadingOverlay style={{ marginLeft: 150 }} isLoading={isLoading} />

      <div className={clsx(styles.control__container)}>
        <div className={clsx(styles.control)}>
          <div
            className={clsx(styles.graphAttachment__control)}
            style={{ minWidth: 250 }}
          >
            <span>期間</span>
            <div
              style={{ position: 'relative', width: '235px' }}
              id='selectDate'
            >
              <RangePicker
                style={{ width: '220px' }}
                onChange={(e) => onChangeDate(e)}
                defaultValue={[moment().startOf('month'), moment()]}
                format={monthFormat}
                className={clsx(styles.rangePicker)}
                getPopupContainer={() => document.getElementById('selectDate')!}
                disabledDate={(current) => {
                  let customDate = moment().subtract(dateRangePlan, 'months').format();
                  return current < moment(customDate, "YYYY-MM-DD");
                }} 
              />
            </div>
          </div>
          {
            <div
              style={{ minWidth: 250, display: 'flex', alignItems: 'baseline' }}
            >
              <span style={{ marginRight: 10 }}>シナリオ</span>
              <div style={{ position: 'relative', minWidth: '205px' }} id='selectScenario'>
                <Select
                  onChange={onHandleScenario}
                  value={currentScenarioId}
                  // defaultValue={globalData.currentScenarioAccess}
                  style={{ minWidth: '190px', maxWidth: '190px' }}
                  getPopupContainer={() =>
                    document.getElementById('selectScenario')!
                  }
                >
                  {handleMapListScenario(globalData.listScenario).map(
                    (item: any, index: any) => (
                      <Option key={index} value={item._id}>
                        <Tooltip placement='topLeft' title={item.value}>
                          {item.value}
                          {item.deletedAt !== null && (
                            <Tag
                              color='#ff4133'
                              style={{
                                marginLeft: 5,
                                marginRight: 0,
                                padding: '0 5px',
                                fontSize: 10,
                              }}
                            >
                              削除
                            </Tag>
                          )}
                        </Tooltip>
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </div>
          }
          {
            <div
              style={{ minWidth: 310, display: 'flex', alignItems: 'baseline' }}
            >
              <span style={{ marginRight: 10 }}>フィルタ</span>
              <div style={{ position: 'relative' }} id='filter'>
                <Select
                  onChange={onHandleChangeFilter}
                  value={currentFilter}
                  style={{ minWidth: '230px' }}
                  getPopupContainer={() => document.getElementById('filter')!}
                >
                  <Option value='all'>すべて表示</Option>
                  <Option value='completed'>完了のシナリオのみ表示</Option>
                  <Option value='cvPoint'>CV地点があるシナリオのみ表示</Option>
                </Select>
              </div>
            </div>
          }
          {Array.isArray(aggregationScenario?.listItemName) && (
            <div style={{ display: 'flex' }}>
              <span style={{ minWidth: 45, marginTop: 5, marginRight: 10 }}>
                集計名
              </span>
              <Select
                onChange={onHandleItemName}
                value={itemName}
                style={{ minWidth: 300 }}
                mode='tags'
              >
                {handleMapItemName(aggregationScenario?.listItemName).map(
                  (item: any, index: any) => (
                    <Option key={index} value={index}>
                      {item.value}
                      {item.status !== 'active' && (
                        <Tag
                          color='#ff4133'
                          style={{
                            marginLeft: 5,
                            marginRight: 0,
                            padding: '0 5px',
                            fontSize: 10,
                          }}
                        >
                          無効
                        </Tag>
                      )}
                    </Option>
                  )
                )}
              </Select>
            </div>
          )}
        </div>
        <div className={styles.export}>
          <ButtonCustom
            type='primary'
            text='エクスポート'
            onClick={handleExportExcel}
            disabled={!globalData.currentScenarioAccess}
          />
        </div>
      </div>
      {!isLoading && (
        <ScenarioTable
          dataSource={
            collumn.length !== 0 &&
            handleFilterData(aggregationScenario.aggregationFiles).map(
              (item: any) => {
                return {
                  ...item,
                  scenarioId: globalData.currentScenarioAccess,
                  startDay: startDay,
                  endDay: endDay,
                  page: aggregationScenario.pagedResult.currentPage,
                  pageSize: 10,
                  filterBy: currentFilter,
                  selectBy: currentFilterStateConfirm,
                };
              }
            )
          }
          columns={collumn}
          title='航空券予約'
          onOpenModal={handleOpenModal}
          onChangePage={(current) => handleOnChangePage(current)}
        />
      )}
      <DialogHistoryModal
        title='対話ログ'
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        inforHeader={inforHeader}
        data={currentTalkLogDetail}
      />
    </div>
  );
};
