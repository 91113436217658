import { unwrapResult } from "@reduxjs/toolkit";
import { Alert, Form, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ButtonCustom } from "../../../components/button";
import { LANG } from "../../../lang";
import { authSelector, getMe, updateProfile } from "../../../redux/auth-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

export const ChangeName = () => {
  const fetchData = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data: any) => {
    const body: any = {
      name: data.name,
    };
    try {
      const actionResult = await dispatch(updateProfile(body));
      const response = unwrapResult(actionResult);
      if (!response.status) {
        message.warning({
          content: response.message,
          duration: 1.5,
        });

        reset();
      } else {
        message.success({
          content: LANG.EDIT_SUCCESS,
          duration: 1.5,
        });
      }
    } catch (error) {
    }
  };

  return (
    <form className="form__information" onSubmit={(e) => e.preventDefault()}>
      <Form.Item
      >
        <Controller
          name="name"
          rules={{
            required: false,
          }}
          defaultValue={fetchData.profile.name}
          control={control}
          render={({ field }) => <Input {...field} />}
        />
      </Form.Item>

      <div className="form__information__action">
        <ButtonCustom
          htmlType="submit"
          onClick={handleSubmit(onSubmit)}
          type="primary"
          text="保存"
        />
        <ButtonCustom
          onClick={() => reset()}
          htmlType="button"
          type="link"
          text="キャンセル"
        />
      </div>
    </form>
  );
};
