import styles from './index.module.scss';
import clsx from 'clsx';
import { useFieldArray, Controller } from 'react-hook-form';
import { FormItem } from '../../../../components/form-item';
import { IconWrap } from '../../../../components/icon-wrap';
import { DeleteIcon, PlusIcon } from '../../../../assets/icons';
import { Input, Form } from 'antd';

interface IProps {
  nestIndex: number;
  control: any;
  register: any;
  errors: any;
  show: boolean;
}

export const NestedDropdown: React.FC<IProps> = ({
  nestIndex,
  control,
  register,
  errors,
  show,
}) => {
  const { fields, remove, insert } = useFieldArray<any>({
    control,
    name: `scenarioSettings[${nestIndex}].itemValues`,
  });

  return (
    <div
      className={clsx(styles.add__option, {
        [styles.show]: !show,
      })}
    >
      {fields.map((item: any, k) => {
        return (
          <div key={item.id} className={clsx(styles.custom__option__container)}>
            <Form.Item
              label={`オプション ${k + 1}`}
              validateStatus={
                errors?.scenarioSettings?.[nestIndex]?.itemValues?.[k]?.text
                  ? 'error'
                  : ''
              }
              help={
                errors?.scenarioSettings?.[nestIndex]?.itemValues?.[k]?.text &&
                '入力してください。'
              }
              className={clsx(styles.form__item)}
            >
              <Controller
                name={
                  `scenarioSettings[${nestIndex}].itemValues[${k}].text` as any
                }
                rules={{
                  required: show,
                }}
                control={control}
                render={({ field }) => (
                  <Input
                    autoFocus={true}
                    style={{ height: 32, width: 200 }}
                    placeholder='オプション'
                    {...field}
                    className={styles.filename__input}
                  />
                )}
              />
            </Form.Item>
            <Form.Item
              label={`回答別リアクション ${k + 1}`}
              validateStatus={
                errors?.scenarioSettings?.[nestIndex]?.itemReactions?.[k]?.text
                  ? 'error'
                  : ''
              }
              help={
                errors?.scenarioSettings?.[nestIndex]?.itemReactions?.[k]?.text &&
                '入力してください。'
              }
              className={clsx(styles.form__item)}
            >
              <Controller
                name={
                  `scenarioSettings[${nestIndex}].itemValues[${k}].reaction` as any
                }
                rules={{
                  required: show,
                }}
                control={control}
                render={({ field }) => (
                  <Input.TextArea
                    style={{ width: 370, marginLeft: 10 }}
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    placeholder='回答別リアクション'
                    {...field}
                    className={clsx(
                      styles.filename__input,
                      'textareaResize'
                    )}
                  />
                )}
              />
            </Form.Item>
            <div className={clsx(styles.action)}>
              <div
                className={clsx(styles.plus__icon, styles.icon__wrap)}
                onClick={() =>
                  insert(k + 1, {
                    text: '',
                  })
                }
              >
                <IconWrap icon={<PlusIcon fill="var(--color-orange-1)" />} />
              </div>
              <div
                className={clsx(styles.delete__icon, styles.icon__wrap)}
                onClick={() => {
                  if (fields.length > 1) {
                    remove(k);
                  }
                }}
              >
                <IconWrap icon={<DeleteIcon fill="#FF4133" />} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
