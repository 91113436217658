import { DatePicker, message } from 'antd';
import styles from './index.module.scss';
import clsx from 'clsx';
import { TableGraphAttachment } from './table-attachment';
import { FileIcon } from '../../../../components/file-icon';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  getGraphAttachment,
  GraphAttachmentSelector,
} from '../../../../redux/graph-attachment-slice';
import { IconWrap } from '../../../../components/icon-wrap';
import { ArrowDownIcon } from '../../../../assets/icons';
import { ButtonCustom } from '../../../../components/button';
import { GlobalSelector } from '../../../../redux/global-slice';
import { tenantSelector } from '../../../../redux/tenant-slice';
import { DateRangePlans } from '../../../../model/plan.model';

const { RangePicker } = DatePicker;

export const GraphAttachment = () => {
  const envApiUrl = process.env.REACT_APP_API_URL;

  const monthFormat = 'YYYY/MM/DD';
  const dispatch = useAppDispatch();
  const fetchData = useAppSelector(GraphAttachmentSelector);
  const globalData = useAppSelector(GlobalSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const [startDay, setStartDay] = useState(
    moment().startOf('month').format(monthFormat)
  );
  const [endDay, setEndDay] = useState(moment().format(monthFormat));
  const [sortUrlName, setSortUrlName] = useState(0);
  const [sortClickAmount, setSortClickAmount] = useState(-1);
  const [sortScenario, setSortScenario] = useState(0);

  const tenant = useAppSelector(tenantSelector);

  const dateRangePlan = ({
    trial: 1,
    light: 1,
    standard: 12,
    master: 36
  } as DateRangePlans)[tenant.currentPlan as keyof DateRangePlans];

  const onHandleSort = (type: string) => {
    if (type === 'url') {
      setSortUrlName((prev) => (prev === 0 ? -1 : prev * -1));
      setSortClickAmount(0);
      setSortScenario(0);
    }
    if (type === 'click') {
      setSortClickAmount((prev) => (prev === 0 ? -1 : prev * -1));
      setSortUrlName(0);
      setSortScenario(0);
    }
    if (type === 'scenario') {
      setSortScenario((prev) => (prev === 0 ? -1 : prev * -1));
      setSortClickAmount(0);
      setSortUrlName(0);
    }
  };

  const ButtonSort = (type: any) => {
    return (
      <button className={clsx(styles.btn__sort)} type='button'>
        {(() => {
          if (type === 1) {
            return (
              <IconWrap
                className={clsx(styles.invert)}
                size={10}
                icon={<ArrowDownIcon />}
              />
            );
          }
          if (type === -1) {
            return <IconWrap size={10} icon={<ArrowDownIcon />} />;
          }
          if (type === 0) {
            return;
          }
        })()}
      </button>
    );
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: () => {
        return (
          <>
            <div>
              <span
                onClick={() => onHandleSort('url')}
                className={clsx(styles.pointer)}
              >
                ファイルURL
                {ButtonSort(sortUrlName)}
              </span>
            </div>
          </>
        );
      },
      width: '30%',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (text: any, record: any) => (
        <FileIcon type={record.fileName.split('.')[1]} name={record.fileName} />
      ),
    },
    {
      title: () => {
        return (
          <>
            <div>
              <span
                className={clsx(styles.pointer)}
                onClick={() => onHandleSort('click')}
              >
                クリック数
                {ButtonSort(sortClickAmount)}
              </span>
            </div>
          </>
        );
      },
      width: '30%',
      dataIndex: 'display',
      key: 'display',
      render: (text: any, record: any) => record.display && record.display[0],
    },
    {
      title: () => {
        return (
          <>
            <div>
              <span
                onClick={() => onHandleSort('scenario')}
                className={clsx(styles.pointer)}
              >
                シナリオ
                {ButtonSort(sortScenario)}
              </span>
            </div>
          </>
        );
      },
      width: '30%',
      dataIndex: 'scenarioName',
      key: 'scenarioName',
    },
  ];

  const onChangeDate = (date: any) => {
    const startDay = date[0].format(monthFormat);
    const endDay = date[1].format(monthFormat);
    setStartDay(startDay);
    setEndDay(endDay);
  };

  const getRequestParams = (
    startDay: string,
    endDay: string,
    page: number,
    sortUrlName: number,
    sortClickAmount: number,
    sortScenario: number
  ) => {
    let params: any = {};
    if (startDay) {
      params['startDay'] = startDay;
    }
    if (endDay) {
      params['endDay'] = endDay;
    }
    if (page) {
      params['page'] = page;
    }
    if (sortUrlName) {
      params['sortByFileName'] = sortUrlName;
    }
    if (sortClickAmount) {
      params['sortByDisplay'] = sortClickAmount;
    }
    if (sortScenario) {
      params['sortByScenarioName'] = sortScenario;
    }
    return params;
  };

  const onPagination = (page: number, pageSize: number) => {
    setCurrentPage(Number(page));
  };

  const params = getRequestParams(
    startDay,
    endDay,
    currentPage,
    sortUrlName,
    sortClickAmount,
    sortScenario
  );

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getGraphAttachment(params));
      const response = unwrapResult(actionResult);
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }

    }
  }, [
    dispatch,
    startDay,
    endDay,
    currentPage,
    sortUrlName,
    sortClickAmount,
    sortScenario,
  ]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const handleExportExcel = () => {
    const params = {
      startDay: startDay,
      endDay: endDay,
      page: currentPage,
      sortByFileName: sortUrlName,
      sortByDisplay: sortClickAmount,
      sortByScenarioName: sortScenario,
    };
    window.open(
      `${envApiUrl || window.location.protocol + '//' + window.location.hostname
      }/api/v1/bot-manager/${localStorage.getItem(
        'tenantId'
      )}/analysis/exportAttachFile?startDay=${params.startDay}&endDay=${params.endDay
      }&sortByDisplay=${params.sortByDisplay}&sortByFileName=${params.sortByFileName
      }&sortByScenarioName=${params.sortByScenarioName}&page=${params.page
      }&pageSize=10`,
      `_blank`
    );
  };

  return (
    <div className={clsx()}>
      <div className={clsx(styles.graphAttachment__control)}>
        <span>期間</span>
        <div style={{ position: 'relative' }} id='selectMonth'>
          <RangePicker
            onChange={(e) => onChangeDate(e)}
            defaultValue={[moment().startOf('month'), moment()]}
            format={monthFormat}
            className={clsx(styles.rangePicker)}
            getPopupContainer={() => document.getElementById('selectMonth')!}
            disabledDate={(current) => {
              let customDate = moment().subtract(dateRangePlan, 'months').format();
              return current < moment(customDate, "YYYY-MM-DD");
            }} 
          />
        </div>
        <div className={styles.export}>
          <ButtonCustom
            type='primary'
            text='エクスポート'
            onClick={handleExportExcel}
            disabled={!globalData.currentScenarioAccess}
          />
        </div>
      </div>
      <TableGraphAttachment
        onChangePage={(page: number, pageSize: number) =>
          onPagination(page, pageSize)
        }
        columns={columns}
        data={fetchData.attachments}
        currentPage={currentPage}
        pageResult={fetchData?.pageResult}
        pagination={Number(fetchData?.pageResult.total) > 10 ? true : false}
      />
    </div>
  );
};
