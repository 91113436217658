export const EditIcon = () => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.45 2.42904L7.571 4.55054L2.621 9.50004H0.5V7.37854L5.45 2.42854V2.42904ZM6.157 1.72204L7.2175 0.661042C7.31126 0.567306 7.43842 0.514648 7.571 0.514648C7.70358 0.514648 7.83074 0.567306 7.9245 0.661042L9.339 2.07554C9.43274 2.16931 9.48539 2.29646 9.48539 2.42904C9.48539 2.56162 9.43274 2.68878 9.339 2.78254L8.278 3.84304L6.157 1.72204Z' fill="currentColor"/>
    </svg>
  );
};
