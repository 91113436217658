import { Spin } from 'antd';
import clsx from 'clsx';
import styled, { css } from 'styled-components';
import styles from './index.module.scss';

const DarkBackground = styled.div`
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  right: 0;
  top: 0;
  width: calc(100% - 100px); /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0); /* Black w/ opacity */
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IProps {
  isLoading: boolean;
  style?: any;
}

export const LoadingOverlay: React.FC<IProps> = ({ isLoading, style }) => {
  return (
    <DarkBackground className={clsx({ [styles.loadingOverlay]: !isLoading })}>
      <Spin style={style} size='large' />
    </DarkBackground>
  );
};
