import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import AnalyticsScenarioService from '../services/analytics-scenario.services';
import globalServices from '../services/global.services';

interface AnalyticsScenarioState {
  loading: boolean;
  aggregationScenario: any;
  initScenario: string;
}

const initialState: AnalyticsScenarioState = {
  loading: false,
  aggregationScenario: {},
  initScenario: '',
};

export const getAggregationScenario = createAsyncThunk(
  'analyticsScenario/getaggregation',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await AnalyticsScenarioService.getAggregationScenario(
        body
      );
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getDetailTalklog = createAsyncThunk(
  'analyticsScenario/getdetailtalklog',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await AnalyticsScenarioService.getDetailTalklog(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const changeStateConfirm = createAsyncThunk(
  'analyticsScenario/changestateconfirm',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await AnalyticsScenarioService.changeStateConfirm(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getListScenarioAnalytics = createAsyncThunk(
  'global/getListScenario',
  async (params, { rejectWithValue }) => {
    try {
      const response = await globalServices.getListScenario();
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const exportCSV = createAsyncThunk(
  'analyticsScenario/exportCSV',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await AnalyticsScenarioService.exportCSV(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const analyticsScenarioSlice = createSlice({
  name: 'analyticsScenario',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAggregationScenario.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAggregationScenario.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.aggregationScenario = payload;
      })
      .addCase(getListScenarioAnalytics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListScenarioAnalytics.fulfilled, (state, { payload }) => {
        state.loading = false;
        if (payload.length > 0) {
          state.initScenario = payload[0]._id;
        }
      })
      .addCase(exportCSV.pending, (state) => {
        state.loading = true;
      })
      .addCase(exportCSV.fulfilled, (state, { payload }) => {
        state.loading = false;
        // state.aggregationScenario = payload;
      });
  },
});

export default analyticsScenarioSlice.reducer;
