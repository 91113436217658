import { unwrapResult } from '@reduxjs/toolkit';
import { Editor } from '@tinymce/tinymce-react';
import { message, Modal } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowDownIcon,
  CalendarIcon,
  SaveIcon,
  SubmitIcon,
  TrashIcon,
} from '../../assets/icons';
import { EditIcon } from '../../assets/icons/EditIcon';
import { ButtonCustom } from '../../components/button';
import { PostModel } from '../../model';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getPosts, postSelector, removePost } from '../../redux/post-slice';
import styles from './index.module.scss';
import './post.scss';
import { DatePicker } from 'antd';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { confirm } = Modal;

interface IProps {
  data?: PostModel;
}
export const DetailPost: React.FC<IProps> = () => {
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fetchPost = useAppSelector(postSelector);

  const showDeleteConfirm = (post: PostModel) => {
    confirm({
      title: 'Are you sure delete this post?',
      content: `${post?.title}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        return onHandleDelete(post?._id);
      },
    });
  };

  const onHandleDelete = async (id: string) => {
    try {
      const actionResult = await dispatch(removePost(id));
      const response = unwrapResult(actionResult);
      if (response.status) {
        dispatch(getPosts());
        message.success('Removed post');
        navigate('/ad-setting/');
      }
    } catch (error) {
    }
  };

  return (
    <div className={clsx(styles.writting__tools)}>
      <div className={clsx(styles.post__header)}>
        <h3>{fetchPost.currentPost?.title}</h3>
        <div className={clsx(styles.post__header__status)}>
          <span>状態</span>
          <span
            className={clsx(styles.status, {
              [styles.status__active]:
                fetchPost.currentPost.status === 'active',
            })}
          >
            {fetchPost.currentPost.status === 'active' ? '無効' : '有効'}
          </span>
        </div>
      </div>
      <div className={clsx(styles.post)}>
        <div className={clsx(styles.post__content)}>
          <Editor
            apiKey='zfxajk11y3wmwhlxq96puwtw0f6y5vt376c4giqqb7bdv2w7'
            initialValue={fetchPost.currentPost.content}
            init={{
              skin: 'snow',
              icons: 'thin',
              placeholder: '現在、この投稿のコンテンツはありません',

              height: '100%',
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen textcolor ',
                'insertdatetime media table paste code help wordcount',
              ],
              textcolor_rows: '4',
              statusbar: false,
              toolbar: false,
            }}
            outputFormat='html'
            disabled={true}
          // toolbar="code"
          />
        </div>
      </div>
      <div className={clsx(styles.footer)}>
        <div className={clsx(styles.action_left)}>
          <div className={clsx(styles.button__type__post)}>
            <ButtonCustom
              type='primary'
              disabled={true}
              width={125}
              icon={fetchPost.isPostNow ? <SubmitIcon /> : <CalendarIcon />}
              text={fetchPost.isPostNow ? '今すぐ投稿' : '時間を選択'}
            />
            <ButtonCustom
              disabled={true}
              type='primary'
              icon={<ArrowDownIcon />}
            />
          </div>
          {!fetchPost.isPostNow && (
            <RangePicker
              defaultValue={[
                moment(fetchPost.currentPost.startDate),
                moment(fetchPost.currentPost.endDate),
              ]}
              disabled={true}
              showTime
            />
          )}
        </div>
        <div className={clsx(styles.action_right)}>
          <ButtonCustom
            disabled={true}
            type='primary'
            width={125}
            icon={<SaveIcon />}
            text='保存'
          />
          <ButtonCustom
            disabled={true}
            onClick={() => showDeleteConfirm(fetchPost.currentPost)}
            type='ghost'
            color='red'
            width={125}
            icon={<TrashIcon />}
            text='削除'
          />
        </div>
      </div>
    </div>
  );
};
