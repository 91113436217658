import styles from './index.module.scss';
import clsx from 'clsx';
import { FormItem } from '../../../components/form-item';
import { useRef } from 'react';
import { Button, Input, message } from 'antd';
import axiosClient from '../../../http-common/axiosAdmin';

interface IProps {
  originUrl: string;
  tenantId: string;
}
const envApiUrl = process.env.REACT_APP_API_URL
export const Embedded: React.FC<IProps> = ({ originUrl, tenantId }) => {
  const textAreaRef = useRef<any>(null);
  function copyTextToClipboard() {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
      message.success('クリップボードにコピーしました')
    }
  }
  return (
    <div className={clsx(styles.embedded__container)}>
      <p>埋め込みリンク</p>
      <div className={clsx(styles.embedded__form)}>
        <p>
          {
            'チャットボットを表示したいページの</body>タグの前に以下のHTMLを埋め込んでください。'
          }
        </p>
        <Input
          style={{ marginBottom: 10 }}
          ref={textAreaRef}
          type='text'
          value={`<script src="${originUrl}/chatbot/forLP.js" charset="UTF-8" data-tenant-id="${tenantId}" data-url-page-counter=""></script>`}
        />
        <p>
          {
            'ページ上でボタンをクリックしてチャットボットを呼び出す場合は、HTML上の該当の箇所にクラス名「or-popup-chat」を追記ください。'
          }
        </p>
        <Input
          style={{ marginBottom: 10 }}
          type='text'
          value={`例）<button class="or-popup-chat">チャットボット呼び出し</button>`}
        />
        <Button
          type='primary'
          className={clsx(styles.copy__button)}
          onClick={copyTextToClipboard}
        >
          埋め込みタグをクリップボードにコピー
        </Button>

        <a
          rel="noreferrer"
          className={clsx(styles.demo__button)}
          href={`${envApiUrl || (window.location.protocol + '//' + window.location.hostname)}/api/v1/bot-manager/${localStorage.getItem('tenantId')}/draft`}
          target='_blank'
        >
          <Button
            type='primary'
            className={clsx(styles.copy__button)}
          >
            デモ画面表示
          </Button></a>
      </div>
    </div>
  );
};
