import axiosClient from '../http-common/axiosClient';
import { LoginDTO } from '../dto/index';
import { UserModel } from '../model/index';

class AuthService {
  login = (body: LoginDTO) => {
    const url = '/login';
    return axiosClient.post(url, body);
  };
  getMe = () => {
    const url = '/me';
    return axiosClient.get<UserModel>(url);
  };
  update = (data: any) => {
    const url = '/me';
    return axiosClient.put(url, data);
  };

  checkMail(email: string) {
    return axiosClient.post("/emailExist", { email: email });
  }
}

export default new AuthService();
