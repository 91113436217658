import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

interface IProps {
  icon?: React.ReactNode;
  className?: string;
  onClick?: (e: any) => void;
  size?: number;
  color?: string;
}

export const IconWrap: React.FC<IProps> = ({
  icon,
  className,
  onClick,
  size,
  color,
}) => {
  return (
    <div
      className={clsx(className, styles.icon__wrap)}
      style={{ fontSize: size, color: color }}
      onClick={onClick}
    >
      {icon}
    </div>
  );
};
