import React from 'react';
import { hot } from 'react-hot-loader';

import { useEffect } from 'react';
import 'antd/dist/antd.css';
import './App.scss';
import './App.less';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AttachmentPage } from './pages/attachment';
import { ScenarioSettingPage } from './pages/scenario-setting';
import { ChatDisplaySettingPage } from './pages/chat-display-setting';
import { AdvertisementSettingPage } from './pages/ad-setting';
import { DetailPost } from './pages/ad-setting/detail-post';
import { EditPost } from './pages/ad-setting/edit-post';
import { ChatBotUISettingPage } from './pages/chatbot-ui-setting';
import { LoginPage } from './pages/login';
import { AuthLayout } from './layout/auth-page';
import { GraphScenario } from './pages/analytics/graph/scenario';
import { GraphAttachment } from './pages/analytics/graph/attachment';
import { GraphAccessNumber } from './pages/analytics/graph/access-number';
import { GraphOpenExit } from './pages/analytics/graph/open-exit';
import { ProtectedRoute } from './router/protectedRoute';
import { authSelector } from './redux/auth-slice';
import { useAppSelector } from './redux/hooks';
import { MenuLayout } from './layout/menu-layout';
import { AnalyticsLayout } from './layout/analytics-layout';
import { CreatePost } from './pages/ad-setting/create-post';
import { EmptyPost } from './pages/ad-setting/empty-post';
import { Profile } from './pages/profile';
import { Developing } from './pages/developing';
import { AnalistAttachmentPage } from './pages/aggregate-attachment';
import { ProtectedRouteEdit } from './router/protectedRouteEdit';
import { AnalytiscScenario } from './pages/analytics/scenario';

function App() {
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const isLoggedIn = useAppSelector(authSelector).isAuthenticated;
  const isAuthenticated = localStorage.getItem('accessTokenBot') ? true : false;
  useEffect(() => {}, [isLoggedIn]);

  return (
    <BrowserRouter basename='bot'>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='login' element={<LoginPage />} />
        </Route>
        <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
          <Route element={<MenuLayout />}>
            <Route index element={<ChatBotUISettingPage />} />
            <Route path='profile' element={<Profile />} />
            <Route path='attachment' element={<AttachmentPage />} />
            <Route
              path='chatbotUI-setting'
              element={<ChatBotUISettingPage />}
            />
            <Route path='ad-setting' element={<AdvertisementSettingPage />}>
              <Route index element={<EmptyPost />} />
              <Route path='add' element={<CreatePost />} />
              <Route element={<ProtectedRouteEdit isEditable={isEditable} />}>
                <Route path='edit/:postId' element={<EditPost />} />
              </Route>
              <Route path='detail/:postId' element={<DetailPost />} />
            </Route>

            <Route path='scenario-setting' element={<ScenarioSettingPage />} />
            <Route path='chat-display-setting' element={<ChatDisplaySettingPage />} />
            <Route path='analytics' element={<AnalyticsLayout />}>
              <Route path='graph/scenario' element={<GraphScenario />} />
              <Route path='graph/attachment' element={<GraphAttachment />} />
              <Route
                path='graph/access-number'
                element={<GraphAccessNumber />}
              />
              <Route path='graph/open-exit' element={<GraphOpenExit />} />
              <Route path='dialog-history' element={<Developing />} />
              <Route path='scenario' element={<AnalytiscScenario />} />
              <Route
                path='analytics-attachment'
                element={<AnalistAttachmentPage />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
