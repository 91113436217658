import { Menu, Select } from 'antd';

//icons
import { AttachmentIcon } from '../../assets/icons/AttachmentIcon';
import { ProfileIcon } from '../../assets/icons/ProfileIcon';
import { AdvertisementIcon } from '../../assets/icons/AdvertisementIcon';
import { ScenarioIcon } from '../../assets/icons/ScenarioIcon';
import { ColorIcon } from '../../assets/icons/ColorIcon';
import { AnalistIcon } from '../../assets/icons/AnalistIcon';
import { SettingIcon } from '../../assets/icons/SettingIcon';
import { LogoutIcon } from '../../assets/icons/LogoutIcon';

//logo
import Logo from '../../assets/images/Logo.png';

import clsx from 'clsx';
import styles from './index.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { IconWrap } from '../../components/icon-wrap';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
// import { logout } from '../../redux/auth-slice';
import {
  changeTenant,
  getTenants,
  tenantSelector,
} from '../../redux/tenant-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { authSelector, getMe } from '../../redux/auth-slice';
import moment from 'moment';

export const MenuLayout = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  let url = window.location.pathname.split('/')[2];
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fetchTenant = useAppSelector(tenantSelector);
  const authData = useAppSelector(authSelector);
  const isAuthenticated = !!localStorage.getItem('accessTokenBot');

  const [selectedKey, setSelectedKey] = useState<string>(`/${url}`);
  const [isReload, setIsReload] = useState<boolean>(false);

  interface planDatas {
    trial: string;
    light: string;
    standard: string;
    master: string;
  }
  const planData = {
    trial:    "トライアル",
    light:    "ライト",
    standard: "スタンダード",
    master:   "マスター"
  } as planDatas; 

  const handleClick = (e: any) => {
    //route to analytics scenario when click analytics
    if (e.key === '/analytics') {
      navigate('/analytics/scenario');
      setSelectedKey(e.key);
    } else {
      navigate(`${e.key}`);
      setSelectedKey(e.key);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('accessTokenBot');
    // dispatch(logout());
    navigate('/login');
  };
  const onChangeTenant = async (e: any) => {
    setIsReload(true);
    await dispatch(changeTenant(e));
    setIsReload(false);
  };

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getTenants());
      const response = unwrapResult(actionResult);
    } catch (error) {
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getMe());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    initFetch();
  }, [initFetch, isReload]);

  return (
    <div className={clsx(styles.profile__container)}>
      {(() => {
        if (fetchTenant.currentLimit != '' && moment(fetchTenant.currentLimit).isBefore()) {
          return (
          <div>
            <div className={clsx(styles.bg__modal)}></div>
            <div className={clsx(styles.ft__modal)}>
              {moment(fetchTenant.currentLimit).format('YYYY/MM/DD')}に{planData[fetchTenant.currentPlan as keyof planDatas]}プランの期限が終了しました。
              {(fetchTenant.currentPlan == 'trial') && 
              <div className={clsx(styles.ft__modal__guide)}> 
                本プランへの移行は、担当者までご連絡ください。<br/>
                <a href="mailto:sales01@aster-ai.com">sales01@aster-ai.com</a>
              </div>
              }
            </div>
          </div>
        );
      }
      })()}
      <div
        className={clsx(styles.profile__menu__container)}
        onMouseOut={() => setCollapsed(true)}
        onMouseOver={() => setCollapsed(false)}
      >
        <div className={clsx(styles.profile__header)}>
          <div className={clsx(styles.logo)}>
            <img src={Logo} alt='Logo' />
          </div>
          <div
            className={clsx(styles.title, { [styles.hidden__div]: collapsed })}
          >
            <p className={clsx(styles.sub1)}>forLP</p>
            <p className={clsx(styles.sub2)}>チャットボット</p>
          </div>
        </div>
        <div
          className={clsx(styles.profile__main, {
            [styles.hidden]: collapsed,
          })}
        >
          <Menu
            onClick={handleClick}
            selectedKeys={[selectedKey]}
            className={clsx(styles.profile__menu__main, 'menu__main')}
          >
            <Menu.Item
              key='/profile'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<ProfileIcon />}
                />
              }
            >
              プロファイル
            </Menu.Item>
            <Menu.Item
              key='/attachment'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<AttachmentIcon />}
                />
              }
            >
              添付
            </Menu.Item>
            <Menu.Item
              key='/chatbotUI-setting'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<ColorIcon />}
                />
              }
            >
              チャットボットUIの設定
            </Menu.Item>

            {(() => {
              if (['trial', 'standard', 'master'].includes(fetchTenant.currentPlan)) {
                return (<Menu.Item
                key='/ad-setting'
                icon={
                  <IconWrap
                    className={styles.icon__styles}
                    icon={<AdvertisementIcon />}
                  />
                }
              >
                広告投稿の設定
              </Menu.Item>);
              }
            })()}
            
            <Menu.Item
              key='/scenario-setting'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<ScenarioIcon />}
                />
              }
            >
              シナリオの設定
            </Menu.Item>
            <Menu.Item
              key='/chat-display-setting'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<SettingIcon />}
                />
              }
            >
              チャットの表示設定
            </Menu.Item>
            <Menu.Item
              key='/analytics'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<AnalistIcon />}
                />
              }
            >
              分析
            </Menu.Item>
          </Menu>
          <ul className={clsx(styles.profile__username)}>
            <li>
              <div>
                {authData?.profile?.username &&
                  authData?.profile?.username.charAt(0).toUpperCase()}
              </div>
              <span className={clsx(styles.profile__name)}>
                {authData?.profile?.name}
              </span>
            </li>
          </ul>
          <Menu
            selectedKeys={[selectedKey]}
            className={clsx(styles.profile__menu__main)}
          >
            <Menu.Item
              onClick={handleLogout}
              key='8'
              icon={
                <IconWrap
                  className={styles.icon__styles}
                  icon={<LogoutIcon />}
                />
              }
            >
              ログアウト
            </Menu.Item>
          </Menu>
        </div>
      </div>
      <div className={clsx(styles.profile__content)}>
        <div className={clsx(styles.profile__content__header)}>
          <div>
            <p>アカウント名</p>
            <Select
              onChange={(e) => onChangeTenant(e)}
              value={fetchTenant.currentTenant._id}
              options={fetchTenant.tenants.map((tenant) => ({
                value: tenant._id,
                label: tenant.name,
              }))}
            />
          </div>
        </div>
        <div className={clsx(styles.profile__content__main)}>
          {!isReload && <Outlet />}
        </div>
      </div>
    </div>
  );
};
