import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './rootSaga';
import authSlice from './auth-slice';
import tenantSlice from './tenant-slice';
import counterSlice from '../features/counter/counterSlice';
import chatbotSlice from './chatbot-slice';
import chatSettingSlice from './chat-setting-slice';
import postSlice from './post-slice';
import scenarioSlice from './scenario-setting';
import graphAttachmentSlice from './graph-attachment-slice';
import graphStartChatSlice from './graph-start-chat-slice';
import graphAccessUrlSlice from './graph-access-url-slice';
import globalSlice from './global-slice';
import analyticsScenarioSlice from './analytics-scenario-slice';
import { catchResponse } from '../http-common/axiosAdmin';

const middlewareSaga = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    auth: authSlice,
    counter: counterSlice,
    tenant: tenantSlice,
    chatbot: chatbotSlice,
    chatsetting: chatSettingSlice,
    scenario: scenarioSlice,
    post: postSlice,
    graphStartChat: graphStartChatSlice,
    graphAttachment: graphAttachmentSlice,
    graphAccessURL: graphAccessUrlSlice,
    global: globalSlice,
    analyticsScenario: analyticsScenarioSlice,
  },

  // khai bao saga middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middlewareSaga),
});
middlewareSaga.run(rootSaga);

catchResponse(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
