export const LANG = {
  USERNAME_REQUIRED: '入力してください。',
  USERNAME_INVALID: '入力したユーザー名は無効です。',
  PASSWORD_NOT_MATCH: 'パスワードが一致しません。',
  OLD_PASSWORD_REQUIRED: '現在のパスワードを入力してください。',
  PASSWORD_REQUIRED: '新しいパスワードを入力してください。',
  CONFIRM_PASSWORD_REQUIRED: 'パスワードの確認が必要です。',
  PASSWORD_INVALID: '入力したパスワードは無効です。',
  EMAIL_INVALID: '入力した電子メール アドレスは無効です。',
  BUTTON_YES: '確定',
  BUTTON_NO: 'キャンセル',
  ADD_SUCCESS: '追加が完了しました。',
  EDIT_SUCCESS: '編集が完了しました。',
  CONFIRM_DELETE_USER: 'このユーザを削除してもよろしいですか。',
  CONFIRM_DELETE_TENANT: 'このアカウントを削除してもよろしいですか。',
  COPIED: 'コピー',
  TITLE_REQUIRED: 'タイトルは必要です。',
  CONTENT_REQUIRED: 'コンテンツは必須です。'
};
