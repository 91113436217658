import { unwrapResult } from "@reduxjs/toolkit";
import { Alert, Form, Select, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ButtonCustom } from "../../../components/button";
import { LANG } from "../../../lang";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  chatsettingSelector,
  EditChatSetting,
  GetChatSetting
} from '../../../redux/chat-setting-slice';

export const ChangeDisplayPosition = () => {
  const fetchData = useAppSelector(chatsettingSelector);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data: any) => {
    const body: any = {
      setting: {
        visitSettingCnt: fetchData.setting.visitSettingCnt,
        timerSettingCnt: fetchData.setting.timerSettingCnt,
        displayPosition: data.displayPosition
      },
    };
    try {
      const actionResult = await dispatch(EditChatSetting(body));
      const response = unwrapResult(actionResult);
      if (!response.status) {
        message.warning({
          content: response.message,
          duration: 1.5,
        });

        reset();
      } else {
        message.success({
          content: LANG.EDIT_SUCCESS,
          duration: 1.5,
        });
      }
    } catch (error) {
    }
  };

  return (
    <form className="form__information" onSubmit={(e) => e.preventDefault()}>
      <Form.Item
      >
        <Controller
          name='displayPosition'
          defaultValue={fetchData.setting.displayPosition}
          control={control}
          render={({ field }) => (
            <Select
              size='large'
              placeholder='右下'
              {...field}
            >
              <Select.Option value="right-down">右下</Select.Option>
              <Select.Option value="center">中央（ポップアップ）</Select.Option>
            </Select>
          )}
        />
      </Form.Item>

      <div className="form__information__action">
        <ButtonCustom
          htmlType="submit"
          onClick={handleSubmit(onSubmit)}
          type="primary"
          text="保存"
          spacing={true}
          autoFocus={true}    
          index={0}
        />
        <ButtonCustom
          onClick={() => reset()}
          htmlType="button"
          type="link"
          text="キャンセル"
        />
      </div>
    </form>
  );
};
