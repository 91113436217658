import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import graphStartChatServices from "../services/graph-start-chat.services";
import { RootState } from "./store";


interface GraphStartChatState {
  loading: boolean;
  startChat: any;
  startScenario: any;
  endScenario: any;
}

const initialState: GraphStartChatState = {
  loading: false,
  startChat: {},
  startScenario: {},
  endScenario: {},
};

export const getStartChat = createAsyncThunk(
  "graphStartChat/getStartChat",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await graphStartChatServices.getAll(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getStartScenario = createAsyncThunk(
  "graphStartScenario/getStartScenario",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await graphStartChatServices.getStartScenario(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getEndScenario = createAsyncThunk(
  "graphEndScenario/getEndScenario",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await graphStartChatServices.getEndScenario(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const graphStartChatSlice = createSlice({
  name: "graphStartChat",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getStartChat.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStartChat.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.startChat = payload.data;
    });
    builder.addCase(getStartScenario.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStartScenario.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.startScenario = payload.data;
    });
    builder.addCase(getEndScenario.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEndScenario.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.endScenario = payload.data;
    });
  },
});

export const GraphStartChatSelector = (state: RootState) => state.graphStartChat;

export default graphStartChatSlice.reducer;
