import clsx from 'clsx';
import { ButtonCustom } from '../../components/button';
import styles from './index.module.scss';
//images
import { ListPost } from './list-post';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getPosts, postSelector } from '../../redux/post-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { message } from 'antd';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { tenantSelector } from '../../redux/tenant-slice';
import _ from 'lodash';
const tenant = localStorage.getItem('tenantId');
export const AdvertisementSettingPage = () => {
  const isEditable = useAppSelector((state) => state.tenant.editable);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fetchPost = useAppSelector(postSelector);
  const isMounted = useRef(true);
  const tenant = useAppSelector(tenantSelector);
  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getPosts());
      const response = unwrapResult(actionResult);
      let active = _.find(response.posts, ['status', 'active']);
      if (active && isMounted.current) {
        navigate(`/ad-setting/edit/${active._id}`);
      }
    } catch (error: any) {
      message.error(error.err);
    }
  }, [dispatch]);
  useEffect(() => () => {
    isMounted.current = false
  }, []);
  const initShowFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getPosts());
      const response = unwrapResult(actionResult);
      let active = _.find(response.posts, ['status', 'active']);
      if (active) {
        navigate(`/ad-setting/detail/${active._id}`);
      }
    } catch (error: any) {
      message.error(error.err);
    }
  }, [dispatch]);
  useEffect(() => {
    if (isEditable) {
      initFetch();
    } else {
      initShowFetch();
    }
  }, [initFetch, initShowFetch]);
  useEffect(() => {
    let id = window.location.pathname.split('/')[4];
    if (
      fetchPost.pageResult.pageSize &&
      fetchPost.pageResult.pageSize > 0 &&
      fetchPost.pageResult.total === 0
    ) {
      navigate('/ad-setting');
    }
    if (
      fetchPost.pageResult.total &&
      fetchPost.pageResult.total > 0 &&
      !_.some(fetchPost.posts, ['_id', id])
    ) {
      // navigate('/ad-setting');
    }
  }, [fetchPost]);
  // ライトプランは表示できない
  if (['light'].includes(tenant.currentPlan)) {
    navigate(`/`);
  }
  return (
    <>
      <div className={clsx(styles.page__title)}>
        <h1>広告投稿の設定</h1>
      </div>
      <div className={clsx(styles.container, 'post__setting')}>
        <div className={clsx(styles.container__list)}>
          <div className={clsx(styles.list__ad)}>
            <ListPost onDetail={(post) => navigate(`${post._id}`)} />
          </div>
          <div className={clsx(styles.ad__action)}>
            <ButtonCustom
              disabled={!isEditable}
              className={clsx(styles.create__button)}
              onClick={() => navigate('/ad-setting/add')}
              type='primary'
              text='新投稿作成'
            />
          </div>
        </div>
        <div className={clsx(styles.container__post)}>
          <Outlet context={{ hasPost: fetchPost.posts.length }} />
        </div>
      </div>
    </>
  );
};
