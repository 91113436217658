import axiosAdmin from '../http-common/axiosAdmin';
import { ChatsettingDTO } from '../dto/index';

class ChatsettingService {
  setSetting = (body: ChatsettingDTO) => {
    const url = `/tenants-setting`;
    return axiosAdmin.post(url, body);
  };
  getSetting = () => {
    const url = `/tenants-setting`;
    return axiosAdmin.get(url);
  };
}

export default new ChatsettingService();
