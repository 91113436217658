import clsx from 'clsx';
import styles from './index.module.scss';
import styled from 'styled-components';
import { FormItem } from '../../components/form-item';
import { ChatBotUI } from './chatbot';
import { ButtonCustom } from '../../components/button';
import { UploadIcon } from '../../assets/icons/UploadIcon';

//images
import { Embedded } from './embedded';

//react hook form
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { tenantSelector } from '../../redux/tenant-slice';
import {
  EditChatbotUI,
  GetChatbotUI,
  GetIconChatBot,
} from '../../redux/chatbot-slice';
import { useEffect, useMemo, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { UploadIconChatbot } from './upload';
import { message } from 'antd';
import axiosClient from '../../http-common/axiosAdmin';

const SizeInput = styled.input`
  width: 60px;
`;
export const ChatBotUISettingPage = () => {
  const tenant = useAppSelector(tenantSelector);
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const dispatch = useAppDispatch();
  // const isViewer = useMemo(() => {
  //   if (tenant) {
  //     return !tenant.editable
  //   } else return true
  // },[tenant])
  const [chatbotColor, setChatbotColor] = useState({
    headerBackground: '',
    headerTitleTop: '',
    headerFonsizeTop: '',
    headerColorTop: '',
    headerTitleBot: '',
    headerFonsizeBot: '',
    headerColorBot: '',
    minusBackground: '',
    containerBackground: '',
    messageBackground: '',
    messageTitle: '',
    messageFontSize: '',
    messageColor: '',
    scenarioBackground: '',
    scenarioBorderColor: '',
    scenarioFontSize: '',
    scenarioColor: '',
    inputBackground: '',
    iconColor: '',
    inputPlaceholder: '',
    inputFontSize: '',
    inputContainerBackground: '',
    replyBackground: '',
    replyBorderColor: '',
    replyFontSize: '',
    replyColor: '',
  });
  const [tempIcon, setTempIcon] = useState('');
  const [resetIcon, setResetIcon] = useState(false);
  const [submit, setSubmit] = useState(false);
  const {
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const setValues = (data: any) => {
    setValue('headerBackground', data.headerBackground);
    setValue('headerTitleTop', data.headerTitleTop);
    setValue('headerFonsizeTop', data.headerFonsizeTop);
    setValue('headerColorTop', data.headerColorTop);
    setValue('headerTitleBot', data.headerTitleBot);
    setValue('headerFonsizeBot', data.headerFonsizeBot);
    setValue('headerColorBot', data.headerColorBot);
    setValue('minusBackground', data.minusBackground);
    setValue('containerBackground', data.containerBackground);
    setValue('messageBackground', data.messageBackground);
    setValue('messageTitle', data.messageTitle);
    setValue('messageFontSize', data.messageFontSize);
    setValue('messageColor', data.messageColor);
    setValue('scenarioBackground', data.scenarioBackground);
    setValue('scenarioBorderColor', data.scenarioBorderColor);
    setValue('scenarioFontSize', data.scenarioFontSize);
    setValue('scenarioColor', data.scenarioColor);
    setValue('inputBackground', data.inputBackground);
    setValue('iconColor', data.iconColor);
    setValue('inputPlaceholder', data.inputPlaceholder);
    setValue('inputFontSize', data.inputFontSize);
    setValue('inputContainerBackground', data.inputContainerBackground);
    setValue('replyBackground', data.replyBackground);
    setValue('replyBorderColor', data.replyBorderColor);
    setValue('replyFontSize', data.replyFontSize);
    setValue('replyColor', data.replyColor);
  };
  useEffect(() => {
    const subscription = watch((value: any, { name, type }) => {
      setChatbotColor(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);
  const fetchData = () => {
    const getAtribute = async () => {
      try {
        const actionResult = await dispatch(GetChatbotUI());
        const respone = unwrapResult(actionResult);
        if (respone.widget) {
          setChatbotColor(respone.widget);
          setValues(respone.widget);
        }
      } catch (error) {}
    };
    getAtribute();
  };
  useEffect(() => {
    fetchData();
  }, [dispatch, localStorage.getItem('tenantId')]);

  useEffect(() => {
    if (['light'].includes(tenant.currentPlan)) {
      let url = 'defaultattrlight';
      axiosClient
      .get(url)
      .then((res) => {
        onSubmit(res.data);
      })
      .catch((err) => message.error(err));
    }
  }, [tenant]);

  const originUrl = window.location.origin;
  const onSubmit = async (data: any) => {
    setSubmit(true);
    try {
      const editChatbotUI = await dispatch(EditChatbotUI({ widget: data }));
      const respone = unwrapResult(editChatbotUI);
      if (tempIcon == 'reset') {
        const url = '/icons';
        axiosClient.delete(url).then(() => {});
      }

      if (respone.status) {
        dispatch(GetChatbotUI());
        if (!['light'].includes(tenant.currentPlan)) {
          message.success('編集が完了しました。');
        }
        setSubmit(false);
      }
    } catch (error: any) {
      if (!['light'].includes(tenant.currentPlan)) {
        message.error(error.err);
      }
    }
  };
  const resetCurrent = () => {
    fetchData();
    setTempIcon('');
    setResetIcon(true);
  };
  const resetDefault = () => {
    setResetIcon(true);
    let url = 'defaultattr';
    axiosClient
      .get(url)
      .then((res) => {
        setChatbotColor(res.data);
        setValues(res.data);
      })
      .catch((err) => message.error(err));
    let resetIconURL = '/icons/reset_default';
    axiosClient
      .put(resetIconURL)
      .then((res) => {
        setTempIcon('');
        setResetIcon(true);
      })
      .catch((err) => message.error(err));
  };

  if (['trial', 'standard', 'master'].includes(tenant.currentPlan)) {
    return (
      <div className={clsx(styles.container)}>
        <Embedded originUrl={originUrl} tenantId={tenant.currentTenant._id} />
        <p className={clsx(styles.title__chatbotUIsetting)}>
          チャットボットUIの設定
        </p>
        <div className={clsx(styles.setting__container)}>
          <div className={clsx(styles.setting)}>
            <form className={clsx(styles.setting__form)}>
              <p>ヘッダ</p>
              <FormItem label='背景'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('headerBackground')}
                  value={chatbotColor.headerBackground}
                />
              </FormItem>
              <FormItem label='文書'>
                <input
                  disabled={!isEditable}
                  type='text'
                  {...register('headerTitleTop')}
                  value={chatbotColor.headerTitleTop}
                />
                <SizeInput
                  disabled={!isEditable}
                  type='text'
                  {...register('headerFonsizeTop')}
                  value={chatbotColor.headerFonsizeTop}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('headerColorTop')}
                  value={chatbotColor.headerColorTop}
                />
              </FormItem>
              <FormItem>
                <input
                  disabled={!isEditable}
                  type='text'
                  {...register('headerTitleBot')}
                  value={chatbotColor.headerTitleBot}
                />
                <SizeInput
                  disabled={!isEditable}
                  type='text'
                  {...register('headerFonsizeBot')}
                  value={chatbotColor.headerFonsizeBot}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('headerColorBot')}
                  value={chatbotColor.headerColorBot}
                />
              </FormItem>
              <FormItem label='アイコン'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('minusBackground')}
                  value={chatbotColor.minusBackground}
                />
              </FormItem>
              <p>チャットボックス</p>
              <FormItem label='背景'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('containerBackground')}
                  value={chatbotColor.containerBackground}
                />
              </FormItem>
              <FormItem label='本文'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('messageBackground')}
                  value={chatbotColor.messageBackground}
                />
                <textarea
                  disabled={!isEditable}
                  {...register('messageTitle')}
                  className={styles.textarea}
                  value={chatbotColor.messageTitle}
                />
                <SizeInput
                  disabled={!isEditable}
                  type='text'
                  {...register('messageFontSize')}
                  value={chatbotColor.messageFontSize}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('messageColor')}
                  value={chatbotColor.messageColor}
                />
              </FormItem>
              <FormItem label='シナリオ'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('scenarioBackground')}
                  value={chatbotColor.scenarioBackground}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('scenarioBorderColor')}
                  value={chatbotColor.scenarioBorderColor}
                />
                <SizeInput
                  disabled={!isEditable}
                  type='text'
                  {...register('scenarioFontSize')}
                  value={chatbotColor.scenarioFontSize}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('scenarioColor')}
                  value={chatbotColor.scenarioColor}
                />
              </FormItem>
              <FormItem label='返答'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('replyBackground')}
                  value={chatbotColor.replyBackground}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('replyBorderColor')}
                  value={chatbotColor.replyBorderColor}
                />
                <SizeInput
                  disabled={!isEditable}
                  type='text'
                  {...register('replyFontSize')}
                  value={chatbotColor.replyFontSize}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('replyColor')}
                  value={chatbotColor.replyColor}
                />
              </FormItem>
              <FormItem label='入力欄'>
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('inputBackground')}
                  value={chatbotColor.inputBackground}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('iconColor')}
                  value={chatbotColor.iconColor}
                />
                <input
                  disabled={!isEditable}
                  type='text'
                  {...register('inputPlaceholder')}
                  value={chatbotColor.inputPlaceholder}
                />
                <SizeInput
                  disabled={!isEditable}
                  type='text'
                  {...register('inputFontSize')}
                  value={chatbotColor.inputFontSize}
                />
                <input
                  disabled={!isEditable}
                  type='color'
                  {...register('inputContainerBackground')}
                  value={chatbotColor.inputContainerBackground}
                />
              </FormItem>
            </form>
          </div>
          <ChatBotUI
            headerBackground={chatbotColor.headerBackground}
            headerTitleTop={chatbotColor.headerTitleTop}
            headerFonsizeTop={chatbotColor.headerFonsizeTop}
            headerColorTop={chatbotColor.headerColorTop}
            headerTitleBot={chatbotColor.headerTitleBot}
            headerFonsizeBot={chatbotColor.headerFonsizeBot}
            headerColorBot={chatbotColor.headerColorBot}
            minusBackground={chatbotColor.minusBackground}
            containerBackground={chatbotColor.containerBackground}
            messageTitle={chatbotColor.messageTitle}
            messageBackground={chatbotColor.messageBackground}
            messageFontSize={chatbotColor.messageFontSize}
            messageColor={chatbotColor.messageColor}
            scenarioBackground={chatbotColor.scenarioBackground}
            scenarioColor={chatbotColor.scenarioColor}
            scenarioBorderColor={chatbotColor.scenarioBorderColor}
            scenarioFontSize={chatbotColor.scenarioFontSize}
            inputBackground={chatbotColor.inputBackground}
            iconColor={chatbotColor.iconColor}
            inputPlaceholder={chatbotColor.inputPlaceholder}
            inputFontSize={chatbotColor.inputFontSize}
            inputContainerBackground={chatbotColor.inputContainerBackground}
            iconURL={tempIcon}
            replyBackground={chatbotColor.replyBackground}
            replyColor={chatbotColor.replyColor}
            replyBorderColor={chatbotColor.replyBorderColor}
            replyFontSize={chatbotColor.replyFontSize}
          />
          <div className={clsx(styles.change__icon)}>
            <p>アイコンの変更</p>
            <div>
              <UploadIconChatbot
                setTempIcon={setTempIcon}
                setResetIcon={setResetIcon}
                resetIcon={resetIcon}
                submit={submit}
                setSubmit={setSubmit}
              />
              <div className={clsx(styles.policy)}>
                {
                  'アイコンの画像ファイルについて \nPNG形式でアップロードしてください。'
                }
              </div>
            </div>
          </div>
          <div className={clsx(styles.btn__group)}>
            <ButtonCustom
              disabled={!isEditable}
              type='ghost'
              text='デフォルトに戻す'
              className={clsx(styles.btn__reset__default)}
              onClick={resetDefault}
            />
            <ButtonCustom
              disabled={!isEditable}
              type='ghost'
              text='元に戻す'
              className={clsx(styles.btn__reset)}
              onClick={resetCurrent}
            />
            <ButtonCustom
              disabled={!isEditable}
              type='primary'
              text='保存する'
              className={clsx(styles.btn__savechange)}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={clsx(styles.container)}>
        <Embedded originUrl={originUrl} tenantId={tenant.currentTenant._id} />
      </div>
    );
  }
};
