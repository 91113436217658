import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DetailScenarioFiles, GraphAttachment, ScenarioUsage, TopPage } from "../model";
import { PageResultModel } from "../model/page-result.model";
import GraphAccessURLService from "../services/graph-access-url.services";


interface GraphAccessUrlState {
  loading: boolean;
  scenarioUsage: ScenarioUsage[];
  topPage: TopPage[];
  scenarioDetail: DetailScenarioFiles[];
  pageResult: PageResultModel;
}

const initialState: GraphAccessUrlState = {
  loading: false,
  scenarioUsage: [],
  topPage: [],
  scenarioDetail: [],
  pageResult: { currentPage: 0 } as PageResultModel,
};

export const getGraphAccessURL = createAsyncThunk(
  "graphAccessURL/getGraphAccessURL",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await GraphAccessURLService.getAll(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const getGraphScenarioDetail = createAsyncThunk(
  "graphAccessURL/getGraphScenarioDetail",
  async (params: any, { rejectWithValue }) => {
    try {
      const response = await GraphAccessURLService.getScenarioDetail(params);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const graphAccessURLSlice = createSlice({
  name: "graphAccessURL",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getGraphAccessURL.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGraphAccessURL.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.scenarioUsage = payload.scenarioUsage.map((item: ScenarioUsage, index: number) => ({ ...item, key: index }));
      const topPageData = payload.topPage.filter((item: TopPage, index: number) => index < 5);
      state.topPage = topPageData.map((item: TopPage, index: number) => ({ ...item, key: index }));
      state.pageResult = payload.pagedResult;
    });
    builder.addCase(getGraphScenarioDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGraphScenarioDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.scenarioDetail = payload.detailScenarioFiles.map((item: DetailScenarioFiles, index: number) => ({ ...item, key: index }));
    });
  },
});

export const GraphAccessURLSelector = (state: RootState) => state.graphAccessURL;

export default graphAccessURLSlice.reducer;
