export const PlusIcon = (prop) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      xmlns='http://www.w3.org/2000/svg'
      fill={prop?.fill}
    >
      <path d='M12 12V0H16V12H28V16H16V28H12V16H0V12H12Z' />
    </svg>
  );
};
