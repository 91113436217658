import clsx from 'clsx';
import styles from '../index.module.scss';
import './scenario-table.scss';
//images
import { Pagination, Table } from 'antd';
import { useAppSelector } from '../../../redux/hooks';
import { EmptyData } from '../../analytics/graph/scenario/table-scenario/empty-data';
interface IProps {
  columns?: any[];
  title?: string;
  dataSource?: any[];
  onOpenModal: (e: any) => void;
  onChangePage: (e: any) => void;
}

export const ScenarioTable: React.FC<IProps> = ({
  title,
  columns,
  dataSource,
  onOpenModal,
  onChangePage,
}) => {
  const pagedResult = useAppSelector(
    (state) => state.analyticsScenario.aggregationScenario.pagedResult
  );

  return (
    <div className='scenario__table'>
      <Table
        locale={{ emptyText: <EmptyData /> }}
        rowKey='chatId'
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: 700 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              onOpenModal(record);
            },
          };
        }}
      />
      {pagedResult && pagedResult.total / pagedResult.pageSize > 1 && (
        <Pagination
          showSizeChanger
          onChange={(current) => onChangePage(current)}
          defaultCurrent={pagedResult.currentPage}
          total={pagedResult.total}
          size='small'
          showLessItems={false}
          pageSize={pagedResult.pageSize}
        />
      )}
    </div>
  );
};
