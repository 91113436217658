import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Checkbox, Form, Input } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getMe, signIn } from '../../redux/auth-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { LANG } from '../../lang';
import { lang } from 'moment';
import { PasswordIcon, UserIcon } from '../../assets/icons';
import CryptoJS from 'crypto-js'
const key = CryptoJS.SHA256('0bc76yhvHuTob+bot+manager+ffD6Zk4w==')
export const LoginPage = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('accessTokenBot') ? true : false;
  const [errorMessage, setErrorMessage] = useState<string>('');
  const {
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const onSubmit = async (data: any) => {
    localStorage.removeItem('accessTokenBot');
    localStorage.removeItem('tenantId');
    try {
      const actionResult = await dispatch(signIn({ username: data.username, password: data.password }));
      const response = unwrapResult(actionResult);

      if (response.accessToken) {
        dispatch(getMe());
        navigate('/chatbotUI-setting');
        if (data.remember) {
          localStorage.setItem('usernamebot', data.username)
          localStorage.setItem('botmanager', encode(data.password))
        } else {
          localStorage.removeItem('usernamebot')
          localStorage.removeItem('botmanager')
        }
      }
    } catch (error: any) {
      setErrorMessage(error.err);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/chatbotUI-setting');
      // dispatch(login());
    }
  }, [isAuthenticated]);
  const encode = (data: string) => {
    const iv = CryptoJS.enc.Base64.parse("");
    data = data.slice();
    let hash = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return hash.toString()
  }

  const decode = (data: any) => {
    if (!data) {
      return ''
    }
    try {
      const iv = CryptoJS.enc.Base64.parse("");
      var decrypted = CryptoJS.AES.decrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypted.toString(CryptoJS.enc.Utf8)
    } catch {
      localStorage.removeItem('usernamebot')
      localStorage.removeItem('botmanager')
      setValue('username', '')
      setValue('password', '')
    }

  }
  useEffect(() => {
    setValue('password', decode(localStorage.getItem('botmanager') || ''))
    setValue('username', localStorage.getItem('usernamebot') || '')
    setValue('remember', !!localStorage.getItem('usernamebot'))
  }, [])
  return (
    <div className='login__page'>
      <h1>ログイン</h1>
      <form autoComplete='false' onSubmit={handleSubmit(onSubmit)}>
        <div className='login__page__form'>
          <Form.Item
            label='ユーザー名'
            validateStatus={errors?.username ? 'error' : ''}
            help={
              errors?.username?.type === 'required' && LANG.USERNAME_REQUIRED
            }
          >
            <Controller
              name='username'
              rules={{
                required: true,
                // maxLength: 20,
              }}
              control={control}
              render={({ field }) => (
                <Input
                  autoComplete='false'
                  prefix={<UserIcon />}
                  placeholder='ユーザー名を入力してください。'
                  {...field}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label='パスワード'
            validateStatus={errors?.password ? 'error' : ''}
            help={
              errors?.password?.type === 'required' &&
              'パスワードを入力してください。'
            }
          >
            <Controller
              name='password'
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Input.Password
                  prefix={<PasswordIcon />}
                  placeholder='パースワードを入力してください。'
                  {...field}
                  className='login__page__form__password'
                />
              )}
            />
          </Form.Item>
          <Form.Item>
            <Controller
              name='remember'
              control={control}
              render={({ field }) => (
                <Checkbox {...field} checked={field.value}>ログイン名を保存する</Checkbox>
              )}
            />
          </Form.Item>
        </div>
        {errorMessage && <Alert message={errorMessage} type='error' />}
        <Button htmlType='submit' className='button__submit' type='primary'>
          サインイン
        </Button>
      </form>
    </div>
  );
};
