import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "./store";
import { LoginDTO } from "../dto/index";
import AuthService from "../services/auth.services";
import { TenantModel } from "../model";
import tenantServices from "../services/tenant.services";
import { transpile } from "typescript";

interface TenantState {
  loading: boolean;
  tenants: TenantModel[];
  currentTenant: TenantModel;
  currentPlan: string;
  currentLimit: any;
  editable: boolean;
}

const initialState: TenantState = {
  loading: false,
  tenants: [],
  currentTenant: {} as TenantModel,
  currentPlan: '',
  currentLimit: '',
  editable: true,
};

export const getTenants = createAsyncThunk("tenant/getTenants", async () => {
  const response = await tenantServices.getAll();
  return response.data;
});

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    changeTenant: (state, { payload }) => {
      localStorage.setItem("tenantId", payload);
      state.currentTenant = state.tenants.find(
        (f) => f._id === payload
      ) as TenantModel;
      let tenant = state.tenants.find((f) => f._id === state.currentTenant._id)
      if (tenant) {
        if (tenant.usersRole === '編集者') {
          state.editable = true
        } else {
          state.editable = false
        }
        state.currentPlan = (typeof tenant.plan == 'undefined') ? '' : tenant.plan;
        state.currentLimit = (typeof tenant.limit == 'undefined') ? '' : tenant.limit;
      } else {
        state.editable = false;
        state.currentPlan = '';
        state.currentLimit = '';
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTenants.fulfilled, (state, { payload }) => {
      if (payload.tenants) {
        if (!payload.tenants.length) {
          localStorage.removeItem('accessTokenBot');
          localStorage.removeItem('tenantId');
          window.location.href = "/bot/login";
        }
        state.tenants = payload.tenants;
        let tenantId = localStorage.getItem("tenantId")
        state.currentTenant = tenantId && payload.tenants.some((el: any) => el._id === tenantId) ? { _id: tenantId } : payload.tenants[0];
        localStorage.setItem('tenantId', state.currentTenant._id)
        let tenant = state.tenants.find((f) => f._id === state.currentTenant._id)
        if (tenant) {
          if (tenant.usersRole === '編集者') {
            state.editable = true;
          } else {
            state.editable = false;
          }
          state.currentPlan = (typeof tenant.plan == 'undefined') ? '' : tenant.plan;
          state.currentLimit = (typeof tenant.limit == 'undefined') ? '' : tenant.limit;
        } else {
          state.editable = false;
          state.currentPlan = '';
          state.currentLimit = '';
        }
      }
    });
  },
});

export const { changeTenant } = tenantSlice.actions;

export const tenantSelector = (state: RootState) => state.tenant;

export default tenantSlice.reducer;
