import './index.scss';
import clsx from 'clsx';
import { Pagination, Table } from 'antd';
import { EmptyData } from '../../../graph/scenario/table-scenario/empty-data';

interface IProps {
  columns: any[];
  data: any[];
  // pagination: boolean;
  // currentPage: number;
  // pageResult: PagedResult;
  // onChangePage: (page: number, pageSize: number) => void;
}

export const TableDialogHistoryDetail: React.FC<IProps> = ({
  data,
  columns,
  // pagination,
  // currentPage,
  // pageResult,
  // onChangePage,
}) => {
  // const onHandlePage = (page: number, pageSize: number) => {
  //   onChangePage(page, pageSize);
  // };
  let locale = {
    emptyText: <EmptyData />,
  };

  return (
    <div className='table__dialog__history__detail'>
      <Table
        rowKey='createAt'
        locale={locale}
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ y: 370 }}
      />
      {/* {pagination && (
        <Pagination
          showSizeChanger
          onChange={onHandlePage}
          defaultCurrent={1}
          current={currentPage}
          // total={Number(pageResult.total)}
          size='small'
          showLessItems={false}
          // pageSize={Number(pageResult.pageSize)}
        />
      )} */}
    </div>
  );
};
