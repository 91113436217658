import clsx from 'clsx';
import { useOutletContext } from 'react-router-dom';
import styles from './index.module.scss';

export const EmptyPost = () => {
  const props :any = useOutletContext();
  return (
    <div className={clsx(styles.empty__post)}>
      <p>{ !props.hasPost ? '現在、この投稿のコンテンツはありません' : 'ステータスが有効になる投稿はシステムに存在していません。'}</p>
    </div>
  );
};
