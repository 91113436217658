import { Space } from 'antd';
import {
  DocumentIcon,
  ImageIcon,
  VideoIcon,
  ZipIcon,
} from '../../assets/icons';

interface IProps {
  name: string;
  type: string;
}

export const FileIcon: React.FC<IProps> = ({ name, type }) => {
  return (
    <div>
      <Space className='attachment__col__filename' size='small' align='center'>
        {(() => {
          switch (type) {
            case 'docx':
              return <DocumentIcon />;
            case 'jpg':
            case 'png':
              return <ImageIcon />;
            case 'mp4':
              return <VideoIcon />;
            case 'zip':
              return <ZipIcon />;
            default:
              return <DocumentIcon />;
          }
        })()}
        {name}
      </Space>
    </div>
  );
};
