import axiosAdmin from '../http-common/axiosAdmin';

class GlobalService {
  getAll = () => {
    const url = `/scenarios/suggestions`;
    return axiosAdmin.get(url);
  };
  getListScenario = () => {
    const url = `/scenarios/suggestions`;
    return axiosAdmin.get(url);
  };
}

export default new GlobalService();
