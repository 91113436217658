import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { ChatbotModel } from '../model';
import ChatbotService from '../services/chatbot.services';

interface ChatBotState {
  loading: boolean;
  attribute: ChatbotModel;
  icon: any;
}

const initialState: ChatBotState = {
  loading: false,
  attribute: {
    headerBackground: '#F96262',
  },
  icon: null,
};

export const EditChatbotUI = createAsyncThunk(
  'chatbot/editchatbotUI',
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await ChatbotService.setUI(body);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const GetChatbotUI = createAsyncThunk(
  'chatbot/getchatbotUI',
  async (body, { rejectWithValue }) => {
    try {
      const response = await ChatbotService.getUI();
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const GetIconChatBot = createAsyncThunk(
  'chatbot/geticonchatbot',
  async (body, { rejectWithValue }) => {
    try {
      const response = await ChatbotService.getIcon();
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(EditChatbotUI.pending, (state) => {
        state.loading = true;
      })
      .addCase(EditChatbotUI.fulfilled, (state, action) => {
        state.loading = false;
        // state.attribute = action.payload;
      })
      .addCase(GetChatbotUI.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetChatbotUI.fulfilled, (state, action) => {
        state.loading = false;
        state.attribute = action.payload.widget;
      })
      .addCase(GetIconChatBot.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetIconChatBot.fulfilled, (state, action) => {
        state.loading = false;
        state.icon = action.payload;
      });
  },
});

export const chatbotSelector = (state: RootState) => state.chatbot;

export default chatbotSlice.reducer;
