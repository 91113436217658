import { Button } from 'antd';
import clsx from 'clsx';
import { useCallback, useEffect, useRef } from 'react';
import styles from './index.module.scss';

interface IProps {
  type: 'primary' | 'ghost' | 'default' | 'link';
  style?: 'basic' | 'raised' | 'stroked' | 'flat' | 'icon';
  text?: string;
  icon?: React.ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  width?: string | number;
  htmlType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  onClick?: (e?: any) => void;
  spacing?: boolean;
  children?: React.ReactNode;
  autoFocus?: boolean;
  focus?: any;
  index?: number;
  setFocus?: any;
}

export const ButtonCustom: React.FC<IProps> = ({
  type,
  style = 'flat',
  text,
  icon,
  className,
  color,
  disabled,
  width,
  htmlType,
  loading,
  onClick = (e?: any) => e,
  spacing = false,
  children,
  autoFocus,
  focus,
  index,
  setFocus,
}) => {
  const ref = useRef<any>();
  useEffect(() => {
    if (focus) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [focus]);
  const handleSelect = useCallback(() => {
    // setting focus to that element when it is selected
    setFocus(index);
  }, [index, setFocus]);
  return (
    <Button
      type={type}
      className={clsx(className, styles.general, {
        [styles.primary]: type === 'primary',
        [styles.ghost]: type === 'ghost',
        [styles.link]: type === 'link',
        [styles.color]: color === 'red',
      })}
      style={{ width: width, letterSpacing: spacing ? '-2px' : 'unset' }}
      icon={icon}
      onClick={(e) => onClick(e)}
      disabled={disabled}
      htmlType={htmlType}
      loading={loading}
      autoFocus={autoFocus}
      ref={ref}
      onKeyPress={handleSelect}
    >
      {text || children}
    </Button>
  );
};
