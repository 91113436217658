export const ProfileIcon = () => {
  return (
    <svg
      width='16'
      height='21'
      viewBox='0 0 16 21'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12Z' />
    </svg>
  );
};
