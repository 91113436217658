import styles from './index.module.scss';
import clsx from 'clsx';
import { IconWrap } from '../../../components/icon-wrap';
import { RadioIcon } from '../../../assets/icons/RadioIcon';

interface IProps {
  scenarioSettings: any;
}
const JPControl = (control: string) => {
  switch (control) {
    case 'input:text':
      return 'テキスト入力'
    case 'input:number':
      return '数字入力のみ'
    case 'input:mail':
      return 'メール入力のみ'
    case 'options':
      return 'オプション選択'
    case 'dropdown':
      return 'ドロップダウン選択'
    case 'datepicker':
      return '日付選択'
    default:
      return ''
  }
}
export const PanelContent: React.FC<IProps> = ({ scenarioSettings }) => {
  return (
    <div className={clsx(styles.panel__container)}>
      <div className={clsx(styles.panel__title)}>
        <div className={styles.panel__title_1}>メッセージ</div>
        <div className={styles.panel__title_2}>コントロール</div>
        <div className={styles.panel__title_3}>項目名</div>
        <div className={styles.panel__title_4}>CV地点</div>
      </div>
      {scenarioSettings.map((scenarioSetting: any, k: number) => (
        <div key={k} className={clsx(styles.panel__content)}>
          <div className={styles.panel__content_1}>
            {scenarioSetting.message}
          </div>
          <div className={styles.panel__content_2}>
            {JPControl(scenarioSetting.control)}
          </div>
          <div className={styles.panel__content_3}>
            {scenarioSetting.itemName}
          </div>
          <div className={styles.panel__content_4}>
            {scenarioSetting.cvPoint && <IconWrap icon={<RadioIcon />} />}
          </div>
        </div>
      ))}
    </div>
  );
};
