import { unwrapResult } from '@reduxjs/toolkit';
import { DatePicker, Form, message } from 'antd';
import moment, { isDate } from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonCustom } from '../../../components/button';
import { LANG } from '../../../lang';
import { authSelector, getMe, updateProfile } from '../../../redux/auth-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

export const ChangeBirthDay = () => {
  const fetchData = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const dateFormat = 'YYYY/MM/DD';

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data: any) => {
    const body: any = {
      dayofbirth: data.dayofbirth
        ? moment(data.dayofbirth).format('YYYY/MM/DD')
        : '',
    };
    try {
      const actionResult = await dispatch(updateProfile(body));
      const response = unwrapResult(actionResult);
      if (!response.status) {
        message.warning({
          content: response.message,
          duration: 1.5,
        });

        reset();
      } else {
        message.success({
          content: LANG.EDIT_SUCCESS,
          duration: 1.5,
        });
      }
    } catch (error) {
    }
  };

  return (
    <form className='form__information' onSubmit={(e) => e.preventDefault()}>
      <Form.Item>
        <Controller
          name='dayofbirth'
          rules={{
            required: false,
          }}
          defaultValue={
            fetchData.profile.dayofbirth
              ? moment(fetchData.profile.dayofbirth, dateFormat)
              : ''
          }
          control={control}
          render={({ field }) => (
            <DatePicker placeholder='生年月日' {...field} format={dateFormat} />
          )}
        />
      </Form.Item>

      <div className='form__information__action'>
        <ButtonCustom
          htmlType='submit'
          onClick={handleSubmit(onSubmit)}
          type='primary'
          text='保存'
        />
        <ButtonCustom
          onClick={() => reset()}
          htmlType='button'
          type='link'
          text='キャンセル'
        />
      </div>
    </form>
  );
};
