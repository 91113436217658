import styles from './index.module.scss';
import './index.scss';
import clsx from 'clsx';
import {
  DatePicker,
  Input,
  Modal,
  Form,
  Select,
  Tag,
  Tooltip,
  Popover,
} from 'antd';
import './index.scss';
import { IconWrap } from '../../../components/icon-wrap';
import { ButtonCustom } from '../../../components/button';

import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { PlusIcon } from '../../../assets/icons/PlusIcon';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { useEffect, useState, useRef } from 'react';
import { UploadIconScenario } from './upload';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  CreateScenario,
  getAllScenario,
  getOneScenario,
  resetCurrentIcon,
  updateScenario,
  scenarioSelector,
} from '../../../redux/scenario-setting';
import { NestedReaction } from './nested-reaction';
import { NestedDropdown } from './nested-dropdown';
import { unwrapResult } from '@reduxjs/toolkit';
import { message } from 'antd';
import moment from 'moment';
import { max } from 'lodash';
import { tenantSelector } from '../../../redux/tenant-slice';
import { ScenarioPlans } from '../../../model/plan.model';
import useRoveFocus from '../../../hooks/useRoveFocus';
const { RangePicker } = DatePicker;
const { Option } = Select;

interface IProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  isEditable: boolean;
  idDetail?: string;
  isEditImg: boolean;
}

interface FormValues {
  filename: string;
  tags: string[];
  status: string;
  icon: string;
  emails: string[];
  scenarioSettings: {
    message: string;
    commonReaction: string;
    itemValues: any[];
    dateTime: any;
    control: any;
    itemName: any;
    cvPoint: boolean;
  }[];
  cvPoint: string;
}
const envApiUrl = process.env.REACT_APP_API_URL;
export const ScenarioModal: React.FC<IProps> = ({
  title,
  visible,
  onCancel,
  isEditable,
  idDetail,
  isEditImg,
}) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  
  const tenant = useAppSelector(tenantSelector);
  const scenarioCount = ({
    trial: 3,
    light: 2,
    standard: 3,
    master: 0
  } as ScenarioPlans)[tenant.currentPlan as keyof ScenarioPlans];

  const { fields, remove, insert } = useFieldArray({
    control,
    name: 'scenarioSettings',
  });
  const [selects, setSelects] = useState<any>([]);
  const indexCVpoint = useRef<any>(null);
  const [currentChecked, setCurrentChecked] = useState<any>();
  const [removedIcon, setRemovedIcon] = useState(false);
  const allScenario = useAppSelector(scenarioSelector);
  const currentImg = useAppSelector(
    (state) => state.scenario?.currentScenario?.scenario?.icon
  );
  const currentIcon = useAppSelector(
    (state) => state.scenario?.currentImageScenario
  );

  const [focus, setFocus] = useRoveFocus(2);

  const dispatch = useAppDispatch();
  useEffect(() => {
    const getDetail = async () => {
      try {
        const actionResult = await dispatch(getOneScenario(idDetail));
        const response = unwrapResult(actionResult);
        const findCvPoint = response.scenario.scenarioSettings.findIndex(
          (setting: any) => setting.cvPoint === true
        );
        
        if (findCvPoint >= 0) {
          setValue('cvPoint', findCvPoint.toString());
        }
        const newSettingScenario = response.scenario.scenarioSettings.map(
          (setting: any) => {
            const newObj = Object.assign({}, setting);
            if (setting.control === 'datepicker') {
              let time: any = [];
              if (typeof setting.itemValues[0] == 'number') {
                time.push(moment(setting.itemValues[0]));
              }
              if (
                setting.itemValues[1] &&
                typeof setting.itemValues[1] == 'number'
              ) {
                time.push(moment(setting.itemValues[1]));
              }
              newObj['dateTime'] = time;
            }
            return newObj;
          }
        );
        if (response.status === true) {
          setValue('filename', response.scenario.filename);
          setValue('tags', response.scenario.tags);
          setValue('emails', response.scenario.emails);
          setValue('scenarioSettings', newSettingScenario);
          setValue('status', response.scenario.status || 'inactive');
          const selectsData = response.scenario.scenarioSettings.map(
            (item: any, index: number) => {
              return {
                index: index,
                value: item.control,
              };
            }
          );
          setSelects(selectsData);
        }
      } catch (error) { }
    };
    if (isEditable) {
      getDetail();
    } else {
      setValue('scenarioSettings', [
        {
          message: '',
          itemValues: [{ text: '' }],
          itemName: '',
          control: '',
          cvPoint: false,
          dateTime: null,
          commonReaction: ''
        },
      ]);
    }
    return () => {
      reset({
        filename: '',
        tags: [],
        status: '',
        icon: '',
        emails: [],
        scenarioSettings: [
          {
            message: '',
            itemValues: [{ text: '' }],
            itemName: '',
            control: '',
            cvPoint: false,
          },
        ],
      });
    };
  }, [idDetail, visible]);

  const handleCancel = () => {
    onCancel();
    dispatch(resetCurrentIcon(null));
    if (!isEditable) {
      reset({
        filename: '',
        tags: [],
        status: '',
        icon: '',
        emails: [],
        scenarioSettings: [
          {
            message: '',
            itemValues: [{ text: '' }],
            itemName: '',
            control: '',
            cvPoint: false,
          },
        ],
      });
    }
  };
  const findMissingOrder = (arr: any) => {
    let sparse: any = arr.reduce(
      (sparse: any, i: number) => ((sparse[i] = 1), sparse),
      []
    );
    return [...sparse.keys()].filter((i) => i && !sparse[i]);
  };
  const fillItemName = (item: any, index: number, filled: any) => {
    if (filled.map((el: any) => el.index).includes(index)) {
      return item.itemName;
    }
    let depth1 = filled.some(
      (el: any) =>
        el.order.length > 0 &&
        el.order.length === 1 &&
        el.order[0] === index + 1
    );
    if (!depth1) {
      return '集計' + (index + 1);
    }
    let depth2 = filled
      .filter((el: any) => el.order[0] === index + 1 && el.order.length > 1)
      .map((el: any) => el.order[1]);
    if (depth2.length) {
      let maxOrder: number = max(depth2) || 0;
      return (
        '集計' +
        (index + 1) +
        '.' +
        (findMissingOrder(depth2)[0]
          ? findMissingOrder(depth2)[0]
          : maxOrder + 1)
      );
    }
    return '集計' + (index + 1) + '.1';
  };
  const autofillItemname = (data: FormValues) => {
    let result = data?.scenarioSettings || [];
    let filled = result.reduce((prev: any, item: any, index: number) => {
      if (item.itemName) {
        let obj = { index: index, order: [] };
        if (item.itemName.startsWith('集計')) {
          obj.order = item.itemName
            .replace('集計', '')
            .split('.')
            .map((el: string) => +el);
        }
        return [...prev, obj];
      } else return prev;
    }, []);
    return result.map((el: any, index) => {
      return {
        ...el,
        itemName: el.control ? fillItemName(el, index, filled) : el.itemName,
      };
    });
  };
  const handleBeforeSubmit = (data: FormValues) => {
    const newData = autofillItemname(data).map((scenario) => {
      scenario.cvPoint = false;
      if (scenario.control === 'datepicker' && scenario?.dateTime?.length) {
        scenario.itemValues[0] = scenario?.dateTime[0]._d?.getTime() || null
        scenario.itemValues[1] = scenario?.dateTime[1]._d?.getTime() || null;
      }
      return scenario;
    });
    const indexCvPoint = parseInt(data?.cvPoint);

    if (indexCvPoint >= 0) {
      newData[indexCvPoint].cvPoint = true;
    }
    return newData;
  };

  const onSubmit = async (data: FormValues) => {
    // handleBeforeSubmit(data);
    const handleData = handleBeforeSubmit(data).map((scenario) => {
      delete scenario.dateTime;
      return scenario;
    });
    if (handleData[handleData.length - 1].control === '') {
      delete handleData[handleData.length - 1].control;
    }
    const submitData = {
      filename: data.filename,
      tags: data.tags,
      emails: data.emails,
      icon: !isEditable
        ? currentIcon
          ? `${envApiUrl ||
          window.location.protocol + '//' + window.location.hostname
          }/${currentIcon}`
          : `${envApiUrl ||
          window.location.protocol + '//' + window.location.hostname
          }/`
        : removedIcon
          ? `${envApiUrl ||
          window.location.protocol + '//' + window.location.hostname
          }/`
          : currentIcon
            ? `${envApiUrl ||
            window.location.protocol + '//' + window.location.hostname
            }/${currentIcon}`
            : currentImg
              ? currentImg
              : watch('icon')
                ? watch('icon')
                : currentImg,
      scenarioSettings: handleData,
      status: data.status,
    };
    if (isEditable) {
      const submitEditData = submitData.scenarioSettings.map((setting: any) => {
        if (setting._id) {
          delete setting._id;
        }
        return setting;
      });
      submitData.scenarioSettings = submitEditData;
      try {
        const actionResult = await dispatch(
          updateScenario({
            id: idDetail,
            body: submitData,
          })
        );
        const response = unwrapResult(actionResult);
        if (response.status === true) {
          dispatch(resetCurrentIcon(null));
          message.success('編集が完了しました。');
          dispatch(getAllScenario({ page: 1, pageSize: 20 }));
          onCancel();
        }
      } catch (error: any) {
        message.error(error.err)
      }
    } else {
      try {
        const actionResult = await dispatch(CreateScenario(submitData));
        const response = unwrapResult(actionResult);
        if (response.status === true) {
          dispatch(resetCurrentIcon(null));
          dispatch(getAllScenario({ page: 1, pageSize: 20 }));
          message.success('追加が完了しました。');
          onCancel();
          reset({
            filename: '',
            tags: [],
            status: '',
            icon: '',
            scenarioSettings: [
              {
                message: '',
                itemValues: [{ text: '' }],
                itemName: '',
                control: '',
                cvPoint: false,
              },
            ],
          });
        }
      } catch (error: any) {
        message.error(error.err);
      }
    }
  };

  const handleOnchangeInput = (value: any, index: number) => {
    // setValue(`scenarioSettings.${index}.control`, value);
    if (selects.find((select: any) => select.index === index)) {
      const findIndex = selects.findIndex(
        (select: any) => select.index === index
      );
      selects[findIndex].value = value;
      setSelects([...selects]);
    } else {
      selects.push({ index: index, value: value });
      setSelects([...selects]);
    }
  };
  const checkArrayValidateEmail = (value: any) => {
    const patternEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (value) {
      let count = 0;
      for (let i = 0; i < value.length; i++) {
        if (!patternEmail.test(value[i])) {
          break;
        }
        count++;
      }
      return count;
    } else {
      return 0;
    }
  };

  const checkArray = (arr: any) => {
    return !(checkArrayValidateEmail(arr) < arr?.length)
  };
  const checkExistItemName = (value: string) => {
    return !(
      value &&
      watch('scenarioSettings')
        .map((el: any) => el.itemName)
        .filter((el: any) => el === value).length === 2
    );
  };
  return (
    <Modal
      title={title}
      visible={visible}
      // onOk={onOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
      className={clsx(styles.modal__container, 'scenario__setting')}
      width='900px'
    >
      <div className={clsx(styles.input__container)}>
        <div className={clsx(styles.form)}>
          <Form.Item
            label={
              <div>
                シナリオ名 <Tag color='#FF4133'>必須</Tag>{' '}
              </div>
            }
            validateStatus={errors?.filename ? 'error' : ''}
            help={errors?.filename && '入力したシナリオ名は無効です。'}
            className={clsx(styles.form__item)}
          >
            <Controller
              name='filename'
              rules={{
                required: true,
              }}
              defaultValue=''
              control={control}
              render={({ field }) => (
                <Input
                  placeholder='シナリオ名を入力ください。'
                  {...field}
                  className={styles.filename__input}
                />
              )}
            />
          </Form.Item>
          <Form.Item
            label={<div>状態</div>}
            className={clsx(styles.form__item)}
          >
            <Controller
              name='status'
              defaultValue='inactive'
              control={control}
              render={({ field }) => (
                <div style={{ position: 'relative' }} id='selectStatus'>
                  <Select
                    size='large'
                    placeholder='シナリオ名を入力ください。'
                    {...field}
                    className={styles.filename__input}
                    getPopupContainer={() => document.getElementById('selectStatus')!}
                  >
                    <Select.Option value='inactive'>無効</Select.Option>
                    {((el) => {
                        let validCnt = allScenario?.scenarios.filter((item: {
                          status: string;
                        }) => {
                          if (item?.status == 'active') {
                            return true;
                          }
                        }).length;
                        if (scenarioCount > validCnt || el.field.value === 'active' || tenant.currentPlan === 'master') {
                          return (
                              <Select.Option value='active'>有効</Select.Option>
                          );
                        }
                    })({ field })}
                  
                  </Select>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item label='タグ' className={clsx(styles.form__item)}>
            <Controller
              name='tags'
              // defaultValue=''
              control={control}
              render={({ field }) => (
                <div style={{ position: 'relative' }} id='selectTag'>
                  <Select
                    open={false}
                    mode='tags'
                    size='large'
                    style={{ width: '100%' }}
                    placeholder='タグを入力ください。'
                    getPopupContainer={() => document.getElementById('selectTag')!}
                    {...field}
                  ></Select>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item
            label='電子メール'
            className={clsx(styles.form__item)}
            validateStatus={errors?.emails ? 'error' : ''}
            help={errors?.emails && '入力した電子メール アドレスは無効です。'}
          >
            <Controller
              name='emails'
              control={control}
              rules={{
                validate: (value) => checkArray(value),
              }}
              render={({ field }) => (
                <div style={{ position: 'relative' }} id='selectEmail'>
                  <Select
                    open={false}
                    mode='tags'
                    size='large'
                    style={{ width: '100%' }}
                    tokenSeparators={[',']}
                    placeholder='電子メールを入力ください。'
                    getPopupContainer={() => document.getElementById('selectEmail')!}
                    {...field}
                  ></Select>
                </div>
              )}
            />
          </Form.Item>
        </div>
        <UploadIconScenario isEdit={isEditImg} removedIcon={setRemovedIcon} />
      </div>
      <div>
        <div className={clsx(styles.header)}>
          <p className={clsx(styles.header__no)}>No</p>
          <p className={clsx(styles.header__message)}>メッセージ</p>
          <p className={clsx(styles.header__control)}>コントロール</p>
          <p className={clsx(styles.header__title)}>集計用</p>
          <p className={clsx(styles.header__cvPoint)}>CV地点</p>
        </div>
        <form className='form__editModal'>
          {fields.map((field, index) => {
            return (
              <div key={field.id}>
                <div className={clsx(styles.form__dynamic)}>
                  <div className={clsx(styles.form__item__no)}>{index + 1}</div>
                  <Form.Item
                    validateStatus={
                      errors?.scenarioSettings?.[index]?.message ? 'error' : ''
                    }
                    help={
                      errors?.scenarioSettings?.[index]?.message &&
                      '入力したメッセージは無効です。'
                    }
                    className={clsx(styles.form__item)}
                  >
                    <Controller
                      name={`scenarioSettings.${index}.message` as const}
                      rules={{
                        required: true,
                      }}
                      control={control}
                      render={({ field }) => (
                        <Input.TextArea
                          style={{ width: 205 }}
                          autoSize={{ minRows: 1, maxRows: 5 }}
                          placeholder='メッセージ'
                          {...field}
                          className={clsx(
                            styles.filename__input,
                            'textareaResize'
                          )}
                        />
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '210px' }}
                    className={clsx(styles.form__item)}
                  >
                    <Controller
                      name={`scenarioSettings.${index}.control` as const}
                      control={control}
                      render={({ field }) => (
                        <div style={{ position: 'relative' }} id={`selectControl` + index}>
                          <Select
                            style={{ width: 210 }}
                            getPopupContainer={() => document.getElementById(`selectControl` + index)!}
                            {...field}
                            onChange={(value: any) => {
                              handleOnchangeInput(value, index);
                              setValue(
                                `scenarioSettings.${index}.control` as any,
                                value
                              );
                            }}
                          >
                            <Option value='input:text'>テキスト入力</Option>
                            <Option value='input:number'>数字入力のみ</Option>
                            <Option value='input:mail'>メール入力のみ</Option>
                            <Option value='options'>オプション選択</Option>
                            <Option value='dropdown'>ドロップダウン選択</Option>
                            <Option value='datepicker'>日付選択</Option>
                          </Select>
                        </div>
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ width: '215px' }}
                    className={clsx(styles.form__item)}
                    validateStatus={
                      errors?.scenarioSettings?.[index]?.itemName ? 'error' : ''
                    }
                    help={
                      errors?.scenarioSettings?.[index]?.itemName &&
                      'この集計用は既に存在します。'
                    }
                  >
                    <Controller
                      name={`scenarioSettings.${index}.itemName` as const}
                      // defaultValue='asdasd'
                      control={control}
                      rules={{
                        validate: checkExistItemName,
                      }}
                      render={({ field }) => (
                        <Input
                          style={{ height: 32, width: 215 }}
                          placeholder='集計名'
                          {...field}
                          className={styles.filename__input}
                        />
                      )}
                    />
                  </Form.Item>
                  <div className={clsx(styles.input__cvPoint__container)}>
                    <label className={clsx(styles.cvPoint__wrapper)}>
                      <input
                        className={clsx(styles.cvPoint__input)}
                        type='radio'
                        value={index}
                        onClick={(e: any) => {
                          if (indexCVpoint.current === e.target.value) {
                            indexCVpoint.current = null;
                            setValue('cvPoint', '');
                          } else {
                            indexCVpoint.current = e.target.value;
                          }
                        }}
                        {...register(`cvPoint` as const)}
                      />
                      <span className={clsx(styles.cvPoint__inner)} />
                    </label>
                  </div>
                  <div className={clsx(styles.cvPoint__btnGroup)}>
                    <div
                      className={clsx(styles.plus__icon, styles.icon__wrap)}
                      onClick={() => {
                        insert(index + 1, {
                          message: '',
                          control: '',
                          itemName: '',
                          itemValues: [{ text: '' }],
                          dateTime: '',
                          commonReaction: '',
                          cvPoint: false
                        });
                        let newSelects = selects.map((el: any) => {
                          if (el.index > index) {
                            return { ...el, index: el.index + 1 };
                          } else return el;
                        });
                        setSelects(newSelects);
                      }}
                    >
                      <IconWrap icon={<PlusIcon />} />
                    </div>
                    {fields.length > 1 && (
                      <div
                        className={clsx(styles.delete__icon, styles.icon__wrap)}
                        onClick={() => {
                          if (fields.length > 1) {
                            remove(index);
                            if (index === +watch('cvPoint')) {
                              setValue('cvPoint', '');
                            }
                            const removeIndex = selects.findIndex(
                              (select: any) => select.index === index
                            );
                            const newSelects = selects.map((select: any) => {
                              if (select.index > index) {
                                return (select.index = select.index - 1);
                              }
                              return select;
                            });
                            setSelects([...newSelects]);
                            if (removeIndex >= 0) {
                              selects.splice(removeIndex, 1);
                            }
                            setSelects([...selects]);
                          }
                        }}
                      >
                        <IconWrap icon={<DeleteIcon />} />
                      </div>
                    )}
                  </div>
                </div>
                <NestedReaction
                  show={selects.find(
                    (select: any) =>
                      select.index === index &&
                      ['dropdown', 'options'].includes(select.value)
                  )}
                  nestIndex={index}
                  {...{ control, register, errors }}
                />
                <NestedDropdown
                  show={selects.find(
                    (select: any) =>
                      select.index === index &&
                      ['dropdown', 'options'].includes(select.value)
                  )}
                  nestIndex={index}
                  {...{ control, register, errors }}
                />
                {selects.find(
                  (select: any) =>
                    select.index === index && select.value === 'datepicker'
                ) && (
                    <div className={clsx(styles.dateTime__container)}>
                      <Controller
                        name={`scenarioSettings.${index}.dateTime` as const}
                        render={({ field }) => (
                          <div style={{ position: 'relative' }} id={`selectDate` + index}>
                            <RangePicker
                              showTime={{ format: "HH:mm" }}
                              className={clsx(styles.input__datetime)}
                              getPopupContainer={() => document.getElementById(`selectDate` + index)!}
                              {...field}
                              format="YYYY-MM-DD HH:mm"
                            // minuteStep={15}
                            />
                          </div>
                        )}
                        control={control}
                      />
                    </div>
                  )}
              </div>
            );
          })}
          <div className={clsx(styles.btn__group)}>
            <ButtonCustom
              type='primary'
              text='確定'
              onClick={handleSubmit(onSubmit)}
              spacing={true}
              autoFocus={true}
              setFocus={setFocus}
              index={0}
              focus={focus === 0}
            />
            <ButtonCustom
              type='ghost'
              text='キャンセル'
              onClick={handleCancel}
              setFocus={setFocus}
              index={1}
              focus={focus === 1}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
