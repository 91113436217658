import { Editor } from "@tinymce/tinymce-react";
import { Dropdown, Form, Input, Menu, message, Select } from "antd";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  CalendarIcon,
  SaveIcon,
  SubmitIcon,
  TrashIcon,
} from "../../assets/icons";
import { ArrowDownIcon } from "../../assets/icons/ArrowDownIcon";
import { ZipIcon } from "../../assets/icons/ZipIcon";
import { ButtonCustom } from "../../components/button";
import { PostModel } from "../../model";
import { CloseOutlined } from "@ant-design/icons"
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  detailPost,
  getPosts,
  postSelector,
  setCurrentPost,
  updatePost,
} from "../../redux/post-slice";
import styles from "./index.module.scss";
import { DatePicker } from "antd";
import moment from "moment";
import { PostDTO } from "../../dto";
import { unwrapResult } from "@reduxjs/toolkit";
import { LANG } from "../../lang";
import axiosClient from "../../http-common/axiosAdmin";
const { RangePicker } = DatePicker;
const { Option } = Select;
interface IProps {
  data?: PostModel;
}
export const EditPost: React.FC<IProps> = () => {
  const fetchPost = useAppSelector(postSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState<any>(fetchPost?.currentPost?.content);
  const { postId } = useParams();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();
  const [isPostNow, setIsPostNow] = useState(fetchPost.isPostNow || false)
  const onSubmit = async (data: any) => {
    if (!content) {
      message.error("投稿内容を入力してください。")
      return
    }
    const body: PostDTO = {
      _id: fetchPost.currentPost._id,
      title: data.title,
      content: content || fetchPost.currentPost.content,
      status: data.status,
    };
    if (!isPostNow && data.date) {
      const startDate = new Date(data.date[0]).getTime();
      const endDate = new Date(data.date[1]).getTime();
      body["startDate"] = startDate;
      body["endDate"] = endDate;
    }
    try {
      const actionResult = await dispatch(updatePost(body));
      const response = unwrapResult(actionResult);

      if (response.status) {
        message.success("編集が完了しました。");
        dispatch(getPosts());
      } else {
        message.error(response.message);
      }
    } catch (error: any) {
      message.error(error.err);

    }
  };
  const menu = (
    <Menu
      onClick={(e: any) => {
        if (e.key === "時間を選択") {
          setIsPostNow(false);
        } else {
          setIsPostNow(true);
        }
      }}
    >
      <Menu.Item key="時間を選択">時間を選択</Menu.Item>
      <Menu.Item key="今すぐ投稿">今すぐ投稿</Menu.Item>
    </Menu>
  );

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(detailPost(postId));
      const response = unwrapResult(actionResult);
      setValue("title", response.post.title);
      setValue("status", response.post.status);
      if (response.startDate && response.endDate) {
        setValue("date", [
          moment(fetchPost.currentPost.startDate),
          moment(fetchPost.currentPost.endDate),
        ]);
      }
    } catch (error) {
    }
  }, [dispatch]);

  useEffect(() => {
    setData()
  }, [fetchPost]);
  const setData = () => {
    setValue("title", fetchPost.currentPost.title);
    setValue("status", fetchPost.currentPost.status);
    if (fetchPost.currentPost.startDate && fetchPost.currentPost.endDate) {
      setValue("date", [
        moment(fetchPost.currentPost.startDate),
        moment(fetchPost.currentPost.endDate),
      ]);
    }
    setContent(fetchPost.currentPost.content);
    if (fetchPost.currentPost.endDate) {
      setIsPostNow(false)
    } else {
      setIsPostNow(true)
    }
  }
  const deletePost = () => {
    reset()
    let url = `/posts/${postId}`
    axiosClient.delete(url).then(() => {
      message.success("削除が完了しました。")
      navigate('/ad-setting')
      dispatch(getPosts());
    }).catch((error) => message.error(error.err))
  }
  return (
    postId ?
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={clsx(styles.writting__tools)}
      >
        <div className={clsx(styles.post__header)}>
          <h3>
            <Form.Item
              className="required"
              validateStatus={errors?.title ? "error" : ""}
              help={errors?.title?.type === "required" && LANG.TITLE_REQUIRED}
            >
              <Controller
                name="title"
                rules={{
                  required: true,
                }}
                defaultValue={fetchPost.currentPost.title}
                control={control}
                render={({ field }) => <Input {...field} placeholder="投稿名を入力してください。" />}
              />
            </Form.Item>
          </h3>
          <div className={clsx(styles.post__header__status)}>
            <Form.Item
              className="required"
              validateStatus={errors?.status ? "error" : ""}
              help={errors?.status?.type === "required" && " 状態を選択してください。"}
            >
              <Controller
                name="status"
                control={control}
                rules={{
                  required: true,
                }}
                defaultValue={fetchPost.currentPost.status}
                render={({ field }) => (
                  <Select
                    style={{ width: 250 }}
                    {...field}
                    placeholder="状態を選択してください。"
                    options={[
                      { value: "inactive", label: "無効" },
                      { value: "active", label: "有効" },
                    ]}
                  />
                )}
              />
            </Form.Item>
          </div>
        </div>
        <div className={clsx(styles.post)}>
          <div className={clsx(styles.post__content)}>
            <Editor
              onEditorChange={(newValue, editor) => {
                setContent(newValue);
              }}
              apiKey="zfxajk11y3wmwhlxq96puwtw0f6y5vt376c4giqqb7bdv2w7"
              value={content}
              init={{
                skin: "snow",
                icons: "thin",
                language: 'ja',
                default_link_target: '_blank',
                target_list: false,
                placeholder: "投稿内容を入力してください。",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                height: "100%",
                menubar: false,
                textcolor_rows: "4",
                statusbar: false,
                toolbar:
                  "bold italic underline casechange forecolor |  image media link  |",
              }}
              outputFormat="html"
            // toolbar="code"
            />
          </div>
        </div>

        <div className={clsx(styles.footer)}>
          <div className={clsx(styles.action_left)}>
            <div className={clsx(styles.button__type__post)}>
              <ButtonCustom
                type="primary"
                width={125}
                icon={isPostNow ? <SubmitIcon /> : <CalendarIcon />}
                text={isPostNow ? "今すぐ投稿" : "時間を選択"}
              />
              <Dropdown overlay={menu} placement="topRight" trigger={["click"]}>
                <ButtonCustom type="primary" icon={<ArrowDownIcon />} />
              </Dropdown>
            </div>
            {!isPostNow && (
              <Form.Item
                validateStatus={errors?.date ? "error" : ""}
                help={errors?.date?.type === "required" && "日付は必須です。"}
              >
                <Controller
                  name="date"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => <RangePicker showTime {...field} />}
                />
              </Form.Item>
            )}
          </div>
          <div className={clsx(styles.action_right)}>
            <ButtonCustom
              type="primary"
              width={125}
              htmlType="submit"
              icon={<SaveIcon />}
              text="保存"
            />
            <ButtonCustom
              type="ghost"
              onClick={setData}
              width={125}
              icon={<CloseOutlined />}
              text="キャンセル"
            />
            <ButtonCustom
              onClick={deletePost}
              type="ghost"
              color="red"
              width={125}
              icon={<TrashIcon />}
              text="削除"
            />
          </div>
        </div>
      </form> : <></>
  )
};
