import styles from './index.module.scss';
import clsx from 'clsx';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { TopPage } from '../../../../../model';

ChartJS.register(ArcElement, Tooltip, Legend);


interface IProps {
  dataTopPage: TopPage[];
}

export const CardPercent: React.FC<IProps> = ({ dataTopPage }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          usePointStyle: true,
          pointStyle: 'rectRounded',
        },
      },
    },
  };
  const data = {
    labels: dataTopPage.map((item) => (item.pageUrl)),
    datasets: [
      {
        label: '# of Votes',
        data: dataTopPage.map((item) => (item.displayTopPage)),
        backgroundColor: ['#FFA544', '#AA80F9', '#25DE7A', '#800080', '#FF0000'],
      },
    ],
  };
  return (
    <div className={clsx(styles.container)}>
      <p>シナリオ間の使用率</p>
      <div className={clsx(styles.doughnut__chart)}>
        <Doughnut
          data={data}
          options={options}
          className={clsx(styles.cardPercent)}
        />
      </div>
    </div>
  );
};
