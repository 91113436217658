import { unwrapResult } from '@reduxjs/toolkit';
import { Alert, Form, Input, message } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CircleVerifyIcon } from '../../../assets/icons';
import { ButtonCustom } from '../../../components/button';
import { IconWrap } from '../../../components/icon-wrap';
import { LANG } from '../../../lang';
import {
  authSelector,
  checkMail,
  getMe,
  updateProfile,
  setExistMail,
} from '../../../redux/auth-slice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

export const ChangeEmail = () => {
  const fetchData = useAppSelector(authSelector);

  const dispatch = useAppDispatch();
  const [isInitCheck, setIsInitCheck] = useState(false);
  const [searchTitle, setSearchTitle] = useState('');
  const [emailValid, setEmailValid] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const onSubmit = async (data: any) => {
    const body: any = {
      emailAddress: data.emailAddress,
    };
    try {
      const actionResult = await dispatch(updateProfile(body));
      const response = unwrapResult(actionResult);
      if (!response.status) {
        message.error({
          content: response.message,
          duration: 1.5,
        });
        // reset();
      } else {
        message.success({
          content: LANG.EDIT_SUCCESS,
          duration: 1.5,
        });
        setIsInitCheck(false);
      }
    } catch (error) {
    }
  };

  const debouncedSearch = useCallback(
    debounce(async (nextValue) => {
      try {
        const actionResult = await dispatch(checkMail(nextValue));
        const response = unwrapResult(actionResult);
        if (response && nextValue !== fetchData.profile.emailAddress) {
          setIsInitCheck(true);
        }
      } catch (error) {
      }
    }, 1000),
    [] // will be created only once initially
  );

  useEffect(() => {
    if (
      !errors?.emailAddress?.type &&
      String(watch('emailAddress')).length > 1
    ) {
      debouncedSearch(String(watch('emailAddress')));
    }
  }, [watch('emailAddress')]);

  const handleReset = () => {
    reset();
    setValue('emailAddress', fetchData.profile.emailAddress);
    setIsInitCheck(false);
  };

  return (
    <form className='form__information' onSubmit={(e) => e.preventDefault()}>
      <div className='checkmail'>
        <Form.Item
          validateStatus={errors?.emailAddress ? 'error' : ''}
          help={errors?.emailAddress?.type === 'pattern' && LANG.EMAIL_INVALID}
        >
          <Controller
            name='emailAddress'
            rules={{
              required: false,
              pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
            }}
            defaultValue={fetchData.profile.emailAddress}
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </Form.Item>
        {fetchData.isExistEmail &&
          watch('emailAddress') !== '' &&
          !errors?.emailAddress && (
            <span className='message error'>
              入力されたメールアドレスは既に使用されています。
            </span>
          )}
        {fetchData.isExistEmail === false &&
          isInitCheck &&
          !fetchData.isExistEmail &&
          watch('emailAddress') !== undefined &&
          !errors?.emailAddress?.type &&
          // String(watch('emailAddress')) !== fetchData.profile.emailAddress &&
          String(watch('emailAddress')).length > 1 &&
          !fetchData.isExistEmail && (
            <span className='message'>
              <IconWrap size={20} icon={<CircleVerifyIcon />} /> 有効なメール
            </span>
          )}
      </div>

      <div className='form__information__action'>
        <ButtonCustom
          htmlType='submit'
          onClick={handleSubmit(onSubmit)}
          type='primary'
          text='保存'
        />
        <ButtonCustom
          onClick={handleReset}
          htmlType='button'
          type='link'
          text='キャンセル'
        />
      </div>
    </form>
  );
};
