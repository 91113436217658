import { Upload, message } from 'antd';
import clsx from 'clsx';
import styles from './index.module.scss';
import { useEffect, useState } from 'react';
import { IconWrap } from '../../../components/icon-wrap';
import { ExitIcon, PlusImageIcon } from '../../../assets/icons';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import axiosClient from '../../../http-common/axiosAdmin';
import { IconCrop } from '../../../components/crop-upload';
interface IProps {
  setTempIcon: Function;
  setResetIcon: Function;
  resetIcon: boolean;
  submit: boolean;
  setSubmit: Function;
}
export const UploadIconChatbot: React.FC<IProps> = ({
  setTempIcon,
  setResetIcon,
  resetIcon,
  submit,
  setSubmit,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const [imageUrl, setImageUrl] = useState('');
  const [imageData, setImageData] = useState<any>();
  const [openCrop, setOpenCrop] = useState('')
  const fetchData = () => {
    const handleAsync = async () => {
      let url = '/icons/bot-header.png';
      axiosClient.get(url, { responseType: 'blob' }).then((res) => {
        var reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        reader.onloadend = function () {
          let imgSRC = reader.result;
          if (imgSRC && typeof imgSRC === 'string') {
            setImageUrl(imgSRC);
          }
        };
      });
    };
    handleAsync();
  };
  useEffect(() => {
    fetchData();
  }, [localStorage.getItem('tenantId')]);
  useEffect(() => {
    if (resetIcon) {
      fetchData();
      setResetIcon(false);
    }
  }, [resetIcon])
  useEffect(() => {
    if (submit && imageData) {
      saveIcon();
    }
  }, [submit])
  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };


  const handleChange = (info: any) => {
    // Get this url from response in real world.
    getBase64(info, (result: any) => {
      setOpenCrop(result)
    });
  };
  const cropied = (url: string, data: any) => {
    setImageUrl(url);
    setLoading(false);
    if (setTempIcon) {
      setTempIcon(url)
    }
    setImageData(data)
    setOpenCrop('')
  }
  const saveIcon = async () => {
    let url = `/icons`
    const formData = new FormData();
    await formData.append('file', imageData);
    if (imageData) {
      axiosClient.post(url, formData).then((res) => {
        setSubmit(false)
      }).catch((err) => message.error(err))
    }
  };
  const uploadButton = (
    <div>
      <div className={clsx(styles.upload)}>
        <div className={clsx(styles.upload__icon)}>
          <IconWrap icon={<PlusImageIcon />} className={clsx(styles.icon)} />
        </div>
      </div>
    </div>
  );
  const openUpload = () => {
    if (isEditable) {
      let dom = document.getElementById("upload")
      dom?.click()
    }
  }
  return (
    <>
      {openCrop && <IconCrop setOpenCrop={(data) => setOpenCrop(data)} open={openCrop} cropied={(url: string, data: any) => cropied(url, data)} />}
      <input accept="image/png" id="upload" style={{ display: "none" }} type="file" onChange={(e) => {
        let files = e.target.files
        if (files && files.length) {
          if ("image/png" === files[0].type) {
            handleChange(files[0])
          } else {
            message.error('PNG形式のみアップロードできます。')
          }
        }
        e.target.value = ''
      }} />
      <div style={{ cursor: isEditable ? 'pointer' : 'not-allowed' }} onClick={openUpload}>
        {imageUrl ? (
          <div className={clsx(styles.upload)}>
            {isEditable && <IconWrap
              icon={<ExitIcon />}
              className={clsx(styles.exitIcon)}
              onClick={(e: any) => {
                e.stopPropagation();
                setImageUrl('');
                setTempIcon('reset')
              }}
            />}
            <div
              className={clsx(styles.upload__image)}
            >
              <img
                src={imageUrl}
                alt='avatar'
                className={clsx(styles.scenario__image)}
              />
            </div>
          </div>
        ) : (
          uploadButton
        )}
      </div>
    </>
  );
};
