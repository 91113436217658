export const ExitIcon = () => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3.99999 3.29312L6.47499 0.818115L7.18199 1.52512L4.70699 4.00012L7.18199 6.47512L6.47499 7.18212L3.99999 4.70712L1.52499 7.18212L0.817993 6.47512L3.29299 4.00012L0.817993 1.52512L1.52499 0.818115L3.99999 3.29312Z' />
    </svg>
  );
};
