export const AdvertisementIcon = () => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M19 0C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19ZM18 2H2V16H18V2ZM7.399 5L10.598 13H8.443L8.043 12H4.753L4.353 13H2.199L5.399 5H7.399ZM17 5V13H14C13.2044 13 12.4413 12.6839 11.8787 12.1213C11.3161 11.5587 11 10.7956 11 10C11 9.20435 11.3161 8.44129 11.8787 7.87868C12.4413 7.31607 13.2044 7 14 7H14.999L15 5H17ZM15 9H14C13.7451 9.00028 13.5 9.09788 13.3146 9.27285C13.1293 9.44782 13.0178 9.68695 13.0028 9.94139C12.9879 10.1958 13.0707 10.4464 13.2343 10.6418C13.3979 10.8373 13.6299 10.9629 13.883 10.993L14 11H15V9ZM6.399 7.885L5.552 10H7.244L6.399 7.885Z' />
    </svg>
  );
};
