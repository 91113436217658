import {
  Progress,
  Space,
  Table,
  notification,
  Pagination,
  Modal,
  Popover,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { Attachment } from '../../model';
import styles from './index.module.scss';
import clsx from 'clsx';
import { UploadIcon } from '../../assets/icons/UploadIcon';
import { DocumentIcon } from '../../assets/icons/DocumentIcon';
import { ImageIcon } from '../../assets/icons/ImageIcon';
import { VideoIcon } from '../../assets/icons/VideoIcon';
import { DeleteIcon } from '../../assets/icons';
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import axiosClient from '../../http-common/axiosAdmin';
import { Preview } from './preview';
import { ButtonCustom } from '../../components/button';
import { useAppSelector } from '../../redux/hooks';
import { EmptyData } from '../analytics/graph/scenario/table-scenario/empty-data';
import { LoadingOverlay } from '../../components/loadingOverlay';
const MediaTypes = [
  {
    name: '写真',
    type: 'image',
    icon: <ImageIcon />,
  },
  {
    name: 'ビデオ',
    type: 'video',
    icon: <VideoIcon />,
  },
  {
    name: 'その他',
    type: 'other',
    icon: <DocumentIcon />,
  },
];
const envApiUrl = process.env.REACT_APP_API_URL;
export const AttachmentPage = () => {
  const [attachments, setAttachments] = useState<Attachment[]>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [maxSize, setMaxSize] = useState(0);
  const [totalImage, setTotalimage] = useState(0);
  const [totalVideo, setTotalVideo] = useState(0);
  const [totalOther, setTotalOther] = useState(0);
  const [countImg, setCountImg] = useState(0);
  const [countVid, setCountVid] = useState(0);
  const [countOther, setCountOther] = useState(0);
  const [total, setTotal] = useState(0);
  const isEditable = useAppSelector((state) => state.tenant.editable);
  const { confirm } = Modal;
  const availbableSize = useMemo(() => {
    return maxSize - totalSize;
  }, [totalSize, maxSize]);
  const columns = [
    {
      title: 'ファイル名',
      dataIndex: 'name',
      key: 'fileName',
      width: 400,
      render: (text: any, record: Attachment) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {['jpg', 'png', 'mp4', 'gif'].includes(record.extension) ? (
              <Popover
                placement='right'
                content={
                  <Preview
                    type={record.extension}
                    data={`${envApiUrl ||
                      window.location.protocol + '//' + window.location.hostname
                      }/api/v1/bot-manager/${localStorage.getItem(
                        'tenantId'
                      )}/attach_files/get_media?name=${record.name}`}
                  />
                }
              >
                <Space
                  className='attachment__col__filename'
                  size='small'
                  align='center'
                >
                  {(() => {
                    switch (record.extension) {
                      case 'jpg':
                      case 'png':
                      case 'gif':
                        return <ImageIcon />;
                      default:
                        return <VideoIcon />;
                    }
                  })()}
                </Space>
              </Popover>
            ) : (
              <Space
                className='attachment__col__filename'
                size='small'
                align='center'
              >
                {(() => {
                  switch (record.extension) {
                    case 'docx':
                      return <DocumentIcon />;
                    default:
                      return <DocumentIcon />;
                  }
                })()}
              </Space>
            )}
            {text}
          </div>
        );
      },
    },
    {
      title: 'サイズ',
      width: 100,
      dataIndex: 'size',
      key: 'size',
      render: (size: any) => formatBytes(size),
    },
    {
      title: '作成者',
      width: 150,
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: '作成日',
      width: 150,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (data: number) => moment(data).format('YYYY/MM/DD HH:mm:ss'),
    },
    {
      title: '更新者',
      width: 150,
      dataIndex: 'updateBy',
      key: 'updatedBy',
    },
    {
      title: '更新日',
      width: 150,
      dataIndex: 'updateAt',
      key: 'updatedAt',
      render: (data: number) => moment(data).format('YYYY/MM/DD HH:mm:ss'),
    },
    {
      key: 'action',
      width: 75,
      render: (record: Attachment) => {
        return (
          <div
            style={{
              width: 60,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ButtonCustom
              type='link'
              disabled={!isEditable}
              className={styles.disabled__btn}
            >
              <a
                rel='noreferrer'
                target='_blank'
                href={`${envApiUrl ||
                  window.location.protocol + '//' + window.location.hostname
                  }/api/v1/bot-manager/${localStorage.getItem(
                    'tenantId'
                  )}/attach_files/download_file?name=${record.name}`}
              >
                <DownloadOutlined style={{ cursor: 'pointer' }} />
              </a>
            </ButtonCustom>
            <ButtonCustom
              type='link'
              disabled={!isEditable}
              className={styles.disabled__btn}
            >
              <div
                onClick={() => showDeleteConfirm(record.name)}
                className={clsx(styles.delete__icon)}
              >
                <DeleteIcon fill='#FF4133' />
              </div>
            </ButtonCustom>
          </div>
        );
      },
    },
  ];
  const fetchFiles = (currentPage?: number) => {
    const url = `/attach_files`;
    axiosClient
      .get(url, {
        params: { page: currentPage ? currentPage : page, pageSize: 10 },
      })
      .then((res: any) => {
        if (res && res.data) {
          setAttachments(res.data.attachFiles);
          setTotalSize(res.data.totalSize);
          setMaxSize(res.data.maxSize);
          setTotalimage(res.data.totalImage);
          setTotalVideo(res.data.totalVideo);
          setTotalOther(res.data.totalOther);
          setTotal(res.data.pagedResult.total);
          setCountImg(res.data.numberImageFiles);
          setCountVid(res.data.numberVideoFiles);
          setCountOther(res.data.numberOtherFiles);
        }
      })
      .catch((err: any) => {
        notification.error({ message: err.message || err });
      });
  };
  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  const upload = async (files: File[]) => {
    setLoading(true);
    if (!files || !files.length) {
      setLoading(false);
      return;
    }
    if (files[0].size >= availbableSize) {
      notification.error({ message: '空き容量不足' });
      setLoading(false);
      return;
    }
    const loadedFiles = await loadFile(files);
    if (loadedFiles && loadedFiles.length) {
      await onLoadedFiles(loadedFiles);
    }
  };
  const loadFile = async (files: File[]) => {
    let loadedFiles: any[] = [];
    await asyncMapSequence(files, async (file: any) => {
      return new Promise((resolve: any, reject: any) => {
        if (file.type.match('image')) {
          getImagePreviewFromImage(loadedFiles, file, resolve);
        } else if (file.type.match('video')) {
          getImagePreviewFromVideo(loadedFiles, file, resolve);
        } else {
          loadedFiles.push({ file });
          resolve();
        }
      });
    });
    return loadedFiles;
  };
  const onLoadedFiles = (files: any) => {
    let formData = new FormData();
    let listFileInfo: any = {};
    for (let i = 0; i < files.length; i++) {
      if (files[i].name && files[i].blob) {
        formData.append(`${i + 1}-preview`, files[i].blob, files[i].name);
      }
      formData.append(`${i + 1}`, files[i].file);
      if (files[i].file) {
        const fileNameOriginal = files[i].file.name.toLowerCase();
        listFileInfo[fileNameOriginal] = {
          name: fileNameOriginal,
          imagePreview: files[i].name || '',
        };
      }
    }
    formData.append('list_file_info', `${JSON.stringify(listFileInfo)}`);
    uploadFromFormData(formData, files);
  };
  const getImagePreviewFromImage = (
    loadedFiles: any[],
    file: File,
    resolve: any
  ) => {
    const img = document.createElement('img');
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      URL.revokeObjectURL(img.src);
      const canvas = document.createElement('canvas');
      const resizeImage = _resizeImage(img.width, img.height);
      img.width = resizeImage.width;
      img.height = resizeImage.height;
      canvas.width = resizeImage.width;
      canvas.height = resizeImage.height;
      canvas
        .getContext('2d')
        ?.drawImage(img, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      const blob = _dataURItoBlob(image);
      const name = _formatFileNamePreview(file.name);
      loadedFiles.push({ blob, name, file });
      resolve();
    };
    img.onerror = (error: any) => {
      URL.revokeObjectURL(img.src);
      if (
        loadedFiles.every(
          (loadedfile: any) => loadedfile && loadedfile.file.name !== file.name
        )
      ) {
        loadedFiles.push({ file });
        resolve();
      }
    };
  };
  const getImagePreviewFromVideo = (
    loadedFiles: any[],
    file: File,
    resolve: any
  ) => {
    const video = document.createElement('video') as any;
    const timeupdate = () => {
      if (snapImage()) {
        URL.revokeObjectURL(video.src);
        video.removeEventListener('timeupdate', timeupdate);
        video.pause();
      }
    };
    video.addEventListener('loadeddata', () => {
      if (snapImage()) {
        URL.revokeObjectURL(video.src);
        video.removeEventListener('timeupdate', timeupdate);
      }
    });
    const snapImage = () => {
      const canvas = document.createElement('canvas');
      const resizeImage = _resizeImage(video.videoWidth, video.videoHeight);
      canvas.width = resizeImage.width;
      canvas.height = resizeImage.height;
      canvas
        .getContext('2d')
        ?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      const success = image.length > 2048;
      if (success) {
        URL.revokeObjectURL(video.src);
        const blob = _dataURItoBlob(image);
        const name = _formatFileNamePreview(file.name);
        loadedFiles.push({ blob, name, file });
        resolve();
      }
      return success;
    };
    video.addEventListener('timeupdate', timeupdate);
    video.onerror = (error: any) => {
      URL.revokeObjectURL(video.src);
      video.removeEventListener('timeupdate', timeupdate);
      if (
        loadedFiles.every(
          (loadedfile: any) => loadedfile && loadedfile.file.name !== file.name
        )
      ) {
        loadedFiles.push({ file });
        resolve();
      }
    };
    video.preload = 'metadata';
    video.src = URL.createObjectURL(file);
    video.muted = true;
    video.playsInline = true;
    video.play();
  };
  const _resizeImage = (width: number, height: number) => {
    const maxWidth = width > 280 ? 280 : width;
    const scaleFactor = maxWidth / width;
    return {
      width: maxWidth,
      height: height * scaleFactor,
    };
  };
  const _formatFileNamePreview = (fileName: string, prefix = 'preview') => {
    const fileSplit = fileName.split('.');
    const extension = fileSplit.pop();
    const name = fileSplit.join('.').toLowerCase();
    return `${name}-${prefix}.${extension?.toLowerCase()}.${'png'}`;
  };
  const _dataURItoBlob = (dataURI: any) => {
    const byteString =
      dataURI.split(',')[0].indexOf('base64') >= 0
        ? atob(dataURI.split(',')[1])
        : unescape(dataURI.split(',')[1]);
    // Separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // Write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };
  const asyncMapSequence = async (array: any, callback: any) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };
  const uploadFromFormData = (formData: any, files: any) => {
    const fileNameUploads = files.map((item: any) => item.file.name);
    validateFile(fileNameUploads)
      .then((res: any) => {
        const listFileNameError: any[] = [];
        const totalSize = files.reduce((sum: number, item: any) => {
          if (item.file.size >= res.maxSizeUpload) {
            listFileNameError.push(item.file.name);
          }
          return sum + item.file.size;
        }, 0);
        if (listFileNameError && listFileNameError.length > 0) {
          const messageSizeFileUpload =
            files.length > 1
              ? `${res.messageSizeFileUpload}\r\n${listFileNameError.join(
                '\r\n'
              )}`
              : res.messageSizeFileUpload;
          notification.error({ message: messageSizeFileUpload });
          setLoading(false);
          //
          return;
        }
        if (
          totalSize + res.currentSizeDirectoryUpload >
          res.maxSizeDirectoryUpload
        ) {
          notification.error({ message: res.messageSizeDirectoryUpload });
          //
          return;
        }
        if (res && res.warining) {
        }
        checkStatusAndUpload(formData);
      })
      .catch(() => {
        setLoading(false);
        //
      });
  };
  const checkStatusAndUpload = (formData: FormData) => {
    const urlCheckStatus = `/attach_files/check_status_and_register_job`;
    axiosClient
      .get(urlCheckStatus)
      .then((response: any) => {
        if (response.statusText !== 'OK') {
          return;
        }
        uploadFile(formData, response.data.jobId);
      })
      .catch((err: any) => {
        setLoading(false);
        notification.error({ message: err?.response?.data?.err || err });
      });
  };
  const uploadFile = (formData: FormData, jobId: any) => {
    formData.append('jobId', jobId);
    const url = `/attach_files`;
    axiosClient
      .post(url, formData)
      .then((response: any) => {
        if (response.statusText === 'OK') {
          setPage(1);
          fetchFiles(1);
          setLoading(false);
        } else {
          if (response.status == 413) {
            setLoading(false);
            throw new Error(
              'ファイルサイズが大きいためアップロードできませんでした。'
            );
          }
          if (response.status == 503) {
            setLoading(false);
            throw new Error(
              'サービスが一時的にご利用いただけません。\nしばらくしてから再度お試しください。'
            );
          }
          if (response.status == 504) {
            setLoading(false);
            throw new Error(
              '処理に時間がかかっています。\nしばらくしてから再読み込みしてください。'
            );
          }
          return response.json().then((json: any) => {
            setLoading(false);
            throw new Error(json.message);
          });
        }
      })
      .catch((err: any) => {
        setLoading(false);
        notification.error({ message: err.message || err });
        const url = `/attach_files/${jobId}`;
        axiosClient.post(url).catch((err: any) => {
          setLoading(false);
        });
      });
  };
  const validateFile = (listFileName: string[]) => {
    const url = `/attach_files/validate_file`;
    const formData = new FormData();
    listFileName.forEach((fileName: string) => {
      formData.append('name', fileName);
    });
    return axiosClient
      .post(url, formData)
      .then((response: any) => {
        if (response.statusText === 'OK') {
          return response.data;
        } else {
          if (response.status === 413) {
            setLoading(false);
            throw new Error(
              'ファイルサイズが大きいためアップロードできませんでした。'
            );
          }
          if (response.status === 503) {
            setLoading(false);
            throw new Error(
              'サービスが一時的にご利用いただけません。\nしばらくしてから再度お試しください。'
            );
          }
          if (response.status === 504) {
            setLoading(false);
            throw new Error(
              '処理に時間がかかっています。\nしばらくしてから再読み込みしてください。'
            );
          }
          return response.json().then((json: any) => {
            setLoading(false);
            throw new Error(json.message);
          });
        }
      })
      .catch((err: any) => {
        setLoading(false);
        notification.error({ message: err.message || err });
      });
  };
  const openUpload = () => {
    let dom = document.getElementById('upload');
    dom?.click();
  };
  const deleteAttachFile = (fileName: string) => {
    const url = `/attach_files/${fileName}`;
    axiosClient
      .delete(url)
      .then(() => {
        notification.success({ message: '削除が完了しました。' });
        fetchFiles();
      })
      .catch((err: any) => {
        notification.error({ message: err.message || err });
      });
  };
  const showDeleteConfirm = (fileName: string) => {
    confirm({
      title: 'このファイルを削除してもよろしいですか。',
      icon: <ExclamationCircleOutlined />,
      okText: 'キャンセル',
      okType: 'default',
      cancelButtonProps: { type: 'primary', style: { letterSpacing: -2 } },
      cancelText: '確定',
      onOk() {
      },
      onCancel() {
        deleteAttachFile(fileName);
      },
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchFiles();
      } catch (error: any) { }
    };
    fetchData();
  }, []);
  const pagination = {
    total: total,
    current: page,
    pageSize: 10,
    onChange: (page: number) => {
      setPage(page);
      fetchFiles(page);
    },
  };
  return (
    <div style={{ height: '100%', width: '100%', position: 'relative' }}>
      <LoadingOverlay isLoading={loading} />
      <div className={clsx(styles.page__title)}>
        <h1>添付</h1>
      </div>
      <div className={clsx(styles.attachment)}>
        <div className={clsx(styles.attachment__left)}>
          {attachments?.length ? (
            <Table
              locale={{ emptyText: <EmptyData /> }}
              className={clsx(styles.attachment__table)}
              rowKey={(record) => record._id}
              columns={columns}
              dataSource={attachments}
              pagination={false}
            // loading={loading}
            />
          ) : (
            <div
              className={clsx(styles.attachment__empty)}
              style={{ width: '100%', height: '100%', background: 'white' }}
            >
              <p>まだシナリオはありません。</p>
              <p>今すぐシナリオを作成しましょう！</p>
            </div>
          )}
          {attachments?.length && total > 10 ? (
            <Pagination
              className={clsx(styles.attachment__pagination)}
              {...pagination}
            />
          ) : null}
        </div>
        <div className={clsx(styles.attachment__right)}>
          <input
            disabled={loading}
            id='upload'
            style={{ display: 'none' }}
            type='file'
            onChange={(e) => {
              let a = e.target.files;
              let list: File[] = [];
              for (let index = 0; index < a!.length; index++) {
                let file: any;
                if (a) {
                  file = a.item(index);
                }
                if (file) {
                  list.push(file);
                }
              }
              upload(list);
              e.target.value = '';
            }}
          />
          <ButtonCustom
            disabled={!isEditable}
            type='ghost'
            className={clsx(
              'attachment__upload__custom',
              styles.attachment__upload__custom_1
            )}
            onClick={() => {
              openUpload();
            }}
          >
            <div>
              <div>
                <p className='ant-upload-drag-icon'>
                  <UploadIcon />
                </p>
                <span className='ant-upload-text'>アップロード</span>
              </div>
            </div>
          </ButtonCustom>
          <div className={clsx(styles.manager__storage)}>
            <div className={clsx(styles.manager__storage__title)}>保存容量</div>
            <div className={clsx(styles.manager__storage__progress)}>
              <Progress
                className='attachment__manager__storage__progress__custom'
                percent={(totalSize / maxSize) * 100}
                showInfo={false}
              />
              <div className={clsx(styles.manager__storage__progress__used)}>
                <strong>
                  {formatBytes(maxSize)} 中 {formatBytes(totalSize)} を利用
                </strong>
              </div>
            </div>
            <div className={clsx(styles.manager__storage__summary)}>
              {MediaTypes.map((type, index) => {
                return (
                  <div
                    key={index}
                    className={clsx(styles.manager__storage__summary__row)}
                  >
                    <div
                      className={clsx(
                        styles.manager__storage__summary__row__icon
                      )}
                    >
                      <div
                        className={clsx(
                          styles.manager__storage__summary__row__icon__size
                        )}
                      >
                        {type.icon}
                      </div>
                    </div>
                    <div
                      className={clsx(
                        styles.manager__storage__summary__row__info
                      )}
                    >
                      <div>{type.name}</div>
                      <div>
                        {type.type === 'image'
                          ? countImg
                          : type.type === 'video'
                            ? countVid
                            : countOther}{' '}
                        ファイル
                      </div>
                    </div>
                    <div
                      className={clsx(
                        styles.manager__storage__summary__row__total
                      )}
                    >
                      {type.type === 'image'
                        ? formatBytes(totalImage)
                        : type.type === 'video'
                          ? formatBytes(totalVideo)
                          : formatBytes(totalOther)}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
