import { unwrapResult } from '@reduxjs/toolkit';
import { Editor } from '@tinymce/tinymce-react';
import { Dropdown, Form, Input, Menu, message, Select } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CalendarIcon,
  SaveIcon,
  SubmitIcon,
  TrashIcon,
} from '../../assets/icons';
import { ArrowDownIcon } from '../../assets/icons/ArrowDownIcon';
import { ZipIcon } from '../../assets/icons/ZipIcon';
import { ButtonCustom } from '../../components/button';
import { PostDTO } from '../../dto';
import { PostModel } from '../../model';
import { CloseOutlined } from "@ant-design/icons"
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  createPost,
  getPosts,
  postSelector,
} from '../../redux/post-slice';
import styles from './index.module.scss';
import './post.scss';
import { DatePicker } from 'antd';
import { LANG } from '../../lang';
const { RangePicker } = DatePicker;
interface IProps {
  data?: PostModel;
}
export const CreatePost: React.FC<IProps> = () => {
  const fetchPost = useAppSelector(postSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [content, setContent] = useState<string>();
  const [isPostNow, setIsPostNow] = useState(true)
  const onSubmit = async (data: any) => {
    if (content) {
      const body: PostDTO = {
        title: data.title,
        content: content,
        status: data.status,
      };
      if (!isPostNow && data.date) {
        const startDate = new Date(data.date[0]).getTime();
        const endDate = new Date(data.date[1]).getTime();
        body['startDate'] = startDate;
        body['endDate'] = endDate;
      }
      try {
        const actionResult = await dispatch(createPost(body));
        const response = unwrapResult(actionResult);
        if (response.status) {
          message.success('追加が完了しました。');
          dispatch(getPosts());
          setTimeout(() => {
            navigate(`/ad-setting/edit/${response.post._id}`);
          }, 500);
        } else {
          message.error(response.message);
        }
      } catch (error: any) {
        message.error(error.err);
      }
    } else {
      message.error('コンテンツは必須です。');
    }
  };

  const menu = (
    <Menu
      // disabled
      onClick={(e: any) => {
        if (e.key === '時間を選択') {
          setIsPostNow(false);
        } else {
          setIsPostNow(true);
        }
      }}
    >
      <Menu.Item key='時間を選択'>時間を選択</Menu.Item>
      <Menu.Item key='今すぐ投稿'>今すぐ投稿</Menu.Item>
    </Menu>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(styles.writting__tools, 'form__post')}
    >
      <div className={clsx(styles.post__header)}>
        <h3>
          <Form.Item
            className='required'
            validateStatus={errors?.title ? 'error' : ''}
            help={errors?.title?.type === 'required' && LANG.TITLE_REQUIRED}
          >
            <Controller
              name='title'
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder='投稿名を入力してください。' />
              )}
            />
          </Form.Item>
        </h3>
        <div className={clsx(styles.post__header__status)}>
          <Form.Item
            className='required'
            validateStatus={errors?.status ? 'error' : ''}
            help={
              errors?.status?.type === 'required' && ' 状態を選択してください。'
            }
          >
            <Controller
              name='status'
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Select
                  placeholder='状態を選択してください。'
                  style={{ width: 250 }}
                  {...field}
                  options={[
                    { value: 'inactive', label: '無効' },
                    { value: 'active', label: '有効' },
                  ]}
                />
              )}
            />
          </Form.Item>
        </div>
      </div>
      <div className={clsx(styles.post)}>
        <div className={clsx(styles.post__content)}>
          <Editor
            onEditorChange={(newValue, editor) => {
              setContent(newValue);
            }}
            apiKey='zfxajk11y3wmwhlxq96puwtw0f6y5vt376c4giqqb7bdv2w7'
            value={content}
            init={{
              skin: 'snow',
              icons: 'thin',
              language: 'ja',
              placeholder: '投稿内容を入力してください。',
              default_link_target: '_blank',
              target_list: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              height: '100%',
              menubar: false,
              textcolor_rows: '4',
              statusbar: false,
              toolbar:
                'bold italic underline casechange forecolor |  image media link  |',
            }}
            outputFormat='html'
          // toolbar="code"
          />
        </div>
      </div>

      <div className={clsx(styles.footer)}>
        <div className={clsx(styles.action_left)}>
          <div className={clsx(styles.button__type__post)}>
            <ButtonCustom
              type='primary'
              width={125}
              icon={isPostNow ? <SubmitIcon /> : <CalendarIcon />}
              text={isPostNow ? '今すぐ投稿' : '時間を選択'}
            />
            <Dropdown overlay={menu} placement='topRight' trigger={['click']}>
              <ButtonCustom type='primary' icon={<ArrowDownIcon />} />
            </Dropdown>
          </div>
          {!isPostNow && (
            <Form.Item
              validateStatus={errors?.date ? 'error' : ''}
              help={errors?.date?.type === 'required' && '日付は必須です。'}
            >
              <Controller
                name='date'
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => <RangePicker showTime {...field} />}
              />
            </Form.Item>
          )}
        </div>
        <div className={clsx(styles.action_right)}>
          <ButtonCustom
            loading={fetchPost.loadingAction}
            type='primary'
            htmlType='submit'
            width={125}
            icon={<SaveIcon />}
            text='保存'
          />
          <ButtonCustom
            type="ghost"
            onClick={() => {
              setIsPostNow(false)
              setContent('')
              reset();
            }}
            width={125}
            icon={<CloseOutlined />}
            text="キャンセル"
            className={clsx(styles.button__cancel)}
          />
          <ButtonCustom
            onClick={() => {
              reset();
            }}
            type='ghost'
            color='red'
            width={125}
            icon={<TrashIcon />}
            text='削除'
          />
        </div>
      </div>
    </form>
  );
};
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
