import styles from './index.module.scss';
import clsx from 'clsx';
import { TableTopLanddingPage } from './top-landdingpage';
import { CardPercent } from './card-percent';
import { TableScenario } from './table-scenario';
import { DatePicker, message, Select, Tag } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
  getGraphAccessURL,
  getGraphScenarioDetail,
  GraphAccessURLSelector,
} from '../../../../redux/graph-access-url-slice';
import { useCallback, useEffect, useRef, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import { changeScenario, GlobalSelector } from '../../../../redux/global-slice';
import { IconWrap } from '../../../../components/icon-wrap';
import { ArrowDownIcon } from '../../../../assets/icons';
import { getListScenarioAnalytics } from '../../../../redux/analytics-scenario-slice';
import { ButtonCustom } from '../../../../components/button';
import { tenantSelector } from '../../../../redux/tenant-slice';
import { DateRangePlans } from '../../../../model/plan.model';

const { RangePicker } = DatePicker;
const { Option } = Select;
const PAGE_SIZE = 10;
export const GraphScenario = () => {
  const envApiUrl = process.env.REACT_APP_API_URL;

  const monthFormat = 'YYYY/MM/DD';

  const dispatch = useAppDispatch();
  const fetchAccessURL = useAppSelector(GraphAccessURLSelector);
  const globalData = useAppSelector(GlobalSelector);

  const [currentPage, setCurrentPage] = useState(1);
  const [startDay, setStartDay] = useState(
    moment().startOf('month').format(monthFormat)
  );
  const [endDay, setEndDay] = useState(moment().format(monthFormat));
  const [currentScenarioId, setCurrentScenarioId] = useState<any>();

  const [startDaySD, setStartDaySD] = useState(
    moment().startOf('month').format(monthFormat)
  );
  const [endDaySD, setEndDaySD] = useState(moment().format(monthFormat));
  const [filterStatus, setFilterStatus] = useState('active');
  const [sortByDisplay, setSortByDisplay] = useState(-1);
  const initLoading = useRef(false);

  const tenant = useAppSelector(tenantSelector);

  const dateRangePlan = ({
    trial: 1,
    light: 1,
    standard: 12,
    master: 36
  } as DateRangePlans)[tenant.currentPlan as keyof DateRangePlans];

  const ButtonSort = (type: any) => {
    return (
      <button className={clsx(styles.btn__sort)} type='button'>
        {(() => {
          if (type === 1) {
            return (
              <IconWrap
                className={clsx(styles.invert)}
                size={10}
                icon={<ArrowDownIcon />}
              />
            );
          }
          if (type === -1) {
            return <IconWrap size={10} icon={<ArrowDownIcon />} />;
          }
        })()}
      </button>
    );
  };

  const onHandleSort = () => {
    setSortByDisplay((prev) => prev * -1);
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'シナリオ名',
      maxWidth: '30%',
      dataIndex: 'scenarioName',
      key: 'scenarioName',
    },
    {
      title: () => {
        return (
          <>
            <div>
              <span onClick={onHandleSort} className={clsx(styles.title__sort)}>
                使用回数
                {ButtonSort(sortByDisplay)}
              </span>
            </div>
          </>
        );
      },
      dataIndex: 'displayUseScenario',
      key: 'displayUseScenario',
      render: (text: any, record: any) => {
        return <>{record.displayUseScenario[0]}</>;
      },
    },
    {
      title: 'ページ',
      width: '25%',
      dataIndex: 'pageUrl',
      key: 'pageUrl',
    },
  ];
  const columns2 = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'シナリオ名',
      dataIndex: 'question',
      key: 'question',
    },
    {
      title: '表示数',
      dataIndex: 'display',
      key: 'display',
    },
    {
      title: '離脱数',
      dataIndex: 'numberExits',
      key: 'numberExits',
    },
    {
      title: '入力数',
      dataIndex: 'reponse',
      key: 'reponse',
    },
    {
      title: 'エラー回数',
      dataIndex: 'errorAnswer',
      key: 'errorAnswer',
    },
    {
      title: '離脱率（％）',
      dataIndex: 'percentExits',
      key: 'percentExits',
    },
    {
      title: '入力率（％）',
      dataIndex: 'percentInput',
      key: 'percentInput',
    },
    {
      title: '状態',
      dataIndex: 'isActive',
      className: 'column__access',
      width: 180,
      key: 'isActive',
      render: (text: any, record: any) => {
        return (
          <>
            {record.isActive === 'active' ? (
              <span className='status status__yes'>有効</span>
            ) : (
              <span className='status'>無効</span>
            )}
          </>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text: any, record: any, index: number) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: 'ページ',
      dataIndex: 'pageUrl',
      key: 'pageUrl',
    },
    {
      title: '使用回数',
      dataIndex: 'displayTopPage',
      key: 'displayTopPage',
      render: (text: any, record: any) => {
        return <>{record.displayTopPage[0]}</>;
      },
    },
  ];
  const onChangeDate = (date: any) => {
    const startDay = date[0].format(monthFormat);
    const endDay = date[1].format(monthFormat);
    setStartDay(startDay);
    setEndDay(endDay);
  };

  const onChangeDateSD = (date: any) => {
    const startDaySD = date[0].format(monthFormat);
    const endDaySD = date[1].format(monthFormat);
    setStartDaySD(startDaySD);
    setEndDaySD(endDaySD);
  };

  const handleSelectChange = (value: any) => {
    setCurrentScenarioId(value);
    dispatch(changeScenario(value));
  };

  const handleSelectFilter = (value: any) => {
    setFilterStatus(value);
    initFetchSD({ ...paramsSD, status: value });
  };

  const handleChangePage = (current: number) => {
    setCurrentPage(current);
    dispatch(
      getGraphAccessURL({
        startDay,
        endDay,
        page: current,
        pageSize: PAGE_SIZE,
      })
    );
  };

  const getRequestParams = (
    startDay: string,
    endDay: string,
    page: number,
    pageSize: number,
    sortByDisplay: number
  ) => {
    let params: any = {};
    if (startDay) {
      params['startDay'] = startDay;
    }
    if (endDay) {
      params['endDay'] = endDay;
    }
    if (page) {
      params['page'] = page;
    }
    if (pageSize) {
      params['pageSize'] = pageSize;
    }
    if (sortByDisplay) {
      params['sortByDisplay'] = sortByDisplay;
    }
    return params;
  };

  const params = getRequestParams(
    startDay,
    endDay,
    currentPage,
    PAGE_SIZE,
    sortByDisplay
  );

  const getRequestParamsSD = (startDay: string, endDay: string) => {
    let params: any = {};
    if (startDay) {
      params['startDay'] = startDay;
    }
    if (filterStatus) {
      params['status'] = filterStatus;
    }
    if (endDay) {
      params['endDay'] = endDay;
    }
    if (globalData.currentScenario) {
      params['scenarioId'] = globalData.currentScenario;
    }

    return params;
  };

  const paramsSD = getRequestParamsSD(startDaySD, endDaySD);

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(getGraphAccessURL(params));
      const response = unwrapResult(actionResult);
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }

    }
  }, [dispatch, startDay, endDay, sortByDisplay]);

  useEffect(() => {
    initFetch();
    // setCurrentScenarioId(globalData.listScenario[0]._id);
  }, [initFetch]);

  const initFetchSD = useCallback(
    async (params?: any) => {
      try {
        const actionResult = await dispatch(
          getGraphScenarioDetail(params || paramsSD)
        );
        const response = unwrapResult(actionResult);
      } catch (error: any) {
        if (error.err) {
          message.error(error.err);
        }

      }
    },
    [dispatch, startDaySD, endDaySD, globalData.currentScenario, filterStatus]
  );

  useEffect(() => {
    if (initLoading.current) {
      setCurrentScenarioId(globalData.currentScenario);
      initFetchSD();
    }
  }, [initFetchSD]);

  const firstFetchSD = async () => {
    try {
      const actionResult = await dispatch(getListScenarioAnalytics());
      const response = unwrapResult(actionResult);
      if (response && response.length > 0) {
        dispatch(
          getGraphScenarioDetail({
            ...paramsSD,
            scenarioId: response[0]._id,
          })
        );
        setCurrentScenarioId(response[0]._id);
      }
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }

    }
  };

  useEffect(() => {
    if (!initLoading.current) {
      firstFetchSD();
    }
    initLoading.current = true;
    return () => {
      initLoading.current = false;
    };
  }, []);

  const handleExportExcel = (type?: string) => {
    const currentScenario = globalData.listScenario.find(
      (item: any) => item._id === currentScenarioId
    );
    const params = {
      startDay: startDay,
      endDay: endDay,
      scenarioId: currentScenarioId,
      status: filterStatus,
      scenarioName: currentScenario.filename,
      pageSize: PAGE_SIZE,
      page: currentPage,
      sortByDisplay: sortByDisplay,
    };
    switch (type) {
      case 'topPage':
        window.open(
          `${envApiUrl ||
          window.location.protocol + '//' + window.location.hostname
          }/api/v1/bot-manager/${localStorage.getItem(
            'tenantId'
          )}/analysis/exportTopPage?startDay=${params.startDay}&endDay=${params.endDay
          }`,
          `_blank`
        );
        break;
      case 'accessURL':
        window.open(
          `${envApiUrl ||
          window.location.protocol + '//' + window.location.hostname
          }/api/v1/bot-manager/${localStorage.getItem(
            'tenantId'
          )}/analysis/exportAccessUrl?startDay=${params.startDay}&endDay=${params.endDay
          }&sortByDisplay=${params.sortByDisplay}&pageSize=${params.pageSize
          }&page=${params.page}`,
          `_blank`
        );
        break;
      case 'useageDetail':
        window.open(
          `${envApiUrl ||
          window.location.protocol + '//' + window.location.hostname
          }/api/v1/bot-manager/${localStorage.getItem(
            'tenantId'
          )}/analysis/exportUsageDetail?startDay=${params.startDay}&endDay=${params.endDay
          }&scenarioId=${params.scenarioId}&status=${params.status
          }&scenarioName=${params.scenarioName}`,
          `_blank`
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={clsx(styles.scenario__container)}>
      <div className={clsx(styles.graphAccessURL__control)}>
        <span>期間</span>
        <div style={{ position: 'relative' }} id='selectMonth1'>
          <RangePicker
            onChange={(e: any) => onChangeDate(e)}
            defaultValue={[moment().startOf('month'), moment()]}
            format={monthFormat}
            className={clsx(styles.rangePicker)}
            getPopupContainer={() => document.getElementById('selectMonth1')!}
            disabledDate={(current) => {
              let customDate = moment().subtract(dateRangePlan, 'months').format();
              return current < moment(customDate, "YYYY-MM-DD");
            }} 
          />
        </div>
      </div>
      <div className={clsx(styles.scenario__top)}>
        <TableTopLanddingPage
          data={fetchAccessURL.topPage}
          columns={columns3}
          exportExcel={() => handleExportExcel('topPage')}
          canNotExport={!globalData.currentScenario}
        />
        <CardPercent dataTopPage={fetchAccessURL.topPage} />
      </div>
      <div className={clsx(styles.scenario__bottom)}>
        <div>
          <p>シナリオの利用状況</p>
          <div className={styles.export}>
            <ButtonCustom
              type='primary'
              text='エクスポート'
              onClick={() => handleExportExcel('accessURL')}
              disabled={!globalData.currentScenario}
            />
          </div>
        </div>
        <TableScenario
          data={fetchAccessURL.scenarioUsage}
          columns={columns}
          hasPagination
          onChangePage={(current) => handleChangePage(current)}
        />
      </div>
      <div className={clsx(styles.scenario__bottom)}>
        <div>
          <p>シナリオの利用詳細</p>
          {
            <div className={clsx(styles.scenario__control)}>
              <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <span style={{ marginRight: 10 }}>期間</span>
                <div style={{ position: 'relative' }} id='selectMonth'>
                  <RangePicker
                    onChange={(e: any) => onChangeDateSD(e)}
                    defaultValue={[moment().startOf('month'), moment()]}
                    format={monthFormat}
                    className={clsx(styles.rangePicker)}
                    getPopupContainer={() =>
                      document.getElementById('selectMonth')!
                    }
                    disabledDate={(current) => {
                      let customDate = moment().subtract(dateRangePlan, 'months').format();
                      return current < moment(customDate, "YYYY-MM-DD");
                    }} 
                  />
                </div>
              </div>
              {
                <div style={{ position: 'relative' }} id='selectScenario'>
                  <Select
                    className={clsx(styles.select)}
                    onChange={handleSelectChange}
                    // defaultValue={globalData.currentScenario}
                    value={currentScenarioId}
                    style={{ minWidth: '160px' }}
                    getPopupContainer={() =>
                      document.getElementById('selectScenario')!
                    }
                  >
                    {globalData.listScenario.map((item, index) => {
                      return (
                        <Option key={index} value={item._id}>
                          {item.filename}
                          {item.deletedAt !== null && (
                            <Tag
                              color='#ff4133'
                              style={{
                                marginLeft: 5,
                                marginRight: 0,
                                padding: '0 5px',
                                fontSize: 10,
                              }}
                            >
                              削除
                            </Tag>
                          )}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              }
              <div style={{ position: 'relative' }} id='selectStatus'>
                <Select
                  style={{ marginLeft: 10, width: 90 }}
                  className={clsx(styles.select)}
                  onChange={handleSelectFilter}
                  defaultValue='active'
                  getPopupContainer={() =>
                    document.getElementById('selectStatus')!
                  }
                >
                  <Option value='active'>有効</Option>
                  <Option value='inActive'>無効</Option>
                </Select>
              </div>
              <div
                className={clsx(styles.export)}
                style={{ width: 125, display: 'flex', justifyContent: 'end' }}
              >
                <ButtonCustom
                  type='primary'
                  text='エクスポート'
                  onClick={() => handleExportExcel('useageDetail')}
                  disabled={!globalData.currentScenario}
                />
              </div>
            </div>
          }
        </div>
        <TableScenario
          data={fetchAccessURL.scenarioDetail}
          columns={columns2}
        />
      </div>
    </div>
  );
};
