//component
import { TitleProfile } from "../../components/title-profile";
import { Collapse } from "antd";

//styles
import styles from "./index.module.scss";
import "./chat-display-setting.scss";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { chatsettingSelector, GetChatSetting, EditChatSetting } from "../../redux/chat-setting-slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChangeVisitSetting } from "./change-visit-setting";
import { ChangeTimerSetting } from "./change-timer-setting";
import { ChangeDisplayPosition } from "./change-display-position";
import { tenantSelector } from '../../redux/tenant-slice';
import { convertCompilerOptionsFromJson } from "typescript";

const { Panel } = Collapse;

export const ChatDisplaySettingPage = () => {
  const [collapse, setCollapse] = useState<string[]>([]);
  const fetchData = useAppSelector(chatsettingSelector);
  const fetchTenant = useAppSelector(tenantSelector);
  const dispatch = useAppDispatch();
 
  useEffect(() => {
    dispatch(GetChatSetting());
  }, [dispatch]);

  // ライトプラン・スタンダードプランで初期設定が違う場合は初期値に変更
  if (['light', 'standard'].includes(fetchTenant.currentPlan) && 
    (fetchData.setting.visitSettingCnt !== '' || fetchData.setting.timerSettingCnt !== '')
  ) {
    const body: any = {
      setting: {
        visitSettingCnt: '',
        timerSettingCnt: '',
        displayPosition: fetchData.setting.displayPosition
      },
    };
    dispatch(EditChatSetting(body));
  }
  return (
    <div className="page__chat-display-setting">
      <div className="chat-display-setting__header">
        <h1>チャットの表示設定</h1>
      </div>
      <div className="chat-display-setting__container">
      <Collapse
          // defaultActiveKey={['1']}
          accordion
          expandIconPosition="end"
          className="chat-display-setting__collapse"
        >
          {(() => {
              if (['trial', 'master'].includes(fetchTenant.currentPlan)) {
                return (
                  <Panel
                    showArrow={false}
                    header={
                      <TitleProfile
                        className={clsx({
                          [styles.chatDisplaySetting__title]: collapse.includes("1"),
                        })}
                        title="来訪数によるボット出し分け"
                        textEmpty="ボットを表示させる来訪数を選択してください"
                        content={(typeof fetchData?.setting?.visitSettingCnt !== 'undefined' && fetchData?.setting?.visitSettingCnt !== "") ? fetchData?.setting?.visitSettingCnt+"回" : '常時表示'}
                      />
                    }
                    key="1"
                  >
                  <ChangeVisitSetting />
                  </Panel>);
              }
          })()}
          {(() => {
              if (['trial', 'master'].includes(fetchTenant.currentPlan)) {
                return (
                  <Panel
                    showArrow={false}
                    header={
                      <TitleProfile
                        className={clsx({
                          [styles.chatDisplaySetting__title]: collapse.includes("2"),
                        })}
                        title="タイマー設定"
                        textEmpty="ボットを表示させる時間（秒数）を選択してください"
                        content={(typeof fetchData?.setting?.timerSettingCnt !== 'undefined' && fetchData?.setting?.timerSettingCnt !== "") ? fetchData?.setting?.timerSettingCnt+"秒" : '常時表示'}
                      />
                    }
                    key="2"
                  >
                  <ChangeTimerSetting />
                  </Panel>);
              }
          })()}

          <Panel
            showArrow={false}
            header={
              <TitleProfile
                className={clsx({
                  [styles.chatDisplaySetting__title]: collapse.includes("3"),
                })}
                title="表示位置"
                textEmpty="表示位置を選択してください"
                content={(typeof fetchData?.setting?.displayPosition !== 'undefined' &&　fetchData?.setting?.displayPosition !== "right-down") ? "中央(ポップアップ)" : "右下"}
              />
            }
            key="3"
          >
          <ChangeDisplayPosition />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};