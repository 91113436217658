import axiosAdmin from "../http-common/axiosAdmin";

class GraphAttachmentService {
  getAll = (params: any) => {
    const url = `/analysis/attachfile?pageSize=10`;
    return axiosAdmin.get(url, { params });
  };
}

export default new GraphAttachmentService();
