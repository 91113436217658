import styles from './index.module.scss';
import clsx from 'clsx';
import { TopScenarioChart } from '../../../../components/line-chart';
import { DatePicker, message, Select, Tag, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getStartChat,
  getStartScenario,
  GraphStartChatSelector,
} from '../../../../redux/graph-start-chat-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  changeScenarioAccess,
  GlobalSelector,
} from '../../../../redux/global-slice';
import { ButtonCustom } from '../../../../components/button';
import { tenantSelector } from '../../../../redux/tenant-slice';
import { DateRangePlans } from '../../../../model/plan.model';

const { Option } = Select;

export const GraphAccessNumber = () => {
  const envApiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const fetchData = useAppSelector(GraphStartChatSelector);
  const globalData = useAppSelector(GlobalSelector);

  const [currentScenarioId, setCurrentScenarioId] = useState<any>();
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY/MM/DD'));

  const tenant = useAppSelector(tenantSelector);

  const dateRangePlan = ({
    trial: 1,
    light: 1,
    standard: 12,
    master: 36
  } as DateRangePlans)[tenant.currentPlan as keyof DateRangePlans];

  const onDateTimePickerChange = (date: any, dateString: any) => {
    setCurrentDate(dateString);
  };

  const onHandleScenario = (data: any) => {
    setCurrentScenarioId(data);
    if (data !== globalData.currentScenarioAccess) {
      dispatch(changeScenarioAccess(data));
      initFetch();
    }
  };

  const initFetch = useCallback(async () => {
    try {
      const actionResult = await dispatch(
        getStartScenario({
          date: currentDate,
          scenarioId: globalData.currentScenarioAccess,
        })
      );
      const response = unwrapResult(actionResult);
    } catch (error: any) {
      if (error.err) {
        message.error(error.err);
      }

    }
  }, [dispatch, currentDate, globalData.currentScenarioAccess]);

  useEffect(() => {
    if (globalData.currentScenarioAccess) {
      initFetch();
      setCurrentScenarioId(globalData.currentScenarioAccess);
    }
  }, [initFetch, globalData.currentScenarioAccess]);

  const handleExportExcel = () => {
    const params = {
      currentDate: currentDate,
      scenarioId: currentScenarioId,
    };
    window.open(
      `${envApiUrl || window.location.protocol + '//' + window.location.hostname
      }/api/v1/bot-manager/${localStorage.getItem(
        'tenantId'
      )}/analysis/exportAccessNumber?date=${params.currentDate}&scenarioId=${params.scenarioId
      }`,
      `_blank`
    );
  };

  return (
    <div>
      {
        <div className={clsx(styles.control__container)}>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span>期間</span>
            <div style={{ position: 'relative' }} id='selectDate'>
              <DatePicker
                picker='date'
                defaultValue={moment(currentDate, 'YYYY/MM/DD')}
                format={'YYYY/MM/DD'}
                onChange={onDateTimePickerChange}
                getPopupContainer={() => document.getElementById('selectDate')!}
                disabledDate={(current) => {
                  let customDate = moment().subtract(dateRangePlan, 'months').format();
                  return current < moment(customDate, "YYYY-MM-DD");
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <span>シナリオ</span>
            <div style={{ position: 'relative' }} id='selectScenario'>
              <Select
                onChange={onHandleScenario}
                value={
                  globalData.listScenario.length === 0 || currentScenarioId
                }
                style={{ minWidth: '180px', maxWidth: '180px' }}
                getPopupContainer={() =>
                  document.getElementById('selectScenario')!
                }
              >
                {globalData.listScenario.map((item, index) => {
                  return (
                    <Option key={index} value={item._id}>
                      <Tooltip placement='topLeft' title={item.filename}>
                        {item.filename}
                        {item.deletedAt !== null && (
                          <Tag
                            color='#ff4133'
                            style={{
                              marginLeft: 5,
                              marginRight: 0,
                              padding: '0 5px',
                              fontSize: 10,
                            }}
                          >
                            削除
                          </Tag>
                        )}
                      </Tooltip>
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className={styles.export}>
            <ButtonCustom
              type='primary'
              text='エクスポート'
              onClick={handleExportExcel}
              disabled={globalData.listScenario.length === 0}
            />
          </div>
        </div>
      }
      <TopScenarioChart scenarioData={fetchData.startScenario} />
    </div>
  );
};
